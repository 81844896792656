import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forgotPasswordErrors } from 'src/app/config/error-messages';
import { ForgotpasswordService } from 'src/app/services/forgot-password.service';
import { Response } from 'src/app/models/response.model';
import { bluetrailsoftMailRegex } from 'src/app/config/regex';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ForgotpasswordService],
})
export class ForgotPasswordComponent implements OnInit {
  @Output() timerLoginView: EventEmitter<string> = new EventEmitter<string>();

  public showAlert: boolean = false;
  public disableRestartButton: boolean = false;
  public alertText: string[] = ['Email Does Not Exist.'];

  public emailControl = new FormControl('', []);

  // error messages
  public emailErrors = forgotPasswordErrors.emailErrors;

  // validators
  public emailValidators: Validators[] = [
    Validators.required,
    Validators.pattern(bluetrailsoftMailRegex),
  ];

  // resetPassword form group
  public resetPasswordForm = new FormGroup({
    email: this.emailControl,
  });

  constructor(
    private route: Router,
    public forgotPasswordService: ForgotpasswordService,
  ) {}

  ngOnInit() {
    if (history.state.errorMessage) {
      this.alertText = history.state.errorMessage;
      this.showAlert = true;
      delete history.state.errorMessage;
    }
  }

  /**
   * resetPassword method
   * this method is actived by the 'resset password' button to consume the
   * forgotPassordService to send the mail to reset the password
   * @returns {void}
   */
  public resetPassword(): void {
    this.disableRestartButton = true;
    this.forgotPasswordService
      .sendMail(this.emailControl.value.toLowerCase())
      .subscribe((response: Response) => {
        this.disableRestartButton = false;
        if (response.status === 'success') {
          this.goToTimerLoginView();
        } else if (
          response.message === 'The email has been sent successfully'
        ) {
          this.alertText = ['The email has been sent successfully.'];
          this.showAlert = true;
        } else {
          this.alertText = ['Something Went Wrong.'];
          this.showAlert = true;
        }
      });
  }

  /**
   * goToTimerLoginView method
   * function to show the count down and redirect to login
   * @returns {void}
   */
  public goToTimerLoginView(): void {
    this.timerLoginView.emit('login');
  }

  /**
   * goToLoginView method
   * Go directly to login
   * @returns {void}
   */
  public goToLoginView(): void {
    this.route.navigateByUrl('login');
  }
}
