import {
  Component,
  OnInit,
  Input,
  Output, 
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormArray,
  FormBuilder,
  AbstractControl
} from '@angular/forms';
import { DatabaseItemArray } from 'src/app/models/database-item-array.model';
import { validateFormGroupArray } from 'src/app/shared/custom-validators/form-group-array.validator';
import { validateArray } from 'src/app/shared/custom-validators/array.validator';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { DatabasesRequest } from 'src/app/models/databases-request.model';
import { DatabaseItem } from 'src/app/models/database-item.model';

@Component({
  selector: 'app-database-form-array',
  templateUrl: './database-form-array.component.html',
  styleUrls: ['./database-form-array.component.scss']
})
export class DatabaseFormArrayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() buttonsDisabled: boolean;
  @Input() arrayData: object[];
  @Input() addFormButtonText: string;
  @Input() inputName: string;
  @Input() inputArrayName: string;
  @Input() inputId: string;
  @Input() inputLabelText: string;
  @Input() inputValidators;
  @Input() inputErrors;
  @Input() inputArrayErrors;
  @Input() inputArrayTag: string;
  @Input() inputRegex: RegExp;
  @Input() dataUpdated: boolean;
  @Input() showContent: boolean;
  @Input() inputsCategory: string;

  @Output() request: EventEmitter<DatabasesRequest> = new EventEmitter<DatabasesRequest>();
  @Output() disableAllButtons: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() checkItemDataRelated: EventEmitter<DatabaseItem> = new EventEmitter<DatabaseItem>();
  @Output() deleteMainItem: EventEmitter<DatabaseItem> = new EventEmitter<DatabaseItem>()

  public correctInputContent: boolean[] = [];
  public elementForms: FormArray = new FormArray([], validateFormGroupArray);
  public isNewInput: boolean[][] = [];
  public formAdded: boolean = false;
  public inputAdded: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  /**
   * ngOnInit hook
   * @description Generate the necesary forms
   */
  ngOnInit() {
    this.generateForms();
  }

  /**
   * ngOnChanges hook
   * @description Reset the Form variables and regenerate the FormGroups and
   * FormControls according with the new data
   * @param {SimpleChanges} changes - Object containing the changes of the
   * input properties
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.arrayData && this.dataUpdated) {
      this.generateForms();
    }
  }

  /**
   * ngOnDestroy hook
   * @description If a form was added and this was not saved
   * this form will be removed
   */
  ngOnDestroy() {
    const newForm = this.elementForms
    .controls[0] as FormGroup;

    if (this.formAdded) {
      this.cancel(newForm);
    } 
  }

  /**
   * addNewInputElement method
   * @description Listen the addNewInput Event from DatabaseInputArray
   * and send an event to its parent to add a new FormControl in the forms
   * @param {FormGroup} parentForm - FormGroup to add the new input
   * @returns {void} void
   */
  public addNewInputElement(parentForm: FormGroup): void {
    this.getAsFormArray(parentForm, this.inputArrayName)
      .push(this.createSimpleControl(''));
    const index = this.getFormGroupIndex(parentForm, 'regionName');
    this.isNewInput[index].push(true);
  }

  /**
   * checkErrors method
   * @description Check if the main input of the FormGroup has errors
   * @returns {void} void
   */
  public checkErrors(index: number): void {
    this.correctInputContent[index] = this.elementForms.controls[index].valid;
    if (this.correctInputContent[index]) {
      this.addControlOnMainInput(
        (this.elementForms.controls[index] as FormGroup)
      );
    } else {
      this.removeControlOnMainInput(this.elementForms.controls[index] as FormGroup);
    }
  }

  /**
   * generateForms method
   * @description Iterates over arrayData to generate the necesary FormGroups
   * and FormControls
   * @returns {void} void
   */
  private generateForms(): void {
    this.elementForms.clear();
    this.isNewInput = [];
    this.formAdded = false;
    this.arrayData.forEach((element) => {
      this.elementForms.push(this.createForm(
        element[this.inputName],
        element[this.inputArrayName]
      ));

      this.isNewInput.push([
        ...(element[this.inputArrayName] as Array<string>).map(() => {
          return false;
        })
      ]);
    });
  }

  /**
   * createForm method
   * @description Create a new Form Group with a Form Control
   * and a FormArray with their respective values
   * @param formValue - Value of the FormGroup
   * @param formArrayValues - Value of the FormArray
   * @returns {FormGroup} FormGroup
   */
  private createForm(formValue: string, formArrayValues: string[]): FormGroup {
    const newControl: FormControl = new FormControl(formValue, []);
    this.correctInputContent.push(false);
    return this.formBuilder.group({
      [this.inputName]: newControl,
      [this.inputArrayName]: new FormArray([
        ...formArrayValues.map((value) => {
          return this.createSimpleControl(value['name']);
        }),
      ], validateArray),
    });
  }

  /**
   * createSimpleControl method
   * @description Create a new FormControl to use in the FormArray
   * @param {string} controlValue - Initial value to assign in the FormControl
   * @returns {FormControl} FormControl 
   */
  public createSimpleControl(controlValue: string): FormControl {
    return new FormControl(controlValue, [
      patternValidator(this.inputRegex)
    ]);
  }

  /**
   * getAsFormArray method
   * @description Get a certain FormArray in an specific FormGroup
   * @param {FormGroup} form - FormGroup to find the array
   * @returns {FormArray} FormArray
   */
  public getAsFormArray(form: AbstractControl, arrayName: string): FormArray {
    return (form.get(arrayName) as FormArray);
  }

  /**
   * addRegionForm method
   * @description Push a new FormGroup in regionForms
   * @returns {void} void
   */
  public addNewForm(): void {
    this.formAdded = true;
    this.elementForms.insert(0, this.createForm('', []));
    this.arrayData.unshift({
      [this.inputName]: '',
      [this.inputArrayName]: []
    });
    this.isNewInput.unshift([]);
    this.isNewInput[0].push(true);
  }

  /**
   * getFormGroupIndex method
   * @description Get the index that a certain form has in elementForms
   * according to a property to compare
   * @param {FormGroup} formToCompare - FormGroup to find the control
   * @param {string} controlToFind - FormControl name to compare if
   * the value is the same
   * @returns {number} number
   */
  private getFormGroupIndex(formToCompare: FormGroup,
    controlToFind: string): number {
      return this.elementForms.controls.findIndex((form) => {
        return form.value[controlToFind] === formToCompare.value[controlToFind];
      });
  }

  /**
   * addNewSimpleControl method
   * @description Add a new FormControl in an specific
   * FormGroup
   * @param {FormGroup} parentForm - FormGroup to add the
   * FormControl
   * @returns {void} void 
   */
  public addNewSimpleControl(parentForm: FormGroup): void {
    this.getAsFormArray(parentForm, this.inputArrayName)
      .push(this.createSimpleControl(''));
    const index = this.getFormGroupIndex(parentForm, this.inputName);
    this.isNewInput[index].push(true);
    this.inputAdded = true;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * addOnInput method
   * @description Add a new input in DatabaseInputArray when the input event
   * in the main input is happening
   * @param {DatabaseItemArray} itemToRemove - Item from to validate if is
   * able to add a new input
   * @returns {void} void
   */
  public addOnInput(item: DatabaseItemArray): void {
    const controlsArray = (item.parentForm.get('locations') as FormArray)
      .controls;

    const inputsError = !!controlsArray.find((control) => {
      return control.errors
      && (control.errors.patternValidator
        || control.errors.notUnique);
    });

    if (
      (item.control.value.length > 0
      && item.control.value.trim()
      && item.control.valid
      && item.parentForm.valid)
      && controlsArray[controlsArray.length - 1].value
      ) {
        this.getAsFormArray(item.parentForm, this.inputArrayName)
          .push(this.createSimpleControl(''));
        const index = this.getFormGroupIndex(item.parentForm, this.inputName);
        this.isNewInput[index].push(true);
        this.inputAdded = true;
    }

    this.removeSimpleControl(item);
  
    if (inputsError) {
      this.removeLastControl(controlsArray, item);
    }
  }

  /**
   * removeSimpleControl method
   * @description Remove a FormControl in an specific FormGroup
   * @param {RemoveDatabaseItemArray} itemToRemove - Object that
   * includes the FormGroup to remove the FormControl
   * and the index of the FormControl
   * @returns {void} void 
   */
  public removeSimpleControl(itemToRemove: DatabaseItemArray): void {
    const controlsArray = (itemToRemove.parentForm
      .get('locations') as FormArray).controls;

    controlsArray.forEach((control, index) => {
      if (!control.value && index !== controlsArray.length -1) {
        const index = this.getFormGroupIndex(
          itemToRemove.parentForm,
          this.inputName
        );

        this.getAsFormArray(itemToRemove.parentForm, this.inputArrayName)
          .removeAt(itemToRemove.index);
        this.isNewInput[index].splice(itemToRemove.index, 1);
      }
    });
  }

  /**
   * cancel method
   * @description Cancel the action to add a new FormGroup/FormControl
   * @param parentForm - FormGroup that the cancel button was clicked
   * @returns {void} void
   */
  public cancel(parentForm: FormGroup): void {    
    const indexForm = this.elementForms.controls.findIndex((control) => {
      return control === parentForm;
    })

    if (!indexForm && this.formAdded) {
      this.elementForms.removeAt(0);
      this.arrayData.shift();
      this.isNewInput.shift();
    } else {
      this.isNewInput[indexForm].reverse().forEach((input) => {
        if (input) {
          this.getAsFormArray(parentForm, this.inputArrayName).controls.pop();
        }
      });
      
      this.isNewInput[indexForm] = this.isNewInput[indexForm]
        .filter((input) => {
          return !input;
        });      
    }
    this.formAdded = false;
    this.inputAdded = false;
    this.formAdded = false;
  }

  /**
   * prepareAddRequest method
   * @description Prepare the add item DatabasesRequest object
   * to send in the service
   * @param {DatabaseItemArray} item - Item to check 
   * if has empty inputs and delete it
   * @returns {void} void 
   */
  public prepareAddRequest(item: DatabaseItemArray): void {
    const parentIndex = this.getFormGroupIndex(item.parentForm, 'regionName');

    this.getAsFormArray(item.parentForm, 'locations')
      .value.map((location, index) => {
        if (!location) {
          this.getAsFormArray(item.parentForm, 'locations').removeAt(index);
          this.isNewInput[parentIndex].splice(index, 1);
        }
      });

    if (!this.isOldFormGroup(parentIndex)) {
      const requestToSend: DatabasesRequest = {
        method: 'POST',
        endpoint: '/company/regions',
        body: item.parentForm.value,
      }
      this.request.emit(requestToSend);
    } else {
      const requestToSend: DatabasesRequest = {
        method: 'POST',
        endpoint: '/company/locations',
        body: item.parentForm.value,
      }
      this.request.emit(requestToSend);
      this.cancel(item.parentForm);
    }

    this.inputAdded = false;
    this.formAdded = false;
  }

  /**
   * isOldFormGroup method
   * @description Check if a certain FormGroup was created in the begin (old)
   * or was created with the addFormButton (new)
   * @param indexForm - index that the form has in elementForms
   * @returns {boolean} boolean
   */
  public isOldFormGroup(indexForm: number): boolean {
    return this.isNewInput[indexForm].includes(false);
  }

  /**
   * removeLastControl method
   * @description Remove (if is necessary) the last SimpleControl found
   * the FormArray 
   * @param {AbstractControl[]} arrayControls - Controls to verify 
   * and delete their input 
   * @param {DatabaseItemArray} itemToRemove - Item to verify 
   * if is able to delete it
   * @returns {void} void
   */
  private removeLastControl(arrayControls: AbstractControl[],itemToRemove: DatabaseItemArray): void {
    if (!arrayControls[arrayControls.length - 1].value) {
      const index = this.getFormGroupIndex(itemToRemove.parentForm, this.inputName);
      this.getAsFormArray(itemToRemove.parentForm, this.inputArrayName)
        .removeAt(arrayControls.length - 1);
      this.isNewInput[index].splice(arrayControls.length - 1, 1);
    }
  }

  /**
   * addControlOnMainInput method
   * @description Add a new SimpleControl in a certain FormGroup
   * if the main input has the event of input and this new control
   * is required
   * @param {FormGroup} parentForm - FormGroup to check and add the new control
   * @returns {void} void 
   */
  private addControlOnMainInput(parentForm: FormGroup): void {
    const hasEmptyInputs = this.getAsFormArray(parentForm, this.inputArrayName)
      .value.includes('');
    if (!hasEmptyInputs) {
      this.getAsFormArray(parentForm, this.inputArrayName)
        .push(this.createSimpleControl(''));
      const index = this.getFormGroupIndex(parentForm, this.inputName);
      this.isNewInput[index].push(true);
      this.inputAdded = true;
    }
  }
  
  /**
   * removeControlOnMainInput method
   * @description Remove a SimpleControl in a certain FormGroup
   * if the main input has the event of input and is required delete it
   * @param {FormGroup} parentForm - FormGroup to check and delete the control
   * @returns {void} void 
   */
  public removeControlOnMainInput(parentForm: FormGroup): void {
    const arrayControls = this.getAsFormArray(parentForm, this.inputArrayName);
    const hasEmptyInputs = arrayControls.value.includes('');
    if (hasEmptyInputs && arrayControls.length > 1) {
      const index = this.getFormGroupIndex(parentForm, this.inputName);
      arrayControls.removeAt(arrayControls.length - 1);
      this.isNewInput[index].splice(arrayControls.length - 1, 1);
    }
  }

  /**
   * disableButtons method
   * @description Emit an event to its parent component with a value
   * received from its child component to set the buttonsDisabled varible
   * @param {boolean} value - Received value
   * @returns {void} void 
   */
  public disableButtons(value: boolean): void {
    this.disableAllButtons.emit(value);
  }

  /**
   * getFormControl method
   * @description Get the specified form control from the specified form group.
   * @param {AbstractControl} form - Form to find the control.
   * @param {string} controlName - Control to find.
   * @returns {FormControl} FormControl.
   */
  public getFormControl(form: AbstractControl, controlName: string): FormControl {
    return form.get(controlName) as FormControl;
  }

  /**
   * prepareDeleteRequest method
   * @description Prepare the delete item
   * DatabasesRequest object to send in the service
   * @param {DatabaseItemArray} item - Item to delete
   * from the databases section
   * @returns {void} void
   */
  public prepareDeleteRequest(item: DatabaseItemArray): void {
    const itemId = this.arrayData.find((element) => {
      return element[this.inputName] === item.parentForm.value[this.inputName];
    })[this.inputArrayName][item.index].id;

    const requestToSend: DatabasesRequest = {
      method: 'DELETE',
      endpoint: `/company/locations/${itemId}`,
      body: {},
    }

    this.request.emit(requestToSend);
  }

  /**
   * checkDataRelated method
   * @description Prepare the data to send an event
   * to parent component to check if the given item
   * has related data
   * @param {DatabaseItemArray} item - Item to check related data
   * @returns {void} void
   */
  public checkDataRelated(item: DatabaseItemArray): void {
    let itemId = null;
    let itemName = null;
    
    this.arrayData.forEach((element) => {
      if (element[this.inputName] === item.parentForm.value[this.inputName]) {
        itemId = element[this.inputArrayName][item.index].id;
        itemName = element[this.inputArrayName][item.index].name;
      }
    });

    this.checkItemDataRelated.emit({
      id: itemId,
      name: itemName
    });
  }

  /**
   * deleteInputNameItem method
   * @description Send an event to parent component
   * to delete an main item from arrayData
   * @param {number} index - Item index position in
   * arrayData array
   * @returns {void} void
   */
  public deleteInputNameItem(index: number): void {
    this.deleteMainItem.emit({
      id: this.arrayData[index]['id'],
      name: this.arrayData[index][this.inputName]
    });
  }
}
