import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { fileTypeImage } from 'src/app/shared/custom-validators/file-type-image';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss']
})
export class ImageInputComponent implements OnInit {

  @Input() control: FormControl;
  @Input() parentForm: FormGroup;
  @Input() name: string;
  @Input() preview: string;
  @Input() editImage: boolean = false;
  @Output() data: EventEmitter<string | ArrayBuffer> = new EventEmitter<string | ArrayBuffer>();
  @Output() imgBuffer: EventEmitter<string | ArrayBuffer> = new EventEmitter<string | ArrayBuffer>();

  public imgSrc: string | ArrayBuffer;
  public imagePath: FileList;
  public message: string;
  public flagPath: string = 'assets/images/icons/flag.svg';
  public alert: boolean;

  constructor() { }

  ngOnInit() {
    this.subscribeControl()
  }

  /**
   * imageBuffer
   * Emits an event with the buffer of an image
   * @param {string | ArrayBuffer} buffer image buffer
   * @returns {void} void
   */
  public imageBuffer(buffer: string | ArrayBuffer): void {
    this.data.emit(buffer);
  }

  /**
   * subscribeControl
   * subscribe to the form to expect the changes in the values
   * @returns {void} void
   */
  private subscribeControl(): void {
    this.control.valueChanges.subscribe((img) => {
      if (!img) {
        this.imgSrc = '';
      }
    });
  }

  /**
   * renderImage
   * Gets the image from the input and render it into the label, and use a validator to check
   * that the file is valid image format
   * @param {FileList} files object that contains an array with the value of the input type file
   * @returns {void} void
   */
  public renderImage(files: FileList): void {
    let content: string = '';
    this.alert = false;
    this.control.setValidators(null);
    if (files.length === 0) {
      return;
    }
    const reader: FileReader = new FileReader();

    reader.readAsArrayBuffer(files[0]);
    reader.onload = (evt: ProgressEvent) => {

      const bytes = new Uint8Array(evt.target['result']).subarray(0, 4);

      for (let i = 0; i < bytes.length; i += 1) {
        content += bytes[i].toString(16).toUpperCase();
      }

      this.control.setValidators([fileTypeImage(content)]);
      this.control.updateValueAndValidity();

      // checking if the the control is valid
      if (this.control.invalid) {
        if (this.imgSrc || this.preview) {
          this.clearImage();
        }
        this.alert = true;
        this.control.setValidators(null);
        this.control.updateValueAndValidity();
        return;
      }


      reader.readAsDataURL(files[0]);

      reader.onload = _event => {
        this.imgSrc = reader.result;
        this.imageBuffer(this.imgSrc);
        this.control.patchValue(this.imgSrc);
      };
    };
  }

  /**
   * sendImgBuffer
   * Emit an event with the buffer
   * @param {string | ArrayBuffer} buffer of the image
   * @returns {void} void
   */
  public sendImgBuffer(buffer: string | ArrayBuffer): void {
    this.imgBuffer.emit(buffer);
  }

  /**
   * clearImage
   * sets the variables to empty strings to clean the image input
   * @returns {void} void
   */
  public clearImage(): void {
    this.imgSrc = '';
    this.preview = '';
    this.alert = false;
  }
}
