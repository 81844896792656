import { Component } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Project } from 'src/app/models/projects/project.model';
import { ClientTeamMember } from 'src/app/models/client-team-member.model';
import { AddClientMemberService } from 'src/app/services/add-client-member.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { addClientTeamMemberErrors } from 'src/app/config/error-messages';
import { alphabeticRegex } from 'src/app/config/regex';
import { validateEmailValidator } from 'src/app/shared/custom-validators/custom-validator-email';

@Component({
  selector: 'app-add-client-member',
  templateUrl: './add-client-member.component.html',
  styleUrls: ['./add-client-member.component.scss'],
  providers: [AddClientMemberService],
})
export class AddClientMemberComponent {

  public project: Project;
  public clientTeamMember: ClientTeamMember[];

  // form controls
  public clientTeamMemberNameControl: FormControl = new FormControl(' ', []);
  public clientTeamMemberRoleControl: FormControl = new FormControl('',[]);
  public clientTeamMemberEmailControl: FormControl = new FormControl('',[]);

  // Validators
  public clientTeamMemberNameValidators: Validators[] = [
    Validators.required,
    patternValidator(alphabeticRegex),
  ];
  public clientTeamMemberRoleValidators: Validators[] = [
    Validators.required,
    patternValidator(alphabeticRegex),
  ];
  public emailValidations: Validators[] = [
    Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$/),
  ];
  public clientTeamMemberEmailValidators: Validators[] = Array.from(this.emailValidations);

  // Errors
  public clientTeamMemberNameError =
    addClientTeamMemberErrors.clientTeamMemberName;
  public clientTeamMemberRoleError =
    addClientTeamMemberErrors.role;
  public clientTeamMemberEmailError =
    addClientTeamMemberErrors.email;

  //Form group
  public addClientTeamMemberForm: FormGroup = new FormGroup({
    name: this.clientTeamMemberNameControl,
    role: this.clientTeamMemberRoleControl,
    email: this.clientTeamMemberEmailControl,
  });

  constructor(
    private addClientMemberService: AddClientMemberService,
    private projectsService: ProjectsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.parent.parent.params.subscribe((params) => {
      this.getProject(params.id);
    });
  }

    /**
   * onSubmit method
   * handle the logic when submitting the form
   * @return {void}
   */
    public onSubmit(): void {
     if(!this.addClientTeamMemberForm.value.email) {
      this.addClientTeamMemberForm.removeControl('email');
     }
      this.addClientMemberService.addClientMemberProject(this.addClientTeamMemberForm.value, 
        this.project.id).subscribe(result => {
        if (result.status === 'success') {
          this.goToEditView();
        } else if (result['error'].message === 'The email already exists') {
          let validators = Array.from(this.emailValidations);
          validators.push(validateEmailValidator(result['error'].message));
          this.clientTeamMemberEmailValidators = Array.from(validators);
        }
      });
    }

  /**
   * dropErrorMessage
   * set again the validators in email
   * @return {void}
   */
  public dropErrorMessage(): void {
    if (this.clientTeamMemberEmailControl.errors) {
      if (this.clientTeamMemberEmailControl.errors.hasOwnProperty('EmailRepeat')) {
        this.clientTeamMemberEmailValidators = Array.from(this.emailValidations);
      }
    }
  }

  /**
   * getProject Method
   * This method subscribes to a getProject method in Projects Service
   * and retrieves the data of an existing project
   * @param {number} id - Project ID of the project to retrives the data
   * @returns {void}
   */
  private getProject(id: number): void {
    this.projectsService.getProject(id)
    .subscribe((project) => {
      this.project = project.data;
    });
  }

  /**
   * goToEditView Method
   * Redirects you to the Edit Project View
   * @returns {void}
   */
  public goToEditView(): void {
    this.router.navigateByUrl(`manage/projects/${this.project.id}/edit`);
  }
}
