import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  HostListener,
  ViewChildren,
  QueryList
} from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { FormatLocationsPipe } from 'src/app/pipes/format-locations.pipe';
import { SkillLevel } from 'src/app/models/skill-level.model';
import { ChangeSkillLevel } from 'src/app/models/change-skill-level.model';
import { SuggestionsService } from 'src/app/services/suggestions.service';
import { SuggestionsKeyPressedResults } from 'src/app/models/select/select-key-pressed.model';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
  providers: [FilterPipe, FormatLocationsPipe],
})
export class SuggestionsComponent implements OnInit, OnChanges {
  @ViewChild('suggestionsInput', { static: false }) suggestionsInput: ElementRef;
  @ViewChildren('stars') stars: QueryList<ElementRef>;
  @ViewChildren('options') options;

  @Input() parentForm: FormGroup;
  @Input() inputName: string;
  @Input() list: string[];
  @Input() setFocus: boolean;
  @Input() textPlaceholder: string = ' ';
  @Input() labelText: string;
  @Input() id: string;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() errors;
  @Input() isLocationsInput: boolean = false;
  @Input() nameSkill: string
  @Input() lenghtSkills: string[];
  @Input() skillLevels?: SkillLevel[];
  @Input() verticalLayout?: boolean;
  @Input() close: boolean;
  @Input() skillRepeated: boolean;
  @Input() repeatMessage: boolean;

  @Output() enter: EventEmitter<string> = new EventEmitter<string>();
  @Output() listEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tab: EventEmitter<string> = new EventEmitter<string>();
  @Output() blurred: EventEmitter<string> = new EventEmitter<string>();
  @Output() query: EventEmitter<string> = new EventEmitter<string>();
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeSkillLevel: EventEmitter<ChangeSkillLevel> = new EventEmitter<ChangeSkillLevel>();

  public setOverflow: boolean = false;
  public filtered: string[] = [];
  public selectedItem: string;
  public suggestionsOpened: boolean = false;
  public displayError: boolean = false;
  public avoidErrorOutline: boolean = true;
  public inputInFocus: boolean = false;
  public actualSkillLevel: number = 0;
  public inputHasContent: boolean = false;
  public focusedOption: number = -1;

  constructor(
    private filter: FilterPipe,
    private elementRef: ElementRef,
    private formatLocations: FormatLocationsPipe,
    private suggestionsService: SuggestionsService,
  ) { }

  /**
   * onGlobalClick method
   * determine if the user clicks the
   * outside the suggestions
   * @param {object} event click event listened
   * @return {void}
   */
  @HostListener('document:click', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.filtered = [];
      this.suggestionsOpened = false;
    }
    this.avoidErrorOutlineMethod();
  }

  /**
   * ngOnInit hook
   * set the validators when the component starts
   * @return {void}
   */
  ngOnInit(): void {
    // set validators
    this.setValidators();
  }

  /**
   * ngOnChanges hook
   * detects whenever there is a change in the
   * input properties
   * @return {void}
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (this.control && changes.list && changes.list.currentValue) &&
      (changes.list.currentValue.length > 0 && this.control.value && this.control.value.length >= 3)
    ) {
      this.suggestionsOpened = true;
      this.filtered = this.formatLocations.transform(changes.list.currentValue);

      if (this.filtered.length > 4) {
        this.setOverflow = true;
      } else {
        this.setOverflow = false;
      }
    } else {
      this.filtered = [];
      this.suggestionsOpened = false;
    }
    this.avoidErrorOutlineMethod();

    //checking changes in the variable to hide the suggestions list
    if (this.close || !this.close) {
      this.suggestionsOpened = false;
    }
  }

  /**
   * setValidators method
   * sets the validations of the control
   * @return {void}
   */
  private setValidators(): void {
    if (this.control) {
      this.control.setValidators(this.validators);
      this.control.updateValueAndValidity();
    }
  }

  /**
   * emitBlur method
   * emit evet to father in event blur
   * @param {HTMLInputElement} element
   * @return {void}
   */
  public emitBlur(element: HTMLInputElement): void {
    this.blurred.emit(element.value);
    this.trim(element);
    this.checkForErrors();
    this.avoidErrorOutlineMethod();
    this.inputInFocus = false;
  }

  /**
   * checkForErrors method
   * sets a display flag to true if
   * the control has errors
   * @return {void}
   */
  public checkForErrors(): void {
    setTimeout(() => {
      if (this.control && this.control.errors || this.skillRepeated) {
        this.displayError = true;
      } else {
        this.displayError = false;
      }
    }, 150);
  }

  /**
   * focusInput method
   * focus the suggestions input once the
   * label is clicked
   * @return {void}
   */
  public focusInput(): void {
    this.suggestionsInput.nativeElement.focus();
  }

  /**
   * makeQuery method
   * make the query to the respective API
   * based on the input of the user
   * @return {void}
   */
  public makeQuery(): void {
    // wait until 3 inputs to make the query
    if (this.control.value.length >= 3) {
      this.query.emit(this.control.value);
    } else {
      this.filtered = [];
      this.suggestionsOpened = false;
    }
  }

  /**
   * emitInput method
   * emit to father when input text
   * @param {HTMLInputElement} element - text input
   * @return {void}
   */
  public emitInput(element: HTMLInputElement): void {
    this.onInput.emit(element.value);
    this.avoidErrorOutlineMethod();
  }

  /**
   * filterList method
   * filter the list through the filter value
   * @param {object} element filter object
   * @return {void}
   */
  public filterList(element): void {
    // filter the list of users
    this.filtered = this.filter.transform(this.list, element.value);
    this.emitInput(element);
    if (this.filtered.length > 0) {
      this.suggestionsOpened = true;

      // check if there are more than four suggestions so that it gets trimmed
      if (this.filtered.length > 4) {
        this.setOverflow = true;
      } else {
        this.setOverflow = false;
      }

      this.listEvent.emit(true);
    } else {
      this.suggestionsOpened = false;
      this.listEvent.emit(false);
    }
  }

  /**
   * makeSelection method
   * select the item to be added to the form
   * @param {string} item id of the user to be selected
   * @return {void}
   */
  public makeSelection(item: string): void {
    // get the selected item
    this.selectedItem = this.filtered.find(elem => elem === item);

    // close suggestions
    this.suggestionsOpened = false;

    // set the input values
    this.parentForm.get(this.inputName).setValue(this.selectedItem);

    // empty the filtered list of users
    this.filtered = [];

    //emit selection one
    this.emitSelected.emit(item);

  }

  /**
   * emitEnter method
   * emits event when Enter key is pressed
   * @param {string} element event target
   * @return {void}
   */
  private emitEnter(element: string): void {
    if (element) {
      this.enter.emit('enter');
    }
  }

  /**
   * emitTab method
   * emits event when Tab key is pressed
   * @param {string} element event target
   * @return {void}
   */
  private emitTab(element: string): void {
    if (element) {
      this.tab.emit('tab');
    }
  }

  /**
   * trimTabValue method
   * trims the data in the input when blurring
   * through a Tab key
   * @param {HTMLInputElement} element event target
   * @return {void}
   */
  public trimTabValue(element: HTMLInputElement): void {
    if (element.value) {
      const trimValue = element.value.trim().toString();
      element.value = element.value.trim();
      this.emitTab(trimValue);
    }
  }

  /**
   * trimValue method
   * trim the data in the input when blurring
   * through an Enter key
   * @param {string} element event target
   * @return {void}
   */
  public trimValue(element: HTMLInputElement): void {
    const trimValue = element.value.trim().toString();
    element.value = element.value.trim();
    this.control.setValue(element.value);
    this.emitEnter(trimValue);
    this.selectItemEnter();
  }

  /**
   * selectItemEnter method
   * closed suggestions list
   * @return {void}
   */
  public selectItemEnter(): void {
    this.suggestionsOpened = false;
    this.filtered = [];
  }

  /**
   * trim method
   * trim the data in the input when blurring
   * @param {HTMLInputElement} element event target
   * @return {void}
   */
  public trim(element: HTMLInputElement): void {
    element.value = element.value.trim();
    this.control.setValue(element.value);
  }

  /**
   * enterPressed
   * emits an event to notify when the user press an enter and
   * selects an item from the list
   * @param {string} item event target
   * @return {boolean} false to cancel the event
   */
  public enterPressed(item: string): boolean {
    if (this.filtered.length > 0) {
      this.makeSelection(item);
      return false;
    } else {
      this.emitEnter(item);
    }
  }

  /**
   * avoidErrorOutline method
   * Specifically for signUp, it prevents the orange
   * ouline from being painted
   * @returns {boolean}
   */
  public avoidErrorOutlineMethod(): boolean {
    this.avoidErrorOutline = true;
    if (this.lenghtSkills != null && this.lenghtSkills.length > 1) {
      this.avoidErrorOutline = false;
    }
    if (this.control.errors && this.control.errors.pattern) {
      this.avoidErrorOutline = true;
    }
    return this.avoidErrorOutline;
  }

  /**
   * inputFocus method
   * @description When the input is focused change the border color
   * of the stars container
   * @param {number} starsIndex - index of the star that was be clicked
   * @returns {void} void
   */
  public inputFocus(): void {
    this.inputInFocus = true;
  }

  /**
   * changeLevel method
   * @description Is called when a star is clicked
   * receiving the array position of this star
   * @param starsIndex - Index of the star that was clicked
   * @returns {void} void
   */
  public changeLevel(starsIndex): void {
    if (this.inputHasContent) {
      this.actualSkillLevel = starsIndex;
      this.changeSkillLevel.emit({
        index: this.actualSkillLevel,
        inputName: this.nameSkill,
      });
    }
  }

  /**
   * changeInputContent method
   * @description Is called when the input is typed
   * and checks if has content or not
   * @returns {void} void
   */
  public changeInputContent(): void {
    if (this.suggestionsInput.nativeElement.value) {
      this.inputHasContent = true;
    } else {
      this.inputHasContent = false;
      this.actualSkillLevel = 0;
    }
  }

  /**
   * onKeyDown method
   * Function to handle keyboard events and handle the component with keys
   * @param {KeyboardEvent} event Keyboard-Event to be handled
   * @returns {void}
   */
  public onKeyPressed(event) {
    const results: SuggestionsKeyPressedResults = this.suggestionsService.onKeyPressed(
      event.key,
      this.focusedOption,
      this.suggestionsInput,
      this.options.toArray(),
      this.filtered,
    );

    this.focusedOption = results.focusedOption;
    if (results.emptyFiltered) {
      this.filtered = [];
    }
    return results.result;
  }

  /**
   * setFocus method
   * Set the focus in an specific element
   * @param {HTMLLIElement} element li element to set the focus
   * @param {number} i index from the element to set the focus
   * @returns {void}
   */
  public toSetFocus(element: HTMLLIElement, i: number): void {
    element.focus();
    this.focusedOption = i;
  }
}
