import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model'
import { permissions } from 'src/app/config/permissions';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-project-members-header',
  templateUrl: './project-members-header.component.html',
  styleUrls: ['./project-members-header.component.scss']
})
export class ProjectMembersHeaderComponent implements OnChanges {

  @Input() name: string;
  @Input() projectId: number;
  @Input() projectFiltersData: ProjectFiltersData;

  public projectName: string;
  public permissions = permissions;

  public menu: string =  this.router.url.split('/')[1];

  constructor(
    private router: Router,
    public permissionsService: PermissionsService
  ) { }

  /**
   * ngOnChanges hook
   * sets the project name once the data is available
   * @return {void}
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name.currentValue) {
      this.projectName = this.name;
    }
  }

  /**
   * goToProjectsView method
   * emits an event to trigger the projects list view
   * @return {void}
   */
  public goToProjectsView(): void {
    this.router.navigate([`${this.menu}/projects`], {
      state: {
        data: this.projectFiltersData
      }
    });
  }

  /**
   * goToEditView method
   * emits an event to trigger the edit project view
   * @return {void}
   */
  public goToEditView(id: number): void {
    this.router.navigateByUrl(`manage/projects/${id}/edit`);
  }
}
