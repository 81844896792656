import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import moment from 'moment';

import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { SignUpService } from 'src/app/services/sign-up.service';
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { Locations } from 'src/app/models/sign-up/locations.model';
import { UserCheckIn } from 'src/app/models/user-check-in.model';
import { UpdateCheckIn } from 'src/app/models/update-check-in.model';
import { UserService } from 'src/app/services/user.service';
import { CheckInService } from 'src/app/services/check-in.service';
import { TheOneDateCheckInsResponse } from 'src/app/models/responses/check-in-response.model';
import { ProfileResponse } from 'src/app/models/profile-response.model';

@Component({
  selector: 'app-check-in-main',
  templateUrl: './check-in-main.component.html',
  styleUrls: ['./check-in-main.component.scss']
})
export class CheckInMainComponent implements OnInit {
  
  private locationId: number;
  private eventFilter: string;
  private order: string = 'asc';
  private columnToOrder: string;
  private defaultLocationIdObtained: boolean = false;
  private sortingAscendingTitle: string = 'Ascending sort';
  private sortingDescendingTitle: string = 'Descending sort';

  public usersCheckIn: UserCheckIn[] = [];
  public usersCheckInForms: FormGroup[] = [];
  public arrivalControls: FormControl[] = [];
  public leaveLunchControls: FormControl[] = [];
  public arrivalLunchControls: FormControl[] = [];
  public leaveControls: FormControl[] = [];
  
  public dateIndex: moment.Moment = moment();
  
  public userLocation$: SelectorOption;

  public locationsOptions$: SelectorOption[] = [
    { id: null, name: 'All' },
  ];
  
  public userEventsOptions: SelectorOption[] = [
    { id: null, name: 'All'},
    { id: null, name: 'Arrive'},
    { id: null, name: 'Leave for lunch'},
    { id: null, name: 'Arrive from lunch'},
    { id: null, name: 'Leave'},
  ]
  
  public searchControl = new FormControl('', []);
  public userEventControl = new FormControl('', []);
  public locationControl = new FormControl('', []);
  
  public emptyErrors = {};
  
  public emptyValidators: Validators[] = [];

  public filtersForm = new FormGroup({
    search: this.searchControl,
    userEvent: this.userEventControl,
    location: this.locationControl,
  });

  public sortColumns;

  constructor(
    private signUpService: SignUpService,
    private userService: UserService,
    private checkInService: CheckInService,
  ) {}

  /**
   * ngOnInit hook
   * hook to get the locations and users' check ins
   * @return {void}
   */
  ngOnInit() {
    this.getLocations();

    this.sortColumns = {
      employee: {
        value: 'asc',
        title: this.sortingAscendingTitle,
      },
      arrival: {
        value: 'desc',
        title: this.sortingDescendingTitle,
      },
      leaveLunch: {
        value: 'desc',
        title: this.sortingDescendingTitle,
      },
      arrivalLunch: {
        value: 'desc',
        title: this.sortingDescendingTitle,
      },
      leave: {
        value: 'desc',
        title: this.sortingDescendingTitle,
      },
    };
  }

  /**
   * getLocations method
   * call get endpoint for locations, asign data to variable
   * @return {void}
  */
  private getLocations(): void {
    this.signUpService
      .getSignUpGeneric('/company/locations')
      .subscribe((data: Response) => {
        this.locationsOptions$ = data.data.map((location: Locations) => {
          return {
            id: location.id,
            name: location.name,
          };
        })
        this.setDefaultLocation();
      });
  }

  /**
   * setUpEventFilter method
   * sets up the filter value for the user event
   * @return {void}
   */
  public setUpEventFilter(): void {
    if (this.defaultLocationIdObtained) {
      switch (this.userEventControl.value) {
        case 'All':
        default:
          this.eventFilter = null;
          break;
        case 'Arrive':
          this.eventFilter = 'arrival';
          break;
        case 'Leave for lunch':
          this.eventFilter = 'leaveLunch';
          break;
        case 'Arrive from lunch':
          this.eventFilter = 'arrivalLunch';
          break;
        case 'Leave':
          this.eventFilter = 'leave';
          break;
      }

      this.getUsersCheckIn();
    }
  }

  /**
   * setUpLocationFilter method
   * sets up the filter value for the location
   * @return {void}
   */
  public setUpLocationFilter(): void {
    this.locationId = this.locationControl.value;
    this.getUsersCheckIn();
  }

  /**
   * setUpSortFilter method
   * changes the type of sorting to apply to the data (asc or desc)
   * @param {string} column column to which apply the sort
   * @return {void}
   */
  public setUpSortFilter(column: string): void {
    // reset all other sorting buttons to true except for the one selected
    for (let key of Object.keys(this.sortColumns)) {
      if (column !== key) {
        this.sortColumns[key].value = 'desc';
        this.sortColumns[key].title = this.sortingDescendingTitle;
      }
    }

    // if the value is true, meaning it is ascendant, change it to descendant
    if (this.sortColumns[column].value === 'asc') {
      this.order = 'desc';
      this.sortColumns[column].value = this.order;
      this.sortColumns[column].title = this.sortingDescendingTitle;
    } else {
      this.order = 'asc';
      this.sortColumns[column].value = this.order;
      this.sortColumns[column].title = this.sortingAscendingTitle;
    }

    if (column !== 'employee') {
      this.columnToOrder = column;
    } else {
      this.columnToOrder = null;
    }

    this.getUsersCheckIn();
  }

  /**
   * getUsersData method
   * get the users data to render the view
   * @return {void}
   */
  private getUsersCheckIn(): void {
    this.checkInService.getTheOneDateCheckIns(
      this.eventFilter,
      this.locationId,
      this.order,
      this.columnToOrder,
      this.dateIndex.format('YYYY-MM-DD')
    ).subscribe((response: TheOneDateCheckInsResponse) => {
      this.usersCheckIn = [];
      this.arrivalControls = [];
      this.leaveLunchControls = [];
      this.arrivalLunchControls = [];
      this.leaveControls = [];
      this.usersCheckInForms = [];
  
      response.data.forEach((userCheckIn: UserCheckIn, i: number) => {
        this.setCheckInDataToUppercase(userCheckIn)
        this.arrivalControls.push(new FormControl(userCheckIn.arrival, []));
        this.leaveLunchControls.push(new FormControl(userCheckIn.leaveLunch, []));
        this.arrivalLunchControls.push(new FormControl(userCheckIn.arrivalLunch, []));
        this.leaveControls.push(new FormControl(userCheckIn.leave, []));
        
        this.usersCheckInForms.push(new FormGroup({
          arrival: this.arrivalControls[i],
          leaveLunch: this.leaveLunchControls[i],
          arrivalLunch: this.arrivalLunchControls[i],
          leave: this.leaveControls[i],
        }));
      });
  
      this.usersCheckIn = response.data;
    });
  }

  /**
   * addToDateIndex() method
   * add a day to date index
   * @return {void}
   */
  public addToDateIndex(): void {
    this.dateIndex.add(1, 'd');
    this.getUsersCheckIn();
  }

  /**
   * subtractToDateIndex() method
   * substract a day to date index
   * @return {void}
   */
  public subtractToDateIndex(): void {
    this.dateIndex.subtract(1, 'd');
    this.getUsersCheckIn();
  }
  
  /**
   * setEvent method
   * Set an event in a specific user row in a specific column
   * @param {number} i array's index
   * @param {string} column array's column (arrival, leaveLunch, arrivalLunch
   *  or leave)
   * @param {string} event event type (now, stayed, was already or home office)
   * @returns {void}
   */
  public setEvent(i: number, column: string, event: string): void {
    const dataCheckIn = {};
    
    if (event === 'now') {
      event = moment().format('HH:mm');
    } else if (event === 'Home office') {
      dataCheckIn['leave'] = 'Home office';
      if (column === 'arrival') {
        dataCheckIn['arrivalLunch'] = 'Home office';
        dataCheckIn['leaveLunch'] = 'Home office';
      }
    }
    
    dataCheckIn[column] = event;
    this.setInputValue(i, column, event, dataCheckIn);
  }

  /**
   * setInputValue method
   * Set a value in an input
   * @param {number} i array's index
   * @param {string} column array's column (arrival, leaveLunch, arrivalLunch
   *  or leave)
   * @param {string} value value to set in the input (now, stayed, was already
   *  or home office)
   * @param {UpdateCheckIn} dateCheckIn values to set in the one date check in
   * @returns {void}
   */
  public setInputValue(i: number, column: string, value: string, dataCheckIn: UpdateCheckIn): void {
    if (this.usersCheckIn[i][column] !== value) {
      this.checkInService.updateUserCheckIn(
        this.usersCheckIn[i].userId,
        dataCheckIn,
        this.dateIndex.format('YYYY-MM-DD'),
      ).subscribe((response: Response) => {
        if (response.status === 'success') {
          for (const column of Object.keys(dataCheckIn)) {
            this.usersCheckIn[i][column] = dataCheckIn[column];
            this[`${column}Controls`][i].setValue(dataCheckIn[column]);
          }
        } else {
          this.getUsersCheckIn();
        }
      });
    }
  }

  /**
   * setDefaultLocation method
   * set the location from the logged user in the location selector
   * @returns {void}
   */
  private setDefaultLocation(): void {
    this.userService.getUser(+localStorage.getItem('id')).subscribe(
      (response: ProfileResponse) => {
        this.userLocation$ = this.locationsOptions$.find(
          location => location.name === response.data.location,
        );
        this.locationId = +this.userLocation$.id;
        this.defaultLocationIdObtained = true;

        this.getUsersCheckIn();
      }
    );
  }

    /**
   * setCheckInDataToUppercase method
   * sets the first letter of the data to uppercase
   * @param {CheckIn} checkIn check in data
   * @return {void}
   */
  private setCheckInDataToUppercase(checkIn: UserCheckIn): void {
    const timeNames: string[] = [
      'arrival', 
      'leaveLunch', 
      'arrivalLunch', 
      'leave'
    ];

    timeNames.forEach(timeName => {
      if (checkIn[timeName]) {
        checkIn[timeName] = `${checkIn[timeName][0].toUpperCase()}${checkIn[timeName].slice(1)}`;
      }
    });
  }

}
