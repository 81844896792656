import { Response } from 'src/app/models/response.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()

export class ForgotpasswordService {

  constructor (private http: HttpClient) {}

  /**
   * sendMail method
   * method that consume the backend endpoint send the email to reset the
   * password
   * @param {string} email email to send the mail to reset the password
   * @returns {Observable<Response>} return the backend response
   */
  public sendMail(email: string): Observable<Response>{
    return this.http.post<Response>(
      `${ environment.apiUrl }/users/forgot-password/${ email }`,
      {}
    ).pipe(
      catchError((error) => {
        return of(error.error);
      }),
    );
  }

  /**
   * setNewPassword method
   * method that consume the backend endpoint to set the new password
   * @param {string} hash is generated by backend and is 
   * necesary to authenticate and reset the password
   * @param {string} password new password for the BTS account
   * @returns {Observable<Response>} return the backend response
   */
  public setNewPassword(hash: string, password: string): Observable<Response>{
    return this.http.put<Response>(
      `${ environment.apiUrl }/users/update-password`,
      { hash, password }
    ).pipe(
      catchError((error) => {
        return of(error.error);
      }),
    );
  }

  /**
   * getUrlAvailability method
   * @description Method that consume the backend endpoint to check
   * if the url is available to use.
   * @param hash - UUID hash identifier of the url
   * @returns {Observable<Response>} return the backend response
   */
  public getUrlAvailability(hash: string): Observable<Response>{
    return this.http.get<Response>(
      `${environment.apiUrl}/users/forgot-password-availability/${hash}`
    ).pipe(
      catchError((error) => {
        return of(error.error);
      }),
    );
  }
}
