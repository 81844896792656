import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Validates the type of an image, specifically if the extension is .png o .jpeg
 * @param {string} mime string in hexadecimal number that represents the real format of a file
 * @returns {ValidatorFn} control with object that has key inside with the key of error name and a value
 * of true if it's valid or null if not
 */
function fileTypeImage(mime): ValidatorFn {
  return (control: AbstractControl) => {
    switch (true) {
      case mime === '89504E47':
      case mime.startsWith('FFD8'):
        return null;
      default:
        return {
          fileTypeImage: true
        };
    }
  };
}

export { fileTypeImage };
