import {
  Component,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import { Router } from "@angular/router";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { SignUpService } from "src/app/services/sign-up.service";
import { SignUp } from "src/app/models/sign-up/sign-up.model";
import { Response } from "src/app/models/sign-up/generic-response.model";
import { Skills } from "src/app/models/sign-up/skills.model";
import { fileTypeImage } from "src/app/shared/custom-validators/file-type-image";
import { ResponseSignUp } from "src/app/models/sign-up/response-sign-up.model";
import { signUpFormErrorMessages } from "src/app/config/error-messages";
import * as uuid from "uuid";
import { SuggestionsComponent } from "src/app/shared/components/suggestions/suggestions.component";
import { alphaNumberNoEmojisRegex } from "src/app/config/regex";
import { UserSkillsService } from "src/app/services/user-skills.service";
import { SkillLevel } from "src/app/models/skill-level.model";
import { SkillLevelsResponse } from "src/app/models/skill-levels-response.model";
import { ChangeSkillLevel } from "src/app/models/change-skill-level.model";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: "app-sign-up-view-three",
  templateUrl: "./sign-up-view-three.component.html",
  styleUrls: ["./sign-up-view-three.component.scss"],
})
export class SignUpViewThreeComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() formSignUp: FormGroup;
  @Output() submitForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public name: string;
  public img: string | ArrayBuffer;
  public showMessageSkills: boolean = false;
  public skillsList$: string[];
  public skills: string[] = ["skill1"];
  public icon: string = "assets/images/icons/cuestion.svg";
  public iconCross: string = "assets/images/icons/close.svg";
  public sameSkill: boolean = false;
  public skillRepeated: boolean[] = [false];
  private image: object;
  private sendObject: SignUp;
  private dropDownList: boolean = false;

  public skillLevels: SkillLevel[];
  public actualSkillLevels: number[] = [0];

  public descriptionControl = new FormControl(" ", []);

  public descriptionError = signUpFormErrorMessages.descriptionError;

  public descriptionValidators: Validators[] = [
    Validators.required,
    Validators.pattern(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑÄËÏÖÜäëïöü'-_.,\\!"#$%&\/°~<>`^()=?¡¬`\s]*[A-Za-z][A-Za-zÁÉÍÓÚáéíóúñÑÄËÏÖÜäëïöü'-_.,\\!"#$%&\/°~<>`^()=?¡¬`\s]{0,}$/
    ),
  ];

  public skillsValidators: Validators[] = [
    Validators.pattern(alphaNumberNoEmojisRegex),
  ];

  // Form Group
  public formSignUpThree: FormGroup = new FormGroup({
    description: this.descriptionControl,
    image: new FormControl(this.image, fileTypeImage("")),
    skill1: new FormControl("", [Validators.pattern(alphaNumberNoEmojisRegex)]),
  });

  /**
   * clickWindow event
   * listener to click in page
   * @param {KeyboardEvent} e
   * @return {void}
   */
  @HostListener("click", ["$event"]) clickWindow(e: KeyboardEvent): void {
    if (e.srcElement["id"] !== "imgCuestion" || !e) {
      this.showMessageSkills = false;
    }
  }

  /**
   * onEnter event
   * listener to avoid the and tab
   * @param {keyboardEvent} e pressed key
   * @return {void}
   */
  @HostListener("keydown", ["$event"]) onEnter(e: KeyboardEvent): void {
    if (e.keyCode === 9 && !this.dropDownList) {
      e.preventDefault();
    }
  }

  constructor(
    private route: Router,
    private signUpService: SignUpService,
    private userSkillsService: UserSkillsService
  ) {}

  ngOnInit(): void {
    this.getSkills();
    this.getSkillLevels();
    this.name = this.formSignUp.controls.formOne.value.firstName;
    this.image = this.formSignUp.controls.formTwo.value.res;

    //If exist image, render
    if (this.image) {
      this.readImage(this.image);
    }
  }

  /**
   * showMessage method
   * change variable showMessageSkills to true
   * @return {void}
   */
  public showMessage(): void {
    this.showMessageSkills = true;
  }

  /**
   * showMessage method
   * change variable showMessageSkills to false
   * @return {void}
   */
  public hideMessage(): void {
    this.showMessageSkills = false;
  }

  /**
   * getSkills method
   * call get ednpoint for skills, asign data to the variable
   * @return {void}
   */
  private getSkills(): void {
    this.signUpService
      .getSignUpGeneric("/skills")
      .subscribe((data: Response) => {
        this.skillsList$ = data.data.map((skill: Skills) => {
          return skill.name;
        });
      });
  }

  /**
   * readImage method
   * render image after select
   * @param image content of input type file
   */
  public readImage(image): void {
    if (image.files && image.files[0]) {
      this.getMagicNumber(image.files[0]);
      let reader: FileReader = new FileReader();
      reader.onload = (_event) => {
        this.img = reader.result;
      };
      reader.readAsDataURL(image.files[0]);
    }
  }

  /**
   * getMagicNumber method
   * read the image buffer to see if it
   * is actually an image
   * @param {Blob} data contain image
   * @return {void}
   */
  public getMagicNumber(data: Blob): void {
    this.formSignUpThree.controls.image.setValidators(null);
    let header = "";
    const file = data;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (event: ProgressEvent) => {
      const bytes = new Uint8Array(event.target["result"]).subarray(0, 4);

      for (let i = 0; i < bytes.length; i++) {
        header += bytes[i].toString(16).toUpperCase();
      }
      this.formSignUpThree.controls.image.setValidators([
        fileTypeImage(header),
      ]);
      this.formSignUpThree.controls.image.updateValueAndValidity();
    };
  }

  /**
   * falsesubmit method
   * submit fake to avoid enter
   * @returns {boolean}
   */
  public falseSubmit(): boolean {
    return false;
  }

  /**
   * submit method
   * create a object for post SignUp and send to
   * endpoint createUser
   * @return {void}
   */
  public submit(): void {
    let description = this.formSignUpThree.controls.description.value;
    let skillsToAdd = [];
    let counter = 0;

    this.blockUI.start();
    for (const iterator in this.formSignUpThree.value) {
      if (iterator.startsWith("skill")) {
        if (this.formSignUpThree.value[iterator].trim() !== "") {
          skillsToAdd.push({
            skill: this.formSignUpThree.value[iterator].trim(),
            levelId: this.skillLevels[this.actualSkillLevels[counter]].id,
          });
        }

        counter += 1;
      }
    }

    description = description.charAt(0).toUpperCase() + description.slice(1);
    description = description.replace(/\n+/g, "\n");
    description = description.replace(/[ \t]+/g, " ");

    this.sendObject = {
      firstName: this.formSignUp.controls.formOne.value.firstName,
      lastName: this.formSignUp.controls.formOne.value.lastName,
      email: this.formSignUp.controls.formOne.value.email.toLowerCase(),
      password: this.formSignUp.controls.formOne.value.password,
      validatePassword: this.formSignUp.controls.formOne.value.validatePassword,
      companyField: this.formSignUp.controls.formTwo.value.field,
      companySeniority: this.formSignUp.controls.formTwo.value.seniority,
      companyPosition: this.formSignUp.controls.formTwo.value.position,
      companyLocation: this.formSignUp.controls.formTwo.value.location,
      photo: this.img,
      description: description,
      skills: skillsToAdd,
    };

    // Post user
    this.signUpService.createUser(this.sendObject).subscribe(
      (data: ResponseSignUp) => {
        if (data.status === "success") {
          localStorage.setItem("firstIn", "true");
          localStorage.setItem("auth", data.data.token);
          localStorage.setItem("id", data.data.id.toString());
          this.route.navigateByUrl("/dashboard/profile");
        }

        this.blockUI.stop();
      },
      (error) => {
        this.blockUI.stop();
      }
    );
  }

  /**
   * checklist method
   * check if the list is below
   * @param {boolean} $event suggestions component event emit if
   * list is below
   * @return {void}
   */
  public checklist($event: boolean): void {
    this.dropDownList = $event;
  }

  /**
   * checkTab method
   * check if user press tab and call method addskill
   * @param {SuggestionsComponent} event input in progress
   * @returns {void}
   */
  public checkTab(event: SuggestionsComponent): void {
    if (!this.dropDownList) {
      this.addSkill(event);
    }
  }

  /**
   * filterSkills method
   * compare if some input skill is repeat
   * @return {void}
   */
  public filterSkills(): void {
    for (const iterator of this.skills) {
      for (const [index, content] of this.skills.entries()) {
        this.sameSkill = false;
        const valueIterator = this.formSignUpThree.controls[iterator].value
          .toLowerCase()
          .trim();
        const valueContent = this.formSignUpThree.controls[content].value
          .toLowerCase()
          .trim();
        if (
          valueIterator !== "" &&
          iterator !== content &&
          valueIterator === valueContent
        ) {
          this.sameSkill = true;
          this.skillRepeated[index] = true;
          return;
        } else {
          this.skillRepeated[index] = false;
        }
      }
    }
  }

  /**
   * getValidInput method
   * return if the valid input count is greater than one
   * @return {boolean}
   */
  public getValidInput(): boolean {
    let count: number = 0;

    for (const iterator in this.formSignUpThree.controls) {
      if (iterator.startsWith("skill")) {
        if (this.formSignUpThree.controls[iterator].errors) {
          if (this.formSignUpThree.controls[iterator].errors["pattern"]) {
            return true;
          }
        }
        if (
          this.formSignUpThree.controls[iterator].status.toString() === "VALID"
        ) {
          count++;
        }
      }
    }
    return !(count > 0);
  }

  /**
   * getValidForm method
   * returns image and description validation
   * @return {boolean}
   */
  public getValidForm(): boolean {
    return !(
      this.formSignUpThree.controls.description.valid &&
      this.formSignUpThree.controls.image.valid
    );
  }

  /**
   * addSkill method
   * check there are no repetitive skills, and
   * check if last input skill have content
   * add new form control to from group
   * add input interactly
   * @param {SuggestionsComponent} event input in progress
   * @return {void}
   */
  public addSkill(event: SuggestionsComponent): void {
    const lastSkill = this.skills[this.skills.length - 1];
    if (!this.sameSkill) {
      if (this.formSignUpThree.controls[lastSkill].value.trim() !== "") {
        event.suggestionsInput.nativeElement.blur();
        const skillId = uuid.v4();
        this.formSignUpThree.addControl(
          `skill${skillId}`,
          new FormControl("", [
            Validators.required,
            Validators.pattern(alphaNumberNoEmojisRegex),
          ])
        );
        this.skills.push(`skill${skillId}`);
        this.actualSkillLevels.push(0);
        this.skillRepeated.push(false);
      }
    }
  }

  /**
   * deleteSkill method
   * delete a control from formSignUpThree
   * @param {number} index index of skill input
   * @param {string} name name of skill input
   * @return {void}
   */
  public deleteSkill(index: number, name: string): void {
    this.formSignUpThree.removeControl(name);
    this.skills.splice(index, 1);
    this.actualSkillLevels.splice(index, 1);
  }

  /**
   * checkContent method
   * verify if there is more than one input
   * if have value and is not the last
   * @param {number} total total off dinamic skill inputs
   * @param {string} value value of the current skill input
   * @param {number} index index of current skill inpupt
   * @return {void}
   */
  public checkContent(
    total: number,
    value: string,
    index: number,
    name: string
  ): void {
    if (total > 1 && !value && total !== index + 1) {
      this.deleteSkill(index, name);
    }
  }

  /**
   * toLogin method
   * redirect to login
   * @return {void}
   */
  public toLogin(): void {
    this.route.navigateByUrl("login");
  }

  /**
   * getSkillLevels method
   * @description Use the UserSkillsService to get all the existent
   * skill levels
   * @returns {void} void
   */
  private getSkillLevels(): void {
    this.userSkillsService
      .getSkillLevels()
      .subscribe((response: SkillLevelsResponse) => {
        this.skillLevels = response.data;
      });
  }

  /**
   * changeSkillLevel method
   * @description Listen the event changeSkillLevel
   * emmited by Suggestions Component and change
   * the actualSkillLevel element that change in suggestions
   * @param {ChangeSkillLevel} inputChanged - input that change its skill level
   * @returns {void} void
   */
  public changeSkillLevel(inputChanged: ChangeSkillLevel): void {
    const indexChanged = this.skills.findIndex((level) => {
      return level === inputChanged.inputName;
    });

    this.actualSkillLevels[indexChanged] = inputChanged.index;
  }
}
