import { Injectable, ElementRef } from '@angular/core';
import { NestedSelectorOption } from '../models/select/nested-selector-option.model';
import { KeyPressedResults } from '../models/select/select-key-pressed.model';

@Injectable({
  providedIn: 'root'
})
export class SelectService {

  constructor() { }

  /**
   * onKeyPressed method
   * method to handle a select with weys
   * @param {string} keyPressed key pressed by the user
   * @param {boolean} showOptions if the select is showing the options
   * @param {number} focusedOption option with the focus
   * @param {ElementRef[]} optionsElements array of elements to select
   * @param {number} selectedOptionPosition position of the selected option
   * @param {NestedSelectorOption[]} options array of options to select
   * @returns {KeyPressedResults} results to update the component that called
   * the function
   */
  onKeyPressed(
    keyPressed: string,
    showOptions: boolean,
    focusedOption: number,
    optionsElements: ElementRef[],
    selectedOptionPosition: number,
    options: NestedSelectorOption[],
  ): KeyPressedResults {
    const results: KeyPressedResults = {
      result: false,
      showOptions,
      focusedOption,
      makeSelection: { id: null, name: null},
    }

    switch (keyPressed) {
      case "ArrowDown":
        if (!results.showOptions) {
          results.showOptions = true;
          return results;
        }
        let toAdd: number = 1;
        if (results.focusedOption + 1 === selectedOptionPosition) {
          toAdd += 1;
        }
        if (results.focusedOption + toAdd < optionsElements.length) {
          results.focusedOption += toAdd;
        }

        optionsElements[results.focusedOption].nativeElement.focus();
        return results;
        
      case "ArrowUp":
        if (!results.showOptions || results.focusedOption <= -1) {
          return results;
        }
        let tosubstract: number = 1;
        if (results.focusedOption - 1 === selectedOptionPosition) {
          tosubstract += 1;
        }
        if (results.focusedOption - tosubstract >= 0) {
          results.focusedOption -= tosubstract;
        }
        optionsElements[results.focusedOption].nativeElement.focus();
        return results;

      case "Tab":
        if (results.showOptions) {
          results.focusedOption = -1;
          results.showOptions = false;
        }
      break;

      case "Escape":
        if (results.showOptions) {
          results.focusedOption = -1;
          results.showOptions = false;
          return results;
        }
      break;
        
      case "Enter":
        if (!results.showOptions) {
          results.showOptions = true;
          results.focusedOption = -1;
        } else {
          results.makeSelection = options[results.focusedOption];
        }
        return results;
    }
    results.result = true;
    return results;
  }
}
