import { Injectable } from '@angular/core';
import { 
  HttpRequest, 
  HttpHandler, 
  HttpInterceptor, 
  HttpEvent 
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor() {}

  /**
   * interceptor method
   * will be called on all HTTP requests from the application to set
   * the headers for them
   * @return {Observable<HttpEvent<any>>} observable with the HTTP event
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedRequest: HttpRequest<any>;

    /**
     * the API doesn't work if these headers are not removed
     * as it will not accept the GET request for the cities
     */
    if (request.url.startsWith(environment.citiesApiUrl)) {
      request.headers.delete('Access-Control-Request-Headers');
      request.headers.delete('Access-Control-Request-Method');

      return next.handle(request);
    }

    // set the auth token as header if exists
    if (localStorage.getItem('auth')) {
      // update the request headers
      updatedRequest = request.clone({
        headers: request.headers.set(
          'Authorization', 
          `Bearer ${localStorage.getItem('auth')}`
        ),
      });

      return next.handle(updatedRequest);
    }

    return next.handle(request);
  }
}
