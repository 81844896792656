import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-projects-header',
  templateUrl: './create-projects-header.component.html',
  styleUrls: ['./create-projects-header.component.scss']
})
export class CreateProjectsHeaderComponent {

  constructor(private router: Router) { }

  /**
   * goToProjectsView method
   * go back to the projects list view
   * @return {void}
   */
  public goToProjectsView(): void {
    this.router.navigateByUrl('manage/projects');
  }

}
