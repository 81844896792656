import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  HostListener,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { TimesheetService } from 'src/app/services/timesheet.service';
import { SelectorComponent } from 'src/app/shared/components/selector/selector.component';
import { KEY_CODE } from 'src/app/config/key-codes';

@Component({
  selector: "app-update-header",
  templateUrl: "./update-header.component.html",
  styleUrls: ["./update-header.component.scss"],
  providers: [DatePipe]
})
export class UpdateHeaderComponent implements OnInit {
  public date: Date;
  public dates: Date[] = [];
  public actualPeriod: string = 'Per period';
  private actualStatus: string = 'All';

  @ViewChild('status', {static: false}) status: SelectorComponent;
  @ViewChild('period', {static: false}) period: SelectorComponent;

  @Input() totalHoursMessage: string;
  @Input() onFocus: boolean;

  @Output() emitEvent: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  @Output() statusFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output() periodFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output() scrollTop: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private datePipe: DatePipe,
    public timesheetService: TimesheetService
  ) {}
  
  /**
   * keyEvent HostListener
   * @description Listen the keyup event emmited
   * when you push a key and after release the key.
   * @param {string} eventName - Event Name to listen,
   * in this case the keyup event.
   * @param {string[]} args - extra args that Listener needs,
   * in this case the $event arg used to know which key was
   * pressed and released.
   * @returns {void} void
   */
  @HostListener('window: keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (true) {
      case event.keyCode === KEY_CODE.LEFT_ARROW
      && !this.onFocus: {
        this.back(this.actualPeriod);
        break;
      }

      case event.keyCode === KEY_CODE.RIGHT_ARROW
      && !this.onFocus: {
        this.next(this.actualPeriod);
        break;
      }
    }
  }

  /**
   * ngOnInit hook
   * gets the days of this bi-week
   */
  ngOnInit() {
    this.date = new Date();
    this.getDays('Per period');
    this.timesheetService.setUpForm();
  }

  /**
   * getDays method
   * gets the days corresponding to this bi-week
   * @returns {void}
   */
  public getDays(period): void {
    let start = null;
    let end = null;
    this.dates = [];
    const daysInMonth = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 1,
      0
    ).getDate();
    switch (period) {
      case 'Per period': {
        start = this.date.getDate() <= 15 ? 1 : 16;
        end = this.date.getDate() <= 15 ? 15 : daysInMonth;
        break;
      }

      case 'Per month': {
        start = 1;
        end = daysInMonth;
        break;
      }

      case 'Per week': {
        start = this.date.getDate() - (this.date.getDay() - 1);
        end = start + 6;
      }
    }
    let date;
    for (let day = start; day <= end; day++) {
      date = new Date(this.date);
      date.setDate(day);
      this.dates.push(date);
    }
    this.emitEvent.emit(this.dates);
  }

  /**
   * getPeriodNumber method
   * gets the text for the title
   * @returns {string} month and pay period
   */
  public getPeriodNumber(period): string {
    let ordinal = "th";
    let periodNumber = null;
    let periodName = null;
    let month = this.datePipe.transform(this.date, "MMMM");
    const year = this.date.getFullYear();
    switch (period) {
      case 'Per period': {
        periodNumber = (this.date.getMonth() + 1) * 2;
    
        if (this.date.getDate() <= 15) {
          periodNumber --;
        }

        periodName = 'Period'
        break;
      }

      case 'Per month': {
        periodNumber = this.date.getMonth() + 1;
        periodName = 'Month';
        break;
      }

      case 'Per week': {
        periodNumber = this.getNumberWeek(this.date);
        periodName = 'Week';
        break;
      }
    }

    if (Math.floor(periodNumber / 10) !== 1) {
      switch (periodNumber % 10) {
        case 1:
          ordinal = "st";
          break;
        case 2:
          ordinal = "nd";
          break;
        case 3:
          ordinal = "rd";
          break;
      }
    }

    return `${month} ${year} - ${periodName} ${periodNumber}${ordinal}`;
  }

  /**
   * back method
   * updates the date a period ago
   * @returns {void}
   */
  public back(period): void {
    const previousMonth =
      this.date.getMonth() === 0 ? 11 : this.date.getMonth() - 1;

    this.scrollTop.emit('scrollTop');
    switch (period) {
      case 'Per period': {
        if (this.date.getDate() >= 16) {
          this.date.setDate(1);
        } else {
          this.date.setDate(16);
          this.date.setMonth(previousMonth);
          if (previousMonth == 11) {
            this.date.setFullYear(this.date.getFullYear() - 1);
          }
        }
        break;
      }

      case 'Per month': {
        this.date.setDate(1);
        this.date.setMonth(previousMonth);
        if (previousMonth == 11) {
          this.date.setFullYear(this.date.getFullYear() - 1);
        }
        break;
      }

      case 'Per week': {
        this.date.setDate(this.date.getDate() - 7);
        break;
      }
    }

    this.getDays(this.actualPeriod);
  }

  /**
   * next method
   * updates the date to the next period
   * @returns {void}
   */
  public next(period): void {
    const nextMonth =
      this.date.getMonth() === 11 ? 0 : this.date.getMonth() + 1;

    this.scrollTop.emit('scrollTop');
    switch (period) {
      case 'Per period': {
        if (this.date.getDate() >= 16) {
          this.date.setDate(1);
          this.date.setMonth(nextMonth);
          if (nextMonth == 0) {
            this.date.setFullYear(this.date.getFullYear() + 1);
          }
        } else {
          this.date.setDate(16);
        }
        break;
      }

      case 'Per month': {
        this.date.setDate(1);
        this.date.setMonth(nextMonth);
        if (nextMonth == 0) {
          this.date.setFullYear(this.date.getFullYear() + 1);
        }
        break;
      }

      case 'Per week': {
        this.date.setDate(this.date.getDate() + 7);
        break;
      }
    }

    this.getDays(this.actualPeriod);
  }

  /**
   * setStatusValue method
   * Set the value of actualStatus in a new one if this was changed
   * in the status selector
   * @returns {void}
   */
  public setStatusValue(): void {
    if (this.status.selectedOption.name !== this.actualStatus) {
      this.actualStatus = this.status.selectedOption.name;
      this.statusFilter.emit(this.actualStatus);
    }
  }

  /**
   * setPeriodValue method
   * Set the value of actualPeriod in a new one if this was changed
   * in the period selector
   * @returns {void}
   */
  public setPeriodValue(): void {
    if (this.period.selectedOption.name !== this.actualPeriod) {
      this.actualPeriod = this.period.selectedOption.name;
      this.getDays(this.actualPeriod);
      this.periodFilter.emit(this.actualPeriod);
    }
  }

  /**
   * getNumberWeek method
   * Get the number of the week in the year of a certain date
   * @param { Date } date - Date to get the week number
   * @returns {number}
   */
  public getNumberWeek(date: Date): number {
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    let week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
  }
}
