import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { merge, Subscription } from 'rxjs';

import { ProfileService } from 'src/app/services/profile.service';
import { UserExperience } from 'src/app/models/profile/user-experience.model';

@Component({
  selector: 'app-user-experiences',
  templateUrl: './user-experiences.component.html',
  styleUrls: ['./user-experiences.component.scss'],
})
export class UserExperiencesComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() experiences: UserExperience[];
  @Input() addExperienceHidden: boolean = false;
  @Input() userId: number;
  @Input() personalInfoInEdit: boolean;

  @Output() onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

  // subscriptions
  public addExperienceSubs: Subscription;
  public editExperienceSubs: Subscription;
  public displayExperienceSubs: Subscription;

  // default view
  public displayExperiencesView: boolean = true;
  public addExperienceView: boolean = true;
  public editExperienceView: boolean = true;

  constructor(
    private profileService: ProfileService,
    private cdRef: ChangeDetectorRef
  ) {}

  /**
   * ngOnInit hook
   * subscribes to the different events that can be triggered
   * @return {void}
   */
  ngOnInit() {
    this.addExperienceSubs = this.profileService.addExperienceView.subscribe(() => {
      this.goToAddExperienceView();
    });

    this.editExperienceSubs = this.profileService.editExperienceView.subscribe((experience) => {
      this.profileService.experienceToEdit = experience;
      this.goToEditExperienceView();
    });

    // merge the subjects
    // show the buttons once any of these events happen
    this.displayExperienceSubs = merge(
      this.profileService.displayExperiencesView,
      this.profileService.experienceAdded,
      this.profileService.experienceUpdated
    ).subscribe(() => {
      this.profileService.showButtons.next();
      this.goToDisplayExperiencesView();
    });
  }

  /**
   * ngAfterViewInit hook
   * once the components that are part of this one are fully loaded,
   * set the components that are not the default view to false.
   * This helps avoiding the flickering effect when toggling the
   * flags
   * @return {void}
   */
  ngAfterViewInit() {
    this.addExperienceView = false;
    this.editExperienceView = false;
    /**
     * run Angular's change detection to ensure the changes to
     * the properties are taken into account
     */
    this.cdRef.detectChanges();
  }

  /**
   * goToAddExperienceView method
   * sets all the flags for the other views
   * to false except the one for the add
   * form
   * @return {void}
   */
  public goToAddExperienceView(): void {
    this.addExperienceView = true;
    this.displayExperiencesView = false;
    this.editExperienceView = false;

    this.onEdit.emit(true);
  }

  /**
   * goToEditExperienceView method
   * sets all the flags for the other views
   * to false except the one for the edit form
   * @return {void}
   */
  public goToEditExperienceView(): void {
    this.displayExperiencesView = false;
    this.editExperienceView = true;
    this.addExperienceView = false;

    this.onEdit.emit(true);
  }

  /**
   * goToDisplayExperiencesView method
   * sets all the flags for the other views
   * to false except the one for experiences list view
   * @return {void}
   */
  public goToDisplayExperiencesView(): void {
    this.displayExperiencesView = true;
    this.editExperienceView = false;
    this.addExperienceView = false;

    this.onEdit.emit(false);
  }

  /**
   * ngOnDestroy hook
   * unsubscribe from all subjects obtained in ngOnInit
   * @return {void}
   */
  ngOnDestroy() {
    this.addExperienceSubs.unsubscribe();
    this.editExperienceSubs.unsubscribe();
  }
}
