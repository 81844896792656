import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ViewChild, ElementRef, QueryList } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-filterable-combo',
  templateUrl: './filterable-combo.component.html',
  styleUrls: ['./filterable-combo.component.scss']
})
export class FilterableComboComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() errors: any;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() options: any[] = [];
  @Input() capitalizeOptions: boolean = false;
  @Input() nullId: boolean = false;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  public filterText: string = '';
  public filteredOptions: any[] = [];
  public showOptions: boolean = false;
  public selectedIndex: number = -1;

  @ViewChildren('optionElement') optionElements: QueryList<ElementRef>;
  @ViewChild('filterInput', { static: false }) filterInput: ElementRef<HTMLInputElement>;

  constructor() { }

  ngOnInit() {
    this.filteredOptions = this.options;
    this.setValidators();
    this.normalizeOptions();
  }

  public filterOptions(): void {
    if (!this.filterText) {
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options.filter(option =>
        option.name.toLowerCase().includes(this.filterText.toLowerCase())
      );
    }
    this.selectedIndex = -1; 
  }

  public normalizeOptions(): void {
    this.options = this.options.map(option => {
      return {
        ...option,
        name: option.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
      };
    });
  }


  public toggleOptions(): void {
    this.showOptions = !this.showOptions;
    if (this.showOptions) {
      this.filterText = '';
      this.filterOptions();
      setTimeout(() => {
        const optionsContainer = document.querySelector('.options-container') as HTMLElement;
        if (optionsContainer) {
          optionsContainer.focus();
        }
        // Set focus on the filter input
        if (this.filterInput) {
          this.filterInput.nativeElement.focus();
        }
      });
    }
  }

  public selectOption(option: any): void {
    console.log(option);
    this.control.setValue(this.nullId ? option.name : option);
    this.showOptions = false;
    this.selected.emit(option);

    // Ensure focus on the filter input after selecting an option
    if (this.filterInput) {
      setTimeout(() => {
        this.filterInput.nativeElement.focus();
      });
    }
  }

  private setValidators(): void {
    if (this.validators) {
      this.control.setValidators(this.validators);
      this.control.updateValueAndValidity();
    }
  }

  public checkForErrors(): void {
    // Implement error checking logic here
  }

  public onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.navigateOptions(1);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.navigateOptions(-1);
        break;
      case 'Enter':
        if (this.selectedIndex > -1) {
          this.selectOption(this.filteredOptions[this.selectedIndex]);
        }
        break;
      case 'Escape':
        this.showOptions = false;
        break;
    }
  }

  private navigateOptions(direction: number): void {
    if (!this.filteredOptions.length) return;

    this.selectedIndex = (this.selectedIndex + direction + this.filteredOptions.length) % this.filteredOptions.length;
    
    const optionElementsArray = this.optionElements.toArray();
    const optionElement = optionElementsArray[this.selectedIndex];
    if (optionElement) {
      optionElement.nativeElement.scrollIntoView({ block: 'nearest' });
    }
  }
}
