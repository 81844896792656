import { Injectable } from '@angular/core';
import { Response } from 'src/app/models/response.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Skill } from 'src/app/models/skill.model';
import { SkillLevelsResponse } from 'src/app/models/skill-levels-response.model';

@Injectable({
  providedIn: 'root',
})

export class UserSkillsService {

  constructor(private http: HttpClient) {}

    /**
   * addSkill
   * sends the new skill to backend
   * @param {Skill} skill the skill to be added
   * @param {number} userId the user id
   * @returns {Observable} an observable with the information of the response
   * from the server
   */
  public addSkill(
    skill: Skill,
    userId: number,
    levelId: number
  ): Observable<Response> {
    const body = {
      skill: skill.skill,
      levelId: levelId,
    }

    return this.http.post<Response>(`${environment.apiUrl}/users/${userId}/skills`, body);
  }

  /**
   * deleteUserSkill method
   * make a request (delete) to the backetnd to delete a user's skill
   * @param {number | string} userId user id
   * @param {number | string} skillId skill id
   * @returns {Observable<Response>} backend response or error
   */
  public deleteUserSkill(
    userId: number | string,
    skillId: number | string,
  ): Observable<Response>{
    return this.http.delete<Response>(
      `${environment.apiUrl}/users/${userId}/skills/${skillId}`
    ).pipe(
      catchError((error) => {
        return of(error);
      }),
    );
  }

  /**
   * getSkillLevels method
   * @description Get all the skill levels registered in the database
   * @returns {Observable<SkillLevelsResponse>}
   */
  public getSkillLevels(): Observable<SkillLevelsResponse> {
    return this.http.get<SkillLevelsResponse>(
      `${environment.apiUrl}/skills/levels`
    ).pipe(
      catchError((error) => {
        return of(error);
      }),
    )
  }
}
