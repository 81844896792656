import { Component, SimpleChanges, OnChanges, ViewChildren, QueryList, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { Project } from 'src/app/models/projects/project.model';
import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { ContentModal } from 'src/app/models/content-modal.model';
import { ProjectsService } from 'src/app/services/projects.service';
import { TeamMemberService } from 'src/app/services/team-member.service';
import { ClientMembersService } from 'src/app/services/client-members.service';
import {
  archiveTeamMemberModal,
  restoreTeamMemberModal,
  archiveClientMemberModal,
  restoreClientMemberModal,
  archiveRestoreButtons,
  archiveProjectModal,
  restoreProjectModal,
} from 'src/app/config/modal-contents';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
  providers: [TitleCasePipe]
})
export class EditProjectComponent implements OnChanges, OnInit {
  // View children to active members and inactive members toggle switch
  @ViewChildren('childActive') actives: QueryList<CheckboxComponent>;
  @ViewChildren('clientChildActive') clientActives:
    QueryList<CheckboxComponent>;
  @ViewChildren('clientChildInactive') clientInactives:
    QueryList<CheckboxComponent>;
  @ViewChildren('childInactive') inactives: QueryList<CheckboxComponent>;

  private idProject: number = 0;
  public projectToEdit: Project;

  // Flag to show/hide the modal
  public showModal = false;

  // Variables to set the member that will be change his/her status
  private idChangedMember: number;
  private statusChangedMember: string;

  public activeMembersArray: FormArray;
  public inactiveMembersArray: FormArray;
  public activeClientMembersArray: FormArray;
  public inactiveClientMembersArray: FormArray;

  // Content Modal data to customize
  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: archiveRestoreButtons.confirmButton,
    cancelButton: archiveRestoreButtons.cancelButton,
  };

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private projectServices: ProjectsService,
    private teamMemberService: TeamMemberService,
    private clientMembersService: ClientMembersService,
    private projectsService: ProjectsService,
    private router: Router,
    private titleCasePipe: TitleCasePipe
  ) { 
    this.activatedRoute.parent.params.subscribe((params) => {
      this.idProject = params.id;
      this.getProject(this.idProject);
    });
  }

  ngOnInit() {
    this.setUpFormArrays();
    this.getProject(this.idProject);
    this.showModal = false;
  }

  /**
   * ngOnChanges hook
   * sets the arrays that will contain the data use to paint the members card
   * once the data is available
   * @returns {void}
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectToEdit.currentValue) {
      this.setUpFormArrays();
      this.showModal = false;
    }
  }

  /**
   * getProject Method
   * The method subscribes to the getProject Method in the Projects Services
   * and retrieves the data.
   * @param {number} id - Project ID to get the data
   * @returns {void}
   */
  private getProject(id: number): void {
    this.projectServices.getProject(id)
      .subscribe((project) => {
        this.projectToEdit = project.data;
        this.createForms();
      });
  }

  /**
   * createForm Method
   * Creates a new form group and pushes the form controls in any of the FormArrays
   * @returns {FormGroup} FormGroup
   */
  private createForm(): FormGroup {
    const activeControl: FormControl = new FormControl(true, []);
    return this.formBuilder.group({
      activate: activeControl,
    });
  }

  /**
   * addForm Method
   * Push the FormGroup created inside the formArray
   * @param {FormArray} formArray - The FormArray to push the form control
   * @returns {void} void
   */
  private addForm(formArray: FormArray): void {
    formArray.push(this.createForm());
  }

  /**
   * createForms Method
   * Create all the required FormGroups (for Active and inactive members)
   * @returns {void} void
   */
  private createForms(): void {
    this.projectToEdit.activeUsers.forEach(() => {
      this.addForm(this.activeMembersArray);
    });

    this.projectToEdit.inactiveUsers.forEach(() => {
      this.addForm(this.inactiveMembersArray);
    });

    this.projectToEdit.activeClientMembers.forEach(() => {
      this.addForm(this.activeClientMembersArray);
    });

    this.projectToEdit.inactiveClientMembers.forEach(() => {
      this.addForm(this.inactiveClientMembersArray);
    });
  }

  /**
   * setUpFormArrays Method
   * Set the values in all the FormArrays
   * @returns {void} 
   */
  public setUpFormArrays(): void {
    this.activeMembersArray = this.formBuilder.array([]);
    this.activeClientMembersArray = this.formBuilder.array([]);
    this.inactiveMembersArray = this.formBuilder.array([]);
    this.inactiveClientMembersArray = this.formBuilder.array([]);
  }

  /**
   * onChangeStatus Method
   * Shows the modal and initialize the idChangedMember variable
   * @param {number} id - member id to change his/her status
   * @param {string} status - Actual member status
   * @return {void}
   */
  public onChangeStatus(id: number, status: string): void {
    let name = null;
    this.idChangedMember = id;
    this.showModal = true;
    this.statusChangedMember = status;
    switch (status) {
      case 'actives': {
        name = `${this.projectToEdit.activeUsers[id].firstName} ${this.projectToEdit.activeUsers[id].lastName}`;
        name = this.titleCasePipe.transform(name);
        this.contentModal.title = archiveTeamMemberModal.title;
        this.contentModal.content = archiveTeamMemberModal.content(name);
        break;
      }

      case 'inactives': {
        name = `${this.projectToEdit.inactiveUsers[id].firstName} ${this.projectToEdit.inactiveUsers[id].lastName}`;
        name = this.titleCasePipe.transform(name);
        this.contentModal.title = restoreTeamMemberModal.title;
        this.contentModal.content = restoreTeamMemberModal.content(name);
        break;
      }

      case 'clientActives': {
        name = this.projectToEdit.activeClientMembers[id].name;
        name = this.titleCasePipe.transform(name);
        this.contentModal.title = archiveClientMemberModal.title;
        this.contentModal.content = archiveClientMemberModal.content(name);
        break;
      }

      case 'clientInactives': {
        name = this.projectToEdit.inactiveClientMembers[id].name;
        name = this.titleCasePipe.transform(name);
        this.contentModal.title = restoreClientMemberModal.title;
        this.contentModal.content = restoreClientMemberModal.content(name);
        break;
      }

      case 'archiveProject': {
        name = this.projectToEdit.name;
        name = this.titleCasePipe.transform(name);
        this.contentModal.title = archiveProjectModal.title;
        this.contentModal.content = archiveProjectModal.content(name);
        break;
      }
      case 'restoreProject': {
        name = this.projectToEdit.name;
        name = this.titleCasePipe.transform(name);
        this.contentModal.title = restoreProjectModal.title;
        this.contentModal.content = restoreProjectModal.content(name);
        break;
      }
    }
  }

  /**
   * onControl Method
   * Listen the PopUpMessage EventEmitter of control
   * If confirmButton was pushed the backend call occurs
   * If cancelButton was pushed the toggle switch status is restored
   * @param {string} message - Can be confirm or cancel, depending of the pushed button
   * @return {void}
   */
  public onControl(message: string): void {
    if (message === 'cancel') {
      if(this.statusChangedMember !== 'archiveProject' && this.statusChangedMember !== 'restoreProject'){
        const arrayMembers = this[this.statusChangedMember].toArray();
        arrayMembers[this.idChangedMember].toggle();       
      }
    } else {
      switch (this.statusChangedMember) {
        case 'actives': {
          this.teamMemberService.archiveTeamMember(this.projectToEdit.id,
            this.projectToEdit.activeUsers[this.idChangedMember].id)
            .subscribe(() => {
              this.onStatusChanged();
            });
          break;
        }

        case 'inactives': {
          this.teamMemberService.archiveTeamMember(this.projectToEdit.id,
            this.projectToEdit.inactiveUsers[this.idChangedMember].id)
            .subscribe(() => {
              this.onStatusChanged();
            });
          break;
        }

        case 'clientActives': {
          this.clientMembersService.toggleClientMember(this.projectToEdit.id,
            this.projectToEdit.activeClientMembers[this.idChangedMember].id)
            .subscribe(() => {
              this.onStatusChanged();
            });
          break;
        }

        case 'clientInactives': {
          this.clientMembersService.toggleClientMember(this.projectToEdit.id,
            this.projectToEdit.inactiveClientMembers[this.idChangedMember].id)
            .subscribe(() => {
              this.onStatusChanged();
            });
          break;
        }

        case 'archiveProject': {
          this.projectsService.toggleProject(this.projectToEdit.id)
           .subscribe(() => this.toggleProject() );
          break;
        }
        case 'restoreProject': {
          this.projectsService.toggleProject(this.projectToEdit.id)
            .subscribe(() => this.toggleProject() );
          break;
        }
      }
    }
  }

  /**
   * onStatusChanged Method
   * Updates the data when a member was change of status
   * @returns {void}
   */
  private onStatusChanged(): void {
    this.getProject(this.idProject);
    this.createForms();
    this.showModal = false;
    this.setUpFormArrays();
  }
  /**
   * toggleProject Method
   * Updates the data when a project have change of status
   * @returns {void}
   */
  private toggleProject(): void {
    this.getProject(this.idProject);
    this.showModal = false;
  }

  /**
   * goToEditClientMemberView Method
   * Redirects you to the Edit Client Member View
   * @param {number} memberId - Id of the member to edit
   * @returns {void} 
   */
  public goToEditClientMemberView(memberId: number): void {
    this.router.navigateByUrl(
      `manage/projects/${this.idProject}/client-member/${memberId}/edit`
    );
  }

   /**
   * goToEditTeamMemberView Method
   * Redirects you to the Edit Team Member View
   * @param {number} memberId - Id of the member to edit
   * @returns {void} 
   */
  public goToEditTeamMemberView(memberId: number): void {
    this.router.navigateByUrl(
      `manage/projects/${this.idProject}/team-member/${memberId}/edit`
    );
  }

  /**
   * onCloseModal Method
   * Listen the PopUpMessage EventEmitter of close
   * When it is received the showModal variable turns to false and the modal is closed
   * @return {void}
   */
  public onCloseModal(): void {
    this.showModal = false;
  }

    /**
   * archiveProject Method
   * Listen the archiveProject EventEmitter in the edit-project-header
   * When it is received it calls the onChangeStatus method that change
   * the status of the project 
   * @return {void}
   */
  public archiveProject(status: string): void {
    this.onChangeStatus(this.projectToEdit.id, status);
  }
}
