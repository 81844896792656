/**
 * file to contain the permissions relevant to the application
 * to handle the hide/show of parts of it
 */
const permissions = {
  timesheet: 'edit-timesheet',
  self: 'self-all-access',
  editResume: 'edit-their-resume',
  userStatus: 'edit-their-status',
  assignRoles: 'assign-roles',
  companyMembers: 'company-members-all-access',
  createRoles: 'create/edit/delete-roles',
  projects: 'create/edit-projects',
  generateReports: 'management-generate-reports',
  databaseItems: 'add/edit-items-in-databases', 
  management: 'management-all-access',
  editCheckins: 'create/edit-check-in',
  checkIns: 'check-in-all-access',
};

export { permissions };
