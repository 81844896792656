import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { merge, Subscription } from 'rxjs';

import { ProfileService } from 'src/app/services/profile.service';
import { UserExperience } from 'src/app/models/profile/user-experience.model';

import moment from 'moment';
import { ContentModal } from 'src/app/models/content-modal.model';
import { deleteButtons, deleteExperience } from 'src/app/config/modal-contents';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-experiences-display',
  templateUrl: './experiences-display.component.html',
  styleUrls: ['./experiences-display.component.scss']
})
export class ExperiencesDisplayComponent implements OnInit, OnChanges, OnDestroy {

  @Input() experiences: UserExperience[];
  @Input() addExperienceHidden: boolean = false;
  @Input() userId: number;
  @Input() personalInfoInEdit: boolean;

  public hideEventsSubs: Subscription;
  public showEventsSubs: Subscription;
  public show: boolean = true;
  public showAlert: boolean = false;
  public showModal: boolean = false;
  public errorMessage: string;

  private currentExperience: UserExperience;

  // Content Modal data to customize
  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: deleteButtons.confirmButton,
    cancelButton: deleteButtons.cancelButton,
  };

  constructor(
    private profileService: ProfileService,
    private titlecasePipe:TitleCasePipe,
  ) {}

  /**
   * ngOnInit hook
   * sets a merge between subjects to hide or show
   * the buttons in the markup
   * @return {void}
   */
  ngOnInit() {
    // merge the subjects and hide the buttons
    this.hideEventsSubs = merge(
      this.profileService.editPersonalView,
      this.profileService.editSummaryView,
      this.profileService.addSkillView,
    ).subscribe(() => this.show = false);

    // show the buttons
    this.showEventsSubs = this.profileService.showButtons
      .subscribe(() => this.show = true);
  }

  ngOnChanges() {
    if (this.experiences) {
      // sort the experiences
      this.experiences.sort((a: UserExperience, b: UserExperience): number => {
        if (moment(a.fromDate).isBefore(moment(b.fromDate))) {
          return 1;
        }
  
        return -1;
      });
    }
  }

  /**
   * goToAddExperienceView method
   * triggers an event to show the
   * form to add a new experience
   * @return {void}
   */
  public goToAddExperienceView(): void {
    this.profileService.addExperienceView.next();
  }

  /**
   * goToEditExperienceView method
   * triggers an event to show the
   * form to edit an experience
   * @param {UserExperience} experience experience to edit
   * @return {void}
   */
  public goToEditExperienceView(experience: UserExperience): void {
    this.profileService.editExperienceView.next(experience);
  }

  /**
   * ngOnDestroy hook
   * unsubscribe from all Subjects emitted
   * @return {void}
   */
  ngOnDestroy() {
    this.hideEventsSubs.unsubscribe();
    this.showEventsSubs.unsubscribe();
  }

  /**
   * onControl Method
   * Listen the PopUpMessage EventEmitter of control
   * if message are confirm call method deleteUserExperience
   * 
   * @param {string} message - Can be confirm or cancel, depending of the pushed button
   * @return {void}
   */
  public onControl(message: string): void {
    if (message === 'confirm') {
      this.deleteUserExperiences();
    }
  }

  /**
   * openModal method
   * Show the modal
   * @param {UserExperience} experience experience to delete
   * @returns {void}
   */
  public openModal(experience: UserExperience): void {
    const companyName:string = this.titlecasePipe.transform(experience.company);
    this.contentModal.title = deleteExperience.title;
    this.contentModal.content = deleteExperience.content(companyName);
    this.showModal = true;
    this.currentExperience = experience;
  }

  /**
   * deleteUserExperiences method
   * calls the deleteUserExperience function from  the service to remove a
   * user experience
   * @returns {void} void
   */
  public deleteUserExperiences(): void {
    this.userId = isNaN(this.userId) ? 0 : this.userId;
    this.profileService
      .deleteUserExperience(this.userId, this.currentExperience.id)
      .subscribe((res) => {
        if (res.status === 'success') {
          this.profileService.experienceUpdated.next();
        } else {
          this.showAlert = true;
          this.errorMessage = 'Something went wrong. Please try again later';
        }
      });
  }

  /**
   * onCloseModal Method
   * Listen the PopUpMessage EventEmitter of close modal
   * @return {void}
   */
  public onCloseModal(): void {
    this.showModal = false;
  }
}
