import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Template } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-welcome-modal-template',
  templateUrl: './welcome-modal-template.component.html',
  styleUrls: ['./welcome-modal-template.component.scss']
})
export class WelcomeModalTemplate {

  constructor() { }

}
