import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
  QueryList,
  ViewChildren,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { UserService } from 'src/app/services/user.service';
import { Employee } from 'src/app/models/employees/employee.model';
import { ContentModal } from 'src/app/models/content-modal.model';
import { archiveRestoreButtons, archiveUserModal, restoreUserModal } from 'src/app/config/modal-contents';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { Response } from 'src/app/models/response.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { permissions } from 'src/app/config/permissions';
import { TeamListResponse } from 'src/app/models/team-list-response.model';
import { Permissions } from 'src/app/models/permissions.model';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';
import { RelatedUsersFilters } from 'src/app/models/related-users-filters.model';

@Component({
  selector: 'app-employees-shared-list',
  templateUrl: './employees-shared-list.component.html',
  styleUrls: ['./employees-shared-list.component.scss']
})
export class EmployeesSharedListComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChildren('users') usersHtml: QueryList<CheckboxComponent>;

  @Input() allUsers: boolean;
  @Input() textButton: string;
  @Input() canSearch: boolean;
  @Output() goToEmployee: EventEmitter<number> = new EventEmitter<number>();

  public employeesLoaded: boolean = false;
  public employees: Employee[] = [];
  public employeePhotos: string[] = [];
  public employeesFormGroups: FormGroup[] = [];
  public showModal: boolean = false;
  public idUser: number = null;
  public statusUser: boolean;
  public indexUser: number;
  public permissions: Permissions = permissions;
  public actualFilter: RelatedUsersFilters = {};
  public userStatusFilter = 'all';

  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: archiveRestoreButtons.confirmButton,
    cancelButton: archiveRestoreButtons.cancelButton,
  };

  private totalPages: number = 1;
  private page: number = 1;
  private versionPhoto: number = Date.now();
  private userId: number;
  private newEmployees: Employee[] = [];
  private employeesSubs: Subscription;
  private employeeFormSubs: Subscription[] = [];
  private search: string = null;
  private canRequest: boolean = true;

  constructor(
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    public permissionsService: PermissionsService,
  ) { }

  ngOnInit() {
    const userId = +localStorage.getItem('id');
    this.userId = !isNaN(userId) ? userId : 0;
    this.getEmployees();
  }

  ngAfterViewChecked() {
    if (this.employees.length) {
      this.changeDetectorRef.detectChanges();
    }
  }

  public setUserStatusFilter(filter: 'all' | 'active' | 'inactive'): void {
    this.userStatusFilter = filter;
    this.resetEmployeesList();
    this.getEmployees();
  }

  public getEmployees(): void {
    if (this.search && this.search.length > 0) {
      this.getAllEmployees();
    } else {
      switch (this.userStatusFilter) {
        case 'all':
          this.getAllEmployees();
          break;
        case 'active':
          this.getActiveEmployees();
          break;
        case 'inactive':
          this.getInactiveEmployees();
          break;
      }
    }
  }

  private getAllEmployees(): void {
    if (this.page <= this.totalPages && this.canRequest) {
      this.employeesSubs = this.userService.getFilteredUsers(
        this.page,
        this.actualFilter,
        this.userId,
        this.userStatusFilter === 'all' ? null : this.userStatusFilter === 'active',
        this.search,
      ).subscribe(result => {
        this.setEmployeesInfo(result);
      });
    }
  }

  private getActiveEmployees(): void {
    if (this.page <= this.totalPages) {
      this.employeesSubs = this.userService.getActiveUsersLoggedIncluded(this.page, this.actualFilter)
        .subscribe(result => {
          this.setEmployeesInfo(result);
        });
    }
  }

  private getInactiveEmployees(): void {
    if (this.page <= this.totalPages) {
      this.employeesSubs = this.userService.getInactiveUsersLoggedIncluded(this.page, this.actualFilter)
        .subscribe(result => {
          this.setEmployeesInfo(result);
        });
    }
  }

  private setEmployeesInfo(result: TeamListResponse): void {
    this.employeesLoaded = true;
    this.totalPages = result.data.pages ? result.data.pages : 1;
    this.newEmployees = result.data.items;

    if (this.newEmployees.length) {
      this.newEmployees.forEach(employee => {
        const employeeNameArray: string[] = employee.fullName.trim().split(' ').filter(name => name);
        const employeeName: string = employeeNameArray.map(name => {
          return `${name[0].toUpperCase()}${name.slice(1)}`;
        }).join(' ');

        this.employeePhotos.push(`${employeeName}'s photo`);
        this.versionPhoto = Date.now();
        employee.photo += `?=${this.versionPhoto}`;
      });

      this.employees.push(...this.newEmployees);
      this.createActiveForms();
      this.page += 1;
    }

    this.canRequest = true;
  }

  public createActiveForms(): void {
    this.newEmployees.forEach((newEmployee, index) => {
      this.employeesFormGroups.push(
        new FormGroup({
          'active': new FormControl(null, []),
        })
      );
    });
  }

  public goToEmployeeView(employeeId: number): void {
    this.goToEmployee.emit(employeeId);
  }

  public enableDisableUser(index: number): void {
    this.statusUser = this.employeesFormGroups[index].value.active;
    const fullName = this.employees[index].fullName;
    this.idUser = this.employees[index].id;
    this.indexUser = index;

    if (!this.statusUser) {
      this.contentModal.title = restoreUserModal.title;
      this.contentModal.content = restoreUserModal.content(fullName);
    } else {
      this.contentModal.title = archiveUserModal.title;
      this.contentModal.content = archiveUserModal.content(fullName);
    }
    this.showModal = true;
  }

  private resetEmployeesList(): void {
    this.employeesLoaded = false;
    this.employees = [];
    this.employeePhotos = [];
    this.employeesFormGroups = [];
    this.page = 1;
  }

  public onControl(message: string): void {
    if (message === 'confirm') {
      this.toogleUserStatus();
    } else {
      const usersArray = this.usersHtml.toArray();
      usersArray[this.indexUser].toggle();
    }
  }

  public onCloseModal(): void {
    this.showModal = false;
  }

  private toogleUserStatus(): void {
    this.userService.enableDisableUser(this.idUser).subscribe((response: Response) => {
      if (response.status !== 'success') {
        this.onControl('cancel');
      } else {
        this.employees[this.indexUser].endDate = this.employees[this.indexUser].endDate
          ? null
          : new Date().toISOString();

        const currentFilter = this.userStatusFilter;
        this.resetEmployeesList();
        this.userStatusFilter = currentFilter;
        this.getEmployees();
      }
    });
  }

  public setLocationFilter(filter: UserLocationFilter) {
    this.employeesLoaded = false;
    this.employees = [];
    this.employeePhotos = [];
    this.page = 1;
    this.actualFilter = { [filter.type]: filter.id };
    this.getEmployees();
  }

  ngOnDestroy() {
    if (this.employeesSubs) {
      this.employeesSubs.unsubscribe();
    }
    this.employeeFormSubs.forEach(formSub => formSub.unsubscribe());
  }

  public onSearch(search: string): void {
    this.search = search;
    this.page = 1;
    this.employees = [];
    this.employeesLoaded = false;
    this.getEmployees();
  }
}
