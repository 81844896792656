import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { ProfileResponse } from "src/app/models/profile-response.model";
import { TeamListResponse } from "src/app/models/team-list-response.model";
import { catchError, map, tap } from "rxjs/operators";
import { CitiesResponse } from "src/app/models/responses/cities-response.model";
import { EmptyResponse } from "src/app/models/projects/empty-response.model";
import { UserDescription } from "src/app/models/profile/user-description.model";
import { UserEditPersonalInfo } from "src/app/models/profile/user-edit-personal.info.model";
import { RolesResponse } from "src/app/models/responses/roles-response.model";
import { RoleAssignation } from "src/app/models/employees/role-assignation.model";
import { UserProjectsResponse } from "src/app/models/user-projects-response.model";
import { CheckInResponse } from "src/app/models/responses/check-in-response.model";
import { UpdateCheckIn } from "src/app/models/update-check-in.model";
import { RelatedUsersFilters } from "src/app/models/related-users-filters.model";
import { HealthCheckResponse } from "../models/responses/health-check-response.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  // refresh
  private _refreshNedded$ = new Subject<void>();

  get refreshNedded$() {
    return this._refreshNedded$;
  }

  /**
   * getGitHashFE method
   * read file from assets folder to get the current git hash
   * @return {Observable<{ hash: string }>} observable from response
   */
  getGitHashFE(): Observable<{ hash: string }> {
    return this.http.get<{ hash: string }>("../assets/git-hash.json");
  }

  /**
   * getGitHashBE method
   * it gets the current git hash from the backend
   * @return {Observable<HealthCheckResponse>} observable from response
   */
  getGitHashBE(): Observable<HealthCheckResponse> {
    return this.http
      .get<HealthCheckResponse>(`${environment.apiUrl}/health-check`)
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getUser method
   * it gets the profile´s user information
   * @param {number} id id from user
   * @return {Observable<ProfileResponse>} observable from response
   */
  public getUser(id: number): Observable<ProfileResponse> {
    return this.http
      .get<ProfileResponse>(`${environment.apiUrl}/users/${id}/profile`)
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getCities method
   * retrieves cities data when the user
   * inputs the location of his/her
   * previous experience from a third-party API
   * @param {string} city name of the city to query
   * @return {Observable<CitiesResponse[]>} response from the API
   */
  public getCities(city: string): Observable<CitiesResponse[]> {
    return this.http
      .get<CitiesResponse[]>(
        `${environment.citiesApiUrl}/places2?term=${city}&locale=en&types[]=city`
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /** updateDescription method
   * update description data of the user
   * @param {number} id user id
   * @return {Observable<EmptyResponse>} response from the API
   */
  public updateDescription(
    id: number,
    userDescription: UserDescription
  ): Observable<EmptyResponse> {
    return this.http
      .put<EmptyResponse>(
        `${environment.apiUrl}/users/${id}/description`,
        userDescription
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getActiveUsers method
   * get all the information of the registered users
   * @param {number} page indicates in how many pages split the query
   * @param {number} id contains the id assigned to the team member
   * @returns {Observable} contains the data response from the database
   */
  public getActiveUsers(
    page: number,
    id: number
  ): Observable<TeamListResponse> {
    return this.http
      .get<TeamListResponse>(
        `${environment.apiUrl}/users?page=${page}&skipId=${id}&status=active`
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getActiveUsersLoggedIncluded method
   * get all the information of the registered users included the logged user
   * @param {number} page indicates in how many pages split the query
   * @param {RelatedUsersFilters} filters
   * Filters and values to get the users
   * (may be by location, field and seniority)
   * as default is null 
   * @returns {Observable} contains the data response from the database
   */
  public getActiveUsersLoggedIncluded(
    page: number,
    filters: RelatedUsersFilters | null = null
  ): Observable<TeamListResponse> {
    let url = `${environment.apiUrl}/users?page=${page}&status=active`;
    if(filters){
      const keys = Object.keys(filters);
      const values = Object.values(filters);
      const queryParams = keys
        .map((key, index) => {
          return values[index] ? `${key}=${values[index]}` : null;
        })
        .filter((value) => value);

      if (queryParams.length) {
        url += `&${queryParams.join("&")}`;
      }
    }

    return this.http
      .get<TeamListResponse>(url)
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

    /**
   * getActiveUsersLoggedIncluded method
   * get all the information of the registered users included the logged user
   * @param {number} page indicates in how many pages split the query
   * @param {RelatedUsersFilters} filters
   * Filters and values to get the users
   * (may be by location, field and seniority)
   * as default is null 
   * @returns {Observable} contains the data response from the database
   */
    public getInactiveUsersLoggedIncluded(
      page: number,
      filters: RelatedUsersFilters | null = null,
    ): Observable<TeamListResponse> {
    let url = `${environment.apiUrl}/users?page=${page}&status=inactive`;
    if(filters){
      const keys = Object.keys(filters);
      const values = Object.values(filters);
      const queryParams = keys
        .map((key, index) => {
          return values[index] ? `${key}=${values[index]}` : null;
        })
        .filter((value) => value);
  
      if (queryParams.length) {
        url += `&${queryParams.join("&")}`;
      }
     }
      return this.http
        .get<TeamListResponse>(url)
        .pipe(
          catchError((error) => {
            return of(error);
          })
        );
    }

  /**
   * getAllUsers method
   * get all the information of the registered users
   * @param {number} page indicates in how many pages split the query
   * @param {number} id contains the id assigned to the team member
   * @returns {Observable} contains the data response from the database
   */
  public getAllUsers(
    page: number,
    id: number,
    search?: string
  ): Observable<TeamListResponse> {
    let url: string = `${environment.apiUrl}/users?page=${page}&skipId=${id}`;
    if (search) {
      url += `&search=${search}`;
    }

    return this.http.get<TeamListResponse>(url).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
   * getAllUsersNoSkip method
   * get all the information of the registered users without the exception of the skip
   * @param {number} page indicates in how many pages split the query
   * @returns {Observable} contains the data response from the database
   */
  public getAllUsersNoSkip(page: number): Observable<TeamListResponse> {
    return this.http
      .get<TeamListResponse>(`${environment.apiUrl}/users?page=${page}`)
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * putEditPersonalInfo method
   * send backend the new data to edit user information
   * @param {number | string} userId - if not change the image send string,
   * if the image is changed, an array buffer is sent.
   * @param {UserEditPersonalInfo} newData - object with the new data
   * @returns {Observable<EmptyResponse>}
   */
  public putEditPersonalInfo(
    userId: number | string,
    newData: UserEditPersonalInfo
  ): Observable<EmptyResponse> {
    return this.http
      .put<UserEditPersonalInfo>(
        `${environment.apiUrl}/users/${userId}/personal-info`,
        newData
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      )
      .pipe(
        tap(() => {
          this._refreshNedded$.next();
        })
      );
  }

  /**
   * getUserProjects method
   * gets id and name from an user projects
   * @param {number} userId id of the user
   * @return {Observable<UserProjectsResponse>}
   */
  public getUserProjects(userId: number): Observable<UserProjectsResponse> {
    return this.http
      .get<UserProjectsResponse>(
        `${environment.apiUrl}/users/${userId}/projects`
      )
      .pipe(
        map((response: UserProjectsResponse) => {
          const sortProjects  = response.data.sort((a, b) => a.name.localeCompare(b.name));
          return {
            ...response,
            data: sortProjects,
          };
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getUserCheckIns method
   * retrieve the check ins for the user in a certain time period
   * @param {number} userId id of the user owner of the check ins
   * @param {string[]} period array with the time range
   * @returns {Observable<CheckInResponse>}
   */
  public getUserCheckIns(
    userId: number,
    period: string[]
  ): Observable<CheckInResponse> {
    const queryParams = {
      params: new HttpParams()
        .set("fromDate", period[0])
        .set("toDate", period[1]),
    };

    return this.http
      .get<CheckInResponse>(
        `${environment.apiUrl}/users/${userId}/checkIn`,
        queryParams
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * updateUserCheckIn method
   * updates the user check in with new data
   * @param {number} userId id of the user
   * @param {number} checkInId id of the check in to update
   * @param {CheckIn} newData new data for the check in
   * @return {Observable<EmptyResponse>}
   */
  public updateUserCheckIn(
    userId: number,
    checkInId: number,
    newData: UpdateCheckIn
  ): Observable<EmptyResponse> {
    return this.http
      .put<EmptyResponse>(
        `${environment.apiUrl}/users/${userId}/checkIn/${checkInId}`,
        newData
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getUserRoles method
   * request the roles assigned to the user
   * @param {number} userId id of the user from whom retrieve the roles
   * @param {number} page page to get from the database
   * @returns {Observable<RolesResponse>}
   */
  public getUserRoles(userId: number, page: number): Observable<RolesResponse> {
    return this.http
      .get<RolesResponse>(
        `${environment.apiUrl}/users/${userId}/roles?page=${page}`
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * assignUserRole method
   * assign a new role to the user or update his current one
   * @param {number} userId id of the user to assign the role
   * @param {RoleAssignation} roleData object containing the roles to
   * assign/update
   * @returns {Observable<EmptyResponse>}
   */
  public assignUserRole(
    userId: number,
    roleData: RoleAssignation
  ): Observable<EmptyResponse> {
    return this.http
      .put<EmptyResponse>(
        `${environment.apiUrl}/users/${userId}/roles`,
        roleData
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * enableDisableUser method
   * Change the user status to enable or disable
   * @param {number} userId user id
   * @returns {Observable<EmptyResponse>}
   */
  public enableDisableUser(userId): Observable<EmptyResponse> {
    return this.http
      .put<EmptyResponse>(`${environment.apiUrl}/users/${userId}/toggle`, "")
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * logOutUser method
   * call the enpoint logout by delete token and
   * log out
   * @returns {Observable<EmptyResponse>}
   */
  public logOutUser(): Observable<EmptyResponse> {
    return this.http
      .delete<EmptyResponse>(`${environment.apiUrl}/users/logout`)
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * tokenValidator method
   * call endpoint token-validator to verify the token before loading
   * views
   * @returns {Observable<EmptyResponse>}
   */
  public tokenValidator(): Observable<EmptyResponse> {
    return this.http
      .get<EmptyResponse>(`${environment.apiUrl}/token-validator`)
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getFilteredUsers method
   * @description Get the users filtered by
   * the given filters
   * @param {number} page - Indicates in which page split the query
   * @param {RelatedUsersFilters} filters
   * Filters and values to get the users
   * (may be by location, field and seniority)
   * @param {number} skipId (optional) - User Id to ignore
   * @param {boolean} activesOnly (optional) - Flag to request
   * all the users (false) or just the actives (true)
   * @param {string} search (optional) - value to find in the user's
   * first name and last name that have coincidences
   * @returns {Observable<TeamListResponse>} Observable<TeamListResponse>
   */
  public getFilteredUsers(
    page: number,
    filters: RelatedUsersFilters,
    skipId?: number,
    activesOnly?: boolean,
    search?: string
  ): Observable<TeamListResponse> {
    let url = `${environment.apiUrl}/users?page=${page}`;
    const keys = Object.keys(filters);
    const values = Object.values(filters);
    const queryParams = keys
      .map((key, index) => {
        return values[index] ? `${key}=${values[index]}` : null;
      })
      .filter((value) => value);

    if (queryParams.length) {
      url += `&${queryParams.join("&")}`;
    }

    if (skipId) {
      url += `&skipId=${skipId}`;
    }
    if(activesOnly !== null){
      url += `&status=${activesOnly ? 'active' : 'inactive'}`;
    }
    if (search) {
      url += `&search=${search}`;
    }

    return this.http.get<TeamListResponse>(url).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }
}
