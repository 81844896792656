import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { UserDescription } from 'src/app/models/profile/user-description.model';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { alphaNumberNoEmojisRegex } from 'src/app/config/regex';
import { UserProfile } from 'src/app/models/user-profile.model';
import { userdescriptionErrors } from 'src/app/config/error-messages';
import { EmptyResponse } from 'src/app/models/responses/empty-response.model';
import { ProfileService} from 'src/app/services/profile.service';

@Component({
  selector: 'app-description-edit',
  templateUrl: './description-edit.component.html',
  styleUrls: ['./description-edit.component.scss'],
})
export class DescriptionEditComponent implements OnInit {
  
  @Input() userId: number;
  @Input() description: UserProfile['description'];
  @Output() displayDescriptionView: EventEmitter<string> = new EventEmitter<string>();
  
  public descriptionForm: FormGroup;
  public showAlert: boolean;
  public hasChanged: boolean;
  public descriptionData: UserDescription;
  public message: string;

  // form controls
  public descriptionControl: FormControl;

  // form group
  public formDescription: FormGroup;
  
  // form validators
  public descriptionValidator: Validators[] = [
    Validators.required,
    patternValidator(alphaNumberNoEmojisRegex),
    Validators.minLength(10)
  ];

  // Errors
  public descriptionError = userdescriptionErrors.descriptionError;
  
  constructor(private userService: UserService, private profileService: ProfileService) {}

  /**
   * ngOnInit hook
   * set up the component's data before
   * any rendering
  */
  ngOnInit() {

    if (this.description) {
    this.descriptionControl = new FormControl(this.description, [])
    this.formDescription = new FormGroup({
      description: this.descriptionControl
    });

    this.descriptionData = {
      description: this.description
    }
    this.getDescription();
    this.checkChangesInForm();
  } else {
    this.hasChanged = true;
  }
}

  /** onSubmit method
   * Sends the data to the edit description service
   * @param form the data from the form
   * @returns {void} void
   */
  public onSubmit(form: UserDescription): void {
    let description = form;
    description.description = description.description.charAt(0).toUpperCase() + description.description.slice(1);
    description.description = description.description.replace(/\n+/g, "\n");
    description.description = description.description.replace(/[ \t]+/g, " ");
    this.showAlert = false;
    this.userService.updateDescription(this.userId, description)
      .subscribe((success) => {
        this.goToDescriptionView(success);
      }, error => {
        this.showAlert = true;
        this.message = 'Data not valid to update the project';
      });
  }

  /**
   * validateForm
   * Get all the form group data and checks if is valid data, to pass it to the
   * submitForm edit description if you are editing data
   * @returns {void} void
   */
  public validateForm(): void {
    const editDescription: UserDescription = this.formDescription.value;
    if (this.formDescription.valid) {
      this.message = '';
      if (this.description) {
        this.checkEmptyValues(editDescription);
        this.onSubmit(editDescription);
      }
    } else {
      this.message = 'Form not valid';
    }
  }

  /**
   * checkEmptyValues
   * Validates if there are empty fields in the received data, and deletes them
   * @param {NewProject} data receives data from description form
   * @returns {void}
   */
  public checkEmptyValues(data: UserDescription): void {
    for (const key in data) {
      if (!data[key]) {
        delete data[key];
      }
    }
  }
  
  /**
   * checkChangesInForm
   * go trough the values in the form and checks that they are different than the receives.
   * @returns {void} void
   */
  private checkChangesInForm(): void {
    this.formDescription.valueChanges.subscribe((data) => {
      for (const key in data) {
        if (data[key] === this.descriptionData[key]) {
          this.hasChanged = false;
        } else {
          this.hasChanged = true;
          return;
        }
      }
    })
  }

  /**
   * getDescription
   * Gets project data from parent and set it to the form
   * @returns {void} void
   */
  private getDescription(): void {
    if (this.description) {
      this.formDescription.setValue(this.descriptionData);
    }
  }

  /**
   * goToDescriptionView method
   * goes to the profile view
   * @param {EmptyResponse} descriptionUpdated - optional success object
   * @return {void}
   */
  public goToDescriptionView(descriptionUpdated?: EmptyResponse): void {
    if (descriptionUpdated) {
      this.profileService.descriptionUpdated.next()
      this.displayDescriptionView.emit('descriptionView');
    } else {
      this.displayDescriptionView.emit('descriptionView');
    }
  }

}
