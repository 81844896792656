import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ElementRef, HostListener, QueryList } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { Client } from 'src/app/models/databases/clients.model';
import { FilterClientsPipe } from 'src/app/pipes/filter-clients.pipe';
import { SuggestionsService } from 'src/app/services/suggestions.service';
import { SuggestionsKeyPressedResults } from 'src/app/models/select/select-key-pressed.model';

@Component({
  selector: 'app-clients-suggestions',
  templateUrl: './clients-suggestions.component.html',
  styleUrls: ['./clients-suggestions.component.scss']
})
export class ClientsSuggestionsComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() clientsControl: FormControl;
  @Input() clientsValidators: ValidatorFn[];
  @Input() clientsErrors;
  @Input() idControl: FormControl;
  @Input() idValidators: ValidatorFn[];
  @Input() idErrors;
  @Input() list: Client[] = [];

  @Output() error = new EventEmitter();

  @ViewChildren('clientsSuggestionsInput') clientsSuggestionsInput: QueryList<ElementRef>;
  @ViewChildren('options') options: QueryList<ElementRef>;

  public focusedOption = -1;
  public idHasError: boolean = false;
  public setOverflow: boolean = false;
  public selectedClient: Client;
  public filtered: Client[] = [];

  constructor(
    private filter: FilterClientsPipe,
    private eRef: ElementRef,
    private suggestionsService: SuggestionsService,
  ) { }

  ngOnInit() {
  }

  // listen to clicks outside the component
  @HostListener('document:click', ['$event'])
  onGlobalClick(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.checkForIdErrors();
      this.filtered = [];
    }
  }

  /**
   * filterList method
   * filter the provided list of clients with the value
   * @param {object} event event object containing the data concerning
   * the event, like the target
   * @return {void}
   */
  public filterList(event): void {
    // check if the client belongs to the list obtained
    const client = this.list.find(element => {
      const clientName = element.name;

      return clientName.toLowerCase() === event.target.value.trim().toLowerCase();
    });

    // automatically make the selection if a client with that name exists
    if (client) {
      this.makeSelection(client);
      return;
    } else {
      this.selectedClient = null;
      this.idControl.setValue(null);
    }

    // filter the list of clients
    this.filtered = this.filter.transform(this.list, event.target.value);

    // check if there are more than four suggestions so that it gets trimmed
    if (this.filtered.length > 4) {
      this.setOverflow = true;
    } else {
      this.setOverflow = false;
    }

    this.reviewClientsData();
  }

  /**
   * reviewClientsData method
   * removes data of the selected client
   * @return {void}
   */
  public reviewClientsData(): void {
    if (!this.clientsControl.value) {
      this.selectedClient = null;
    }
  }

  /**
   * makeSelection method
   * select the client to be added to the form
   * @param {Client} client data of the client selected
   * @return {void}
   */
  public makeSelection(client: Client): void {
    // get the selected client
    this.selectedClient = client;

    // set the input values
    this.idControl.setValue(this.selectedClient.id);
    this.clientsControl.setValue(this.selectedClient.name);

    this.idHasError = false;

    // empty the filtered list of clients
    this.filtered = [];
    this.focusedOption = -1;
  }

  /**
   * checkForIdErrors method
   * check if the id control has errors and set the flag
   * @return {void}
   */
  public checkForIdErrors(): void {
    if (this.idControl.errors) {
      this.idHasError = true;
    } else {
      this.idHasError = false;
    }
  }

  /**
   * onKeyDown method
   * Function to handle keyboard events and handle the component with keys
   * @param {KeyboardEvent} event Keyboard-Event to be handled
   * @returns {void} false to cancel the event
   */
  public onKeyPressed(event): boolean {
    const results: SuggestionsKeyPressedResults = this.suggestionsService.onKeyPressed(
      event.key,
      this.focusedOption,
      this.clientsSuggestionsInput.toArray()[0]['inputField'],
      this.options.toArray(),
      this.filtered,
    );
    
    this.focusedOption = results.focusedOption;
    if (results.emptyFiltered) {
      this.filtered = [];
    }
    return results.result;
  }

  /**
   * setFocus method
   * Set the focus in an specific element
   * @param {HTMLLIElement} element li element to set the focus
   * @param {number} i index from the element to set the focus
   * @returns {void} 
   */
  public setFocus(element: HTMLLIElement, i: number): void {
    element.focus();
    this.focusedOption = i;
  }

}
