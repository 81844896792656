import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Project } from 'src/app/models/projects/project.model';
import { TitleCasePipe } from '@angular/common';


@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnChanges, OnInit {
    
  @Input() project: Project;

  public projectName: string;
  public projectImageAlt: string;
  public client: string;
  public description: string;
  public image: string;
 
  constructor() {}

  ngOnInit() {
    this.projectName = this.project.name;
    this.client = this.project.client[0].name;
    this.description = this.project.description;
    this.image = this.project.image;

    const name = `${this.projectName[0].toUpperCase()}${this.projectName.slice(1)}`;
    this.projectImageAlt = `${name}'s logo`;
  }

  /**
   * ngOnChanges hook
   * sets the project details once they are available
   * @param {SimpleChanges} changes object containing the changes of the
   * input properties
   * @return {void} 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project.currentValue) {
      this.projectName = this.project.name;
      this.client = this.project.client[0].name;
      this.description = this.project.description;
      this.image = this.project.image;

      const name = `${this.projectName[0].toUpperCase()}${this.projectName.slice(1)}`;
      this.projectImageAlt = `${name}'s logo`;
    }
  }
}

