import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Input() placeholder: string = '';
  @Input() canSearch: boolean;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  private timeout: NodeJS.Timer = null;

  constructor() { }

  /**
   * onInput method
   * Hide label text when user is
   * introducing input and emit
   * the introduced text, the emitter is sent when the user
   * stop writing for 400ms
   * @param {HTMLInputElement} input input element
   * @return {void}
   */
  public onInput(input: HTMLInputElement): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout((a) => {
      this.search.emit(input.value);
    }, 400);
  }
}
