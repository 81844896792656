import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  AfterViewInit,
  HostListener,
  ElementRef
} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import moment from 'moment';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { SelectorComponent } from 'src/app/shared/components/selector/selector.component';
import { MonthComponent } from 'src/app/components/main/manage/calendar/month/month.component';
import { DateLabel } from 'src/app/models/dateLabel.model';
import { NestedSelectorOption } from 'src/app/models/select/nested-selector-option.model';
import { WorkingHoursReportService } from 'src/app/services/reports/working-hours-report.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { RegionLocationsResponse } from 'src/app/models/region.model';
import { PeriodToReport } from 'src/app/models/reports/period-to-report.model';
import { KEY_CODE } from 'src/app/config/key-codes';

@Component({
  selector: 'app-calendar-general',
  templateUrl: './calendar-general.component.html',
  styleUrls: ['./calendar-general.component.scss']
})
export class CalendarGeneralComponent implements OnInit, AfterViewInit {
  @ViewChild ('monthContainer', { static: true }) monthContainer: MonthComponent;
  @ViewChild ('monthSelector', { static: true }) monthSelector: SelectorComponent;
  @ViewChild ('container', { static: true }) container: ElementRef;

  public status:string;
  public dateLabel: DateLabel = {
    year: '',
    month: '',
    day: '',
  };

  public locationOptions: NestedSelectorOption[] = [
    { id: null, name: 'World Wide' },
  ];

  public monthOptions: SelectorOption[] = [];
  public statusOptions: SelectorOption[] = [
    { id: 0, name: 'Holiday' },
    { id: 1, name: 'Working Day' },
  ];
  public selectedStatus: SelectorOption = this.statusOptions[0];

  public locationControl = new FormControl('', []);
  public monthControl = new FormControl('', []);
  public statusControl = new FormControl('', []);
  public emptyErrors = {};
  public emptyValidators: Validators[] = [];
  public filtersForm = new FormGroup({
    location: this.locationControl,
    month: this.monthControl,
  });

  public statusForm = new FormGroup({
    status: this.statusControl,
  });

  public selectedMonth: SelectorOption = null;
  public actualPeriod: PeriodToReport = null;
  public actualLocation: NestedSelectorOption = this.locationOptions[0];
  public actualDate: string = null;
  public dataLoaded: boolean = false;

  private monthPeriods: PeriodToReport[] = [];

  constructor (
    private cd: ChangeDetectorRef,
    private workingHoursReportService: WorkingHoursReportService,
    private calendarService: CalendarService,
  ) { }

  /**
   * keyEvent HostListener
   * @description Listen the keyup event emmited
   * when you push a key and after release the key.
   * @param {string} eventName - Event Name to listen,
   * in this case the keyup event.
   * @param {string[]} args - extra args that Listener needs,
   * in this case the $event arg used to know which key was
   * pressed and released.
   * @returns {void} void
   */
  @HostListener('window: keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEY_CODE.LEFT_ARROW: {
        this.prevMonth();
        break;
      }

      case KEY_CODE.RIGHT_ARROW: {
        this.nextMonth();
        break;
      }
    }
  }

  ngOnInit() {
    this.dateLabel.day = moment.utc().format('MMMM');
    this.dateLabel.month = moment.utc().format('Do');
    this.dateLabel.year = moment.utc().format('YYYY');

    this.fillLocationFilter();
    this.fillMonthFilter();

    this.locationControl.valueChanges.subscribe((value) => {
      this.actualLocation = value;
    });

    this.monthControl.valueChanges.subscribe((value) => {
      this.actualPeriod = this.monthPeriods[value];
      
      this.setDateLabel();
    });
  }

  ngAfterViewInit() {
    this.statusControl.valueChanges.subscribe((value) => {
      if (this.statusOptions[value].name !== this.status) {
        this.updateStatus(this.statusOptions[value].name);
      }
    });
  }

    /**
   * getStatus method
   * gets an emitted string from a child to put it in the selector
   * with the respective status of the selected day
   * @param {string} event - String with the status of the day
   * @return {void}
   */
  public getStatus(event: string): void {
    this.status = event;

    if (this.status === 'Holiday') {
      this.selectedStatus = this.statusOptions[0];
    } else {
      this.selectedStatus = this.statusOptions[1];
    }

    this.cd.detectChanges();
  }

  /**
   * fillMonthFilter method
   * @description Set the options to be selected in month selector
   * from January 1990 to December 50 years in the future
   * @returns {void} void
   */
  private fillMonthFilter(): void {
    let startDate: moment.Moment;
    let endDate: moment.Moment;
    let monthIndex: number = 0;
    let actualMonth: number = 0;
    let period: PeriodToReport = null;
    const iteratorDate: moment.Moment = moment('1990-01-01');

    while (iteratorDate.year() <= moment.utc().add(50, 'years').year()) {
      startDate = iteratorDate.startOf('month').clone();
      endDate = iteratorDate.endOf('month').clone();

      period = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        selectorOption: {
          id: monthIndex,
          name: `${startDate.format('MMMM YYYY')}`,
        },
      };

      this.monthPeriods.push(period);
      this.monthOptions.push(period.selectorOption);

      if (moment.utc().isBetween(startDate, endDate)) {
        actualMonth = monthIndex;
      }

      monthIndex += 1;
      iteratorDate.add(1, 'month');
    }

    this.selectedMonth = this.monthOptions[actualMonth];
  }

  /**
   * fillLocationFilter method
   * @description Set the options to be selected
   * in location/region selector
   * @returns {void} void
   */
  private fillLocationFilter(): void {
    this.workingHoursReportService.getRegions().subscribe(
      (response: RegionLocationsResponse) => {
        response.data.forEach((region) => {
          this.locationOptions.push({
            id: region.id,
            name: region.name,
            children: region.locations,
          });
        });

        this.dataLoaded = true;
      }
    );
  }

  /**
   * setDateLabel method
   * @description Set the text to show in date label
   * @param staticDate (optional) - Date to show
   * if you want an specific date to show
   * @returns {void} void
   */
  public setDateLabel(staticDate?: moment.Moment): void {
    let dateToSet: moment.Moment = null;
    
    if (staticDate) {
      dateToSet = staticDate;
    } else {
      dateToSet = this.isCurrentMonth() ? moment.utc() : moment(
        this.actualPeriod.startDate
      );
    }

    this.actualDate = dateToSet.format('YYYY-MM-DD');

    if (this.dataLoaded) {
      this.dateLabel.day = moment(dateToSet).format('MMMM');
      this.dateLabel.month = moment(dateToSet).format('Do');
      this.dateLabel.year = moment(dateToSet).format('YYYY');
    }
  }

  /**
   * isCurrentMonth method
   * @description Checks if you are viewing
   * the current month
   * @returns {boolean} boolean
   */
  private isCurrentMonth(): boolean {
    return moment.utc().isBetween(
      this.actualPeriod.startDate,
      this.actualPeriod.endDate,
    )
  }

  /**
   * nextMonth method
   * @description Changes to next month
   * @returns {void} void
   */
  public nextMonth(): void {
    this.container.nativeElement.click();
    this.container.nativeElement.scrollTop = 0;
    this.monthSelector.makeSelection(
      this.monthOptions[this.monthControl.value + 1],
    );
  }


  /**
   * prevMonth method
   * @description Changes to previous month
   * @returns {void} void
   */
  public prevMonth(): void {
    this.container.nativeElement.click();
    this.container.nativeElement.scrollTop = 0;
    this.monthSelector.makeSelection(
      this.monthOptions[this.monthControl.value - 1],
    );
  }

  /**
   * updateStatus method
   * @description Update status at a specific date and segment
   * @param {string} status - New status to set ('holiday' or 'working day')
   * @returns {void}
   */
  public updateStatus(status: string): void {
    let segment: string = null;
    let segmentId: string | number = null;

    if (this.actualLocation.name !== 'World Wide') {
      if (this.actualLocation.children) {
        segment = 'region';
        segmentId = this.actualLocation.id;
      } else {
        segment = 'location';
        segmentId = this.actualLocation.id;
      }
    }

    this.calendarService.updateCalendarStatus(
      this.actualDate,
      status.toLowerCase(),
      segment,
      segmentId,
    ).subscribe((result) => {
      this.status = status;
      this.monthContainer.getCalendarData(
        moment(this.actualPeriod.startDate),
        moment(this.actualPeriod.endDate),
      );
    });
    
  }
}
