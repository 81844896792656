import { Component } from '@angular/core';
import { version } from 'package.json';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  public timerView: boolean = false;
  public version: string = `Version: ${version}`;
  
  constructor() {}
  
  /**
   * goToTimerLoginView method
   * start the timer
   * @param navigateTo rute to be redirected
   * @returns {void}
   */
  public goToTimerLoginView(navigateTo: string): void{
    this.timerView = true;
  }
}
