import { 
  Directive, 
  Input,
  Output, 
  OnInit,
  OnChanges,
  ElementRef,
  HostListener, 
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[appPaginate]'
})
export class PaginateDirective implements OnInit, OnChanges {

  @Input() childContainer: HTMLElement;
  @Input() itemsRetrieved: boolean;

  @Output() newPage: EventEmitter<string> = new EventEmitter<string>();

  constructor(private element: ElementRef) { }

  /**
   * ngOnInit hook
   * get the initial pagination and get more if there are not enough items
   * to fill the page and if they exist
   * @return {void}
   */
  ngOnInit() {
    this.paginate();
  }

  /**
   * ngOnChanges hook
   * detect changes in the input properties to determine if another initial
   * request for pagination should be made
   * @return {void}
   */
  ngOnChanges(): void {
    if (
      this.childContainer && 
      this.itemsRetrieved &&
      !this.childOverflowsParent()
    ) {
      this.paginate();
    }
  }

  /**
   * childOverflowsParent method
   * checks if the child container doesn't have enough items to fill the 
   * page (web page) to retrieve more of them (should there be more)
   * @return {boolean}
   */
  private childOverflowsParent(): boolean {
    const childHeight: number = this.childContainer.scrollHeight;
    const parentHeight: number = this.element.nativeElement.scrollHeight;

    return childHeight >= parentHeight;
  }

  /**
   * checkIfBottomReached method
   * if the bottom of the container element has been reached through scrolling,
   * call for more resources
   * @param {HTMLElement} element container of the resources to be paginated
   * @returns {void}
   */
  @HostListener('scroll', ['$event.target'])
  public checkIfBottomReached(element: HTMLElement): void {
    const position: number = element.scrollTop + element.offsetHeight;
    const max: number = element.scrollHeight;
    if (Math.ceil(position) >= max && element.scrollTop > 0) {
      this.paginate();
    }
  }

  /**
   * paginate method
   * in charge of calling the service of the entity at the time and
   * emitting the result
   * @returns {void}
   */
  private paginate(): void {
    // emit event to let know the component to get more items
    this.newPage.emit('newPage');
  }

}
