import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewProject } from 'src/app/models/projects/new-project.model';
import { EditProject } from 'src/app/models/projects/edit-project.model';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProjectsListResponse } from 'src/app/models/projects/project-list-response.model';
import { ProjectResponse } from 'src/app/models/projects/project-response.model';
import { ProjectsListNamesResponse } from 'src/app/models/projects/projects-list-names-response.model';
import { EmptyResponse } from 'src/app/models/projects/empty-response.model';
import { environment } from 'src/environments/environment';
import { ProjectStatusOptions } from 'src/app/models/project-status-options.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private http: HttpClient) {}

  /**
   * getProjects method
   * @description Gets the projects from the backend through pagination
   * @param {number} page - Array containing the projects to be sliced
   * @param {string} search - Text for filter by project name (null for all)
   * @param {string} status - Filter the projects by active or inactive
   * @param {string} involved - Filter the projects by those related to
   * the user or all the projects
   * @param {string} clientId - Filter the projects by clientId
   * @returns {Observable<ProjectsListResponse>} Observable<ProjectsListResponse>
   * Array containing the page of projects
   */
  public getProjects(
    page: number,
    search: string,
    status: string,
    involved: string,
    clientId?: string,
  ): Observable<ProjectsListResponse> {
    let url: string = `${environment.apiUrl}/projects?page=${page}`;

    if (search && search.trim()) {
      url += `&search=${encodeURIComponent(search)}`;
    }

    if (status && status.trim()) {
      url += `&status=${status}`;
    }

    if (involved && involved.trim()) {
      url += `&involved=${involved}`;
    }

    if (clientId && clientId.trim()) {
      url += `&clientId=${clientId}`;
    }

    return this.http.get<ProjectsListResponse>(url).pipe(
      catchError((error) => {
        return of(error);
      }),
    );
  }

  /**
   * getProject method
   * gets the details project to the database
   * @param {number} id of the project
   * @returns {Observable<ProjectResponse>} project response
   */
  public getProject(id: number): Observable<ProjectResponse> {
    return this.http
      .get<ProjectResponse>(`${environment.apiUrl}/projects/${id}`)
      .pipe(
        catchError((error) => {
          return of(error);
        }),
      );
  }

  /**
   * Method createProject returns an Observable
   * with data of the project to the database
   * @param {NewProject} newProject are the project data
   * @returns {Observable<ProjectResponse>} project response
   */
  public createProject(newProject: NewProject): Observable<ProjectResponse> {
    return this.http.post<ProjectResponse>(
      `${environment.apiUrl}/projects`,
      newProject,
    );
  }

  /**
   * updateProject method
   * Edit an existent project updating its properties by a new properties
   * @param {EditProject} newProperties - Are the new project data
   * @param {number} projectID - Is the project id to edit
   * @returns {Observable<EmptyResponse>} endpoint empty response
   */
  public updateProject(
    newProperties: EditProject,
    projectID: number,
  ): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(
      `${environment.apiUrl}/projects/${projectID}`,
      newProperties,
    );
  }

  /**
   * toggleProject method
   * Archives or restore an existent project
   * @param {number} projectId - ID of the project that wil be archived or restored
   * @returns {Observable<EmptyResponse>} endpoint empty response
   */
  public toggleProject(projectId: number): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(
      `${environment.apiUrl}/projects/${projectId}/toggle`,
      {},
    );
  }

  /**
   * getProjectListNames method
   * @description Gets the projects and their ids from the database to put them in a selector
   * @param {ProjectStatusOptions} status - Optional param to find active or archived projects
   * if this param no exists the method will get all the projects
   * @returns {Observable<ProjectResponse>} project response
   */
  public getProjecstListNames(
    status?: ProjectStatusOptions,
  ): Observable<ProjectsListNamesResponse> {
    let url: string = `${environment.apiUrl}/projects/names`;

    if (status) {
      url += `?status=${status}`;
    }

    return this.http.get<ProjectsListNamesResponse>(url).pipe(
      catchError((error) => {
        return of(error);
      }),
    );
  }

  /**
   * getClients method
   * get all data clients from database.
   * @returns {Observable<Client>} list of all clients
   */
  public getClients(): Observable<EmptyResponse> {
    return this.http.get<EmptyResponse>(`${environment.apiUrl}/clients`).pipe(
      catchError((error) => {
        return of(error);
      }),
    );
  }
}
