import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { LoginResponse } from 'src/app/models/login-response.model';
import { loginErrors } from 'src/app/config/error-messages';
import { mailRegexNoEmojis } from 'src/app/config/regex';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Output() forgotPasswordView: EventEmitter<string> = new EventEmitter<string>();

  public showLoginError: boolean = false;
  
  // form controls
  public emailControl = new FormControl('', []);
  public passwordControl = new FormControl('', []);

  // error messages
  public emailErrors = loginErrors.emailErrors;
  public passwordErrors = loginErrors.passwordErrors;

  // input validators
  public emailValidators: Validators[] = [
    Validators.required,
    Validators.email,
    Validators.pattern(mailRegexNoEmojis),
  ];
  public passwordValidators: Validators[] = [
    Validators.required,
  ];

  // login form group
  public loginForm: FormGroup = new FormGroup({
    email: this.emailControl,
    password: this.passwordControl,
  });
  
  constructor(private route: Router, private loginService: LoginService) {}

  /**
   * logIn method
   * Allows attempt login and store token if everything is correct redirects to
   * the user's profile if an error occurs send a message to the invalid data
   * user
   * @returns {void}
   */
  public logIn(): void {
    if (this.loginForm.valid) {
      // attempt login and store token
      this.loginService
        .login(this.loginForm.value)
        .subscribe((response: LoginResponse): void => {
          const { id, token, permissions } = response.data; 
          localStorage.setItem('id', id.toString());
          localStorage.setItem('auth', token);
          // store the user permissions as string
          localStorage.setItem('permissions', JSON.stringify(permissions));

          // navigate to other route
          this.route.navigateByUrl('/dashboard/profile');
        }, error => {
          this.showLoginError = true;
        });
    } 
  }

  /**
   * goToResetPasswordView method
   * Go to Reset Password View
   * @returns {void}
   */
  goToResetPasswordView(): void{
    this.route.navigateByUrl('reset-password');
  }
}
