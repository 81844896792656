import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Project } from 'src/app/models/projects/project.model';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { addTeamMemberErrors } from 'src/app/config/error-messages';
import { environment } from 'src/environments/environment';
import { TeamMemberService } from 'src/app/services/team-member.service';
import { TeamMemberProjectRelation } from 'src/app/models/team-member/team-member-project-relation.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { ProjectUser } from 'src/app/models/projects/project-user.model';

@Component({
  selector: 'app-edit-team-member',
  templateUrl: './edit-team-member.component.html',
  styleUrls: ['./edit-team-member.component.scss'],
})
export class EditTeamMemberComponent implements OnInit {
  
  public projectId: number;
  public memberId: number;
  public project: Project;
  public user: ProjectUser;

  public roles: SelectorOption[] = [];
  public stringOriginalFormGroupValue;
  public modified: boolean = false;
  public imagesServer: string = environment.url;
  public teamMemberProjectRelation: TeamMemberProjectRelation;
  public variabilities: SelectorOption[] = [
    { id: null, name: 'Fixed' },
    { id: null, name: 'Variable' },
  ];
  public variability: SelectorOption;
  public role: SelectorOption;
  public isLeader: boolean = true;
  public displayWeeklyErrors: boolean = false;
  
  public idControl: FormControl = new FormControl('', [Validators.required]);
  public weeklyHoursControl: FormControl = new FormControl('', []);
  public variabilityControl: FormControl = new FormControl('', []);
  public roleControl: FormControl = new FormControl('', []);
  public isLeaderControl: FormControl = new FormControl(true, []);

  // validators
  public weeklyHoursValidators: ValidatorFn[] = [
    Validators.min(1), 
    Validators.max(40),
    Validators.required,
    Validators.pattern(/^[0-9]*$/),
  ];
  public variabilityValidators: Validators[] = [Validators.required];
  public roleValidators: Validators[] = [Validators.required];

  // form group
  public editTeamMemberForm: FormGroup = new FormGroup({
    id: this.idControl,
    expectedHoursPerWeek: this.weeklyHoursControl,
    variability: this.variabilityControl,
    projectRoleId: this.roleControl,
    isLeader: this.isLeaderControl,
  });

  // errors
  public weeklyHoursErrors = addTeamMemberErrors.weeklyHours;

  constructor(
    private teamMemberService: TeamMemberService,
    private projectsService: ProjectsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  /**
   * ngOnInit hook
   * get the projectId and memberId from the url and set the project in the
   * form and project details
   */
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.memberId = parseInt(params.memberId, 10);
      this.activatedRoute.parent.parent.params.subscribe((params: Params) => {
        this.projectId = parseInt(params.id, 10);
        this.setProject();
      })
    });

    this.weeklyHoursControl.setValidators(this.weeklyHoursValidators);
    this.weeklyHoursControl.updateValueAndValidity();
  }
  
  /**
   * ngAfterViewInit hook
   * change the form status to modified or original to enable or disable the
   * save button
   */
  ngAfterViewInit() {
    this.editTeamMemberForm.valueChanges.subscribe(val => {
      this.modified = this.stringOriginalFormGroupValue !== JSON.stringify(val);
    })
  }
  
  /**
   * setRoles method
   * get the roles from back end and select the actual user's role
   * @param {number} roleId The user's role id
   * @returns {void}
   */
  private setRoles(roleId): void {
    this.teamMemberService.getProjectRoles().subscribe(result => {
      this.roles = result.data;
      this.role = this.roles.find((role) => {
        return role.id === roleId;
      });
    });
  }

  /**
   * setDefaultValues method
   * get the user-project relation and put the values in the form
   * @returns {void}
   */
  public setDefaultValues(): void {
    this.user = this.project.activeUsers.find(user => user.id === this.memberId);

    this.teamMemberService.getTeamMemberProjectRelation(this.user.id, this.projectId)
      .subscribe((relation) => {
      this.idControl.setValue(relation.data.id);

      this.weeklyHoursControl.setValue(relation.data.expectedHoursPerWeek.toString());
      
      this.variability = this.variabilities.find((variability) => {
        return variability.name === relation.data.variability;
      });

      this.setRoles(relation.data.projectRoleId);

      this.isLeader = relation.data.isLeader;

      this.stringOriginalFormGroupValue = JSON.stringify({
        'id': relation.data.id,
        'expectedHoursPerWeek': relation.data.expectedHoursPerWeek.toString(),
        'variability': relation.data.variability,
        'projectRoleId': relation.data.projectRoleId,
        'isLeader': relation.data.isLeader,
      });

    });
  }

  /**
   * setProject method
   * get the project from the back end and set it to show the details
   * @returns {void}
   */
  public setProject(): void {
    this.projectsService.getProject(this.projectId)
      .subscribe((projectResponse) => {
        this.project = projectResponse.data;
        this.setDefaultValues();
    });
  }

  /**
   * onSubmit method
   * handle the logic when submitting the form
   * @return {void}
   */
  public onSubmit(): void {
    if (this.editTeamMemberForm.valid) {
      this.teamMemberService.editTeamMemberProjectRelation(
        this.editTeamMemberForm.value
      ).subscribe(
        (response) => {
          if (response.status === 'success') {
            this.goToEditProjectView();
          }
        }
      );
    }    
  }

  /**
   * goToEditProjectView method
   * go back to the edit project page
   * @return {void}
   */
  public goToEditProjectView(): void {
    this.router.navigateByUrl(`manage/projects/${ this.projectId }/edit`);
  }


  /**
   * validateNumberInput method
   * validate tha the hours per week input admit only
   * numeric charaters
   * @returns {void}
   */
  public validateNumberInput(): void {
    const value = this.weeklyHoursControl.value;

    if (!value.match('^[0-9]*$')) {
      this.weeklyHoursControl.setValue(value.slice(0, value.length - 1)); 
    }
  }

  /**
   * checkWeeklyHoursErrors method
   * set displayWeeklyErrors flag to true if there are errors
   * @returns {void}
   */
  public checkWeeklyHoursErrors(): void {
    if (this.weeklyHoursControl.errors) {
      this.displayWeeklyErrors = true;
    } else {
      this.displayWeeklyErrors = false;
    }
  }

    /**
   * preventPasting method
   * prevents the pasting of values in certain inputs
   * @param {ClipboardEvent} event event triggered
   * @return {void}
   */
  public preventPasting(event: ClipboardEvent): void {
    const copiedData = event.clipboardData.getData('Text');

    // do not allow anything other than numbers
    if (isNaN(+copiedData)) {
      event.preventDefault();
    }
  }
}
