import { Component, OnInit, AfterContentChecked, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, AfterViewChecked, SimpleChanges, OnChanges, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription, merge } from 'rxjs';

import { permissions } from 'src/app/config/permissions';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UserService } from 'src/app/services/user.service';
import { RolesService } from 'src/app/services/roles.service';
import { assignRoleErrors } from 'src/app/config/error-messages';
import { ProfileResponse } from 'src/app/models/profile-response.model';
import { RolesResponse } from 'src/app/models/responses/roles-response.model';
import { Tab } from 'src/app/models/shared/tab.model';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { RolePermissions } from 'src/app/models/role-permissions.model';
import { RoleUsers } from 'src/app/models/role-users.model';
import { RoleAssignation } from 'src/app/models/employees/role-assignation.model';
import { EmptyResponse } from 'src/app/models/projects/empty-response.model';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss'],
  providers: [ProfileService],
})
export class EditEmployeeComponent implements OnInit, AfterContentChecked, OnDestroy {
  private employeeSubs: Subscription;
  private employeeDataSubs: Subscription;
  // page to obtain employee's roles from the database (default 1)
  private employeePage: number = 1;
  // page to obtain all the roles from the database (default 1)
  private rolesPage: number = 1;
  // version of the photo to request once it has been updated
  private versionPhoto: number = Date.now();

  public assignRoleForm: FormGroup;
  public roleControl: FormControl;
  public roleValidators: Validators[];
  public roleErrors = assignRoleErrors;
  public rolesOptions: SelectorOption[] = [];
  public newRolesOptions: SelectorOption[] = [];
  public hasPermissions: boolean;
  public personalInfoInEdit: boolean = false;
  public bottomFormsInEdit: boolean = false;

  // boolean to determine when the roles exist and are loaded
  public rolesLoaded: boolean = false;
  // boolean to determine if the call for roles has been done
  public rolesRequested: boolean = false;
  public employeeId: number;
  public employee: ProfileResponse;
  public tabs: Tab[] = [];
  public tabsToShow: Tab[] = [];

  // temporary set it to 1 (Resume)
  public contentToDisplay: number;
  public roles: RolePermissions[] = [];
  public permissions = permissions;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private rolesService: RolesService,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionsService: PermissionsService
  ) { }

  /**
   * ngOnInit hook
   * obtain the id of the employee to edit based on the
   * parameter in the active route, and set the tabs
   * @return {void}
   */
  ngOnInit() {
    this.tabs = [
      {
        id: 1,
        title: 'Resume',
        icon: 'A',
        active: true,
        show: this.permissionsService.userHasPermission([
          this.permissions.editResume,
          this.permissions.companyMembers,
        ]),
      },
      // Not Ready
      // {
      //   id: 2,
      //   title: 'Check In',
      //   active: false,
      // },
      // {
      //   id: 3,
      //   title: 'Days off',
      //   active: false,
      // },
      // {
      //   id: 4,
      //   title: 'Projects',
      //   show: this.permissionsService
      //     .userHasPermission([
      //       this.permissions.projects,
      //       this.permissions.management
      //     ]),
      //   active: false,
      // },
      {
        id: 5,
        title: 'Roles',
        icon: 'B',
        // change this to hide it according to permissions
        show: this.permissionsService
        .userHasPermission([
          this.permissions.companyMembers,
          this.permissions.assignRoles,
        ]),
        active: !this.permissionsService.userHasPermission([
          this.permissions.editResume,
          this.permissions.companyMembers,
        ]),
      },
    ];

    this.tabsToShow = this.tabs.filter((tab) => {
      return tab.show;
    })

    const rolesIndex = this.tabs.findIndex(tab => tab.title === 'Resume');

    if (!this.tabs[rolesIndex].show) {
      const tabToShow = this.tabs.find(tab => tab.show);

      if (tabToShow) {
        this.contentToDisplay = tabToShow.id;
        tabToShow.active = true;
      }
    } else {
      this.contentToDisplay = this.tabs[rolesIndex].id;
    }

    const id = +this.activatedRoute.snapshot.paramMap.get('employeeId');

    // validate the id in the router
    if (!isNaN(id) && id % 1 === 0) {
      this.employeeId = id;
      this.getEmployeeData();
    }

    this.employeeDataSubs = merge(
      this.profileService.experienceAdded,
      this.profileService.experienceUpdated,
      this.profileService.skillAdded,
      this.profileService.editPersonalUpdated,
      this.profileService.descriptionUpdated
    ).subscribe(() => this.getEmployeeData());;

    // refresh data after update personal user info
    this.userService.refreshNedded$
      .subscribe(() => {
        this.getEmployeeData();
      });

    // set up the roles options for the selector
    this.getRoles();

    // set up form control
    this.roleControl = new FormControl(null, []);

    // set up the validators for the form
    this.roleValidators = [Validators.required];

    // set up the form to add or edit roles
    this.assignRoleForm = new FormGroup({
      'roleId': this.roleControl,
    });

    this.checkPermissions();
  }

  /**
   * ngAfterViewChecked hook
   * check if the items have been rendered and run the change detection
   * system to tell the pagination directive to run again if necessary
   * @return {void}
   */
  ngAfterContentChecked() {
    if (this.roles.length && !this.rolesLoaded) {
      this.rolesLoaded = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * displaySelectedTab method
   * sets the tab to be displayed
   * @param {number} tab tab to be displayed
   * @return {void}
   */
  public displaySelectedTab(tab: number): void {
    this.contentToDisplay = tab;
    // restart add role form
    this.restartAddRoleForm();

    // turn all editForm properties for the roles to false
    this.closeEditRoleForms();

    this.bottomFormsInEdit = false;
  }

  /**
   * getEmployeeData method
   * obtain the data of the user based on the id
   * @return {void}
   */
  private getEmployeeData(): void {
    this.employeeSubs = this.userService
      .getUser(this.employeeId)
      .subscribe((result: ProfileResponse) => {
        // de-cache the photo updated
        result.data.photo += `?=${this.versionPhoto}`;
        this.versionPhoto = Date.now();
        this.employee = result;
      });
  }

  /**
   * getRoles method
   * obtain all the different roles available
   * @return {void}
   */
  private getRoles(): void {
    this.rolesService.getRolesForEmployee(this.rolesPage)
      .subscribe((result: RolesResponse) => {
        if (
          result.data.items.length &&
          !this.isRolePermissions(result.data.items)
        ) {
          const totalPages: number = result.data.pages;
          const roles: RoleUsers[] = result.data.items;

          this.newRolesOptions = roles.map((role) => {
            return {
              id: role.id,
              name: role.name,
            };
          });

          this.rolesOptions.push(...this.newRolesOptions);

          this.rolesPage += 1;

          // retrieve all the roles if there are more in the database
          if (totalPages >= this.rolesPage) {
            this.getRoles();
          }
        }
      });
  }

  /**
   * getEmployeeRoles method
   * obtain the roles that the employee has assigned
   * @return {void}
   */
  public getEmployeeRoles(): void {
    this.userService
      .getUserRoles(this.employeeId, this.employeePage)
      .subscribe((result: RolesResponse) => {
        if (
          result.data.items.length &&
          result.data.currentPage === this.employeePage &&
          this.isRolePermissions(result.data.items)
        ) {
          const roles: RolePermissions[] = result.data.items;

          roles.forEach(role => role.editForm = false);

          this.roles.push(...roles);
          this.employeePage += 1;
        }

        this.rolesRequested = true;
    });
  }

  /**
   * openEditRoleForm method
   * open the edit form to update the role depending on the index
   * @param {number} index index of the role to be updated
   * @returns {void}
   */
  public openEditRoleForm(index: number): void {
    this.roles[index].editForm = true;
    this.bottomFormsInEdit = true;
  }

  /**
   * closeEditRoleForms method
   * closes all of the edit form for the roles
   * @returns {void}
   */
  private closeEditRoleForms(): void {
    this.roles.forEach(role => role.editForm = false);
  }

  /**
   * restartAddRoleForm method
   * restart the value of the add role form
   * @returns {void}
   */
  private restartAddRoleForm(): void {
    this.roleControl.setValue(null);
    this.assignRoleForm.markAsPristine();
  }

  /**
   * getRoleDefault method
   * obtains the role to be set as default in the selector
   * @param {RolePermissions} defaultRole role to be set as default
   * @returns {SelectorOption}
   */
  public getRoleDefault(defaultRole: RolePermissions): SelectorOption {
    return this.rolesOptions.find((role) => role.id === defaultRole.roleId);
  }

  /**
   * onSubmit method
   * assigns the new role or updates it
   * @param {number} oldRoleId id of the role to be replaced
   * @param {number} index index of the role to set its edit view to false
   * @return {void}
   */
  public onSubmit(oldRoleId: number = null, index: number = null): void {
    const roleData: RoleAssignation = {
      roleId: this.roleControl.value,
    };

    // add the old role
    if (oldRoleId) {
      roleData.oldRoleId = oldRoleId;
    }

    this.userService.assignUserRole(this.employeeId, roleData)
      .subscribe((result: EmptyResponse) => {
        if (index && this.roles[index].editForm) {
          this.roles[index].editForm = false;
        }

        // obtain the new roles once assigned
        this.roles = [];
        this.employeePage = 1;
        this.getEmployeeRoles();
      });
    
    this.bottomFormsInEdit = false;
  }

  /**
   * isRolePermissions method
   * type guard to ensure the type of the response received
   * @returns {TypePredicate}
   */
  private isRolePermissions(role: RolePermissions[] | RoleUsers[]): role is RolePermissions[] {
    return (role as RolePermissions[])[0].permissions !== undefined;
  }

  /**
   * checkPermissions method
   * Validate if the logged user has permissions to
   * edit the users resume
   * @returns {void}
   */
  private checkPermissions(): void {
    this.hasPermissions = this.permissionsService
      .userHasPermission([
        permissions.editResume,
        permissions.companyMembers
      ]);
  }

  /**
   * changeStatusButtons method
   * @description Receive an status from EditPersonalInfo component
   * if this form is in edit or not, and with this value set a flag
   * to set the others button hidden or showed.
   * @param {boolean} status - form in edit or not boolean value.
   * @returns {void} void
   */
  public changeStatusButtons(status: boolean): void {
    this.personalInfoInEdit = status;
  }

  /**
   * changeStatusTopButtoon method
   * @description Receive an status from
   * description, skills or experiences component
   * if one of these forms is in edit or not, and with this value set a flag
   * to set the top button hidden or showed.
   * @param {boolean} status - form in edit or not boolean value.
   * @returns {void} void
   */
  public changeStatusTopButton(status: boolean): void {
    this.bottomFormsInEdit = status;
  }

  /**
   * ngOnDestroy hook
   * unsubscribe from observables
   * @return {void}
   */
  ngOnDestroy() {
    this.employeeSubs.unsubscribe();
    this.employeeDataSubs.unsubscribe();
  }
}
