import { Component, OnInit, OnDestroy } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service'
import { ProfileService } from 'src/app/services/profile.service';
import { ProfileResponse } from 'src/app/models/profile-response.model'
import { ContentModal } from 'src/app/models/content-modal.model';
import { UserSkillsService } from 'src/app/services/user-skills.service';
import { Response } from 'src/app/models/response.model';
import { welcomeModal } from 'src/app/config/modal-contents';
import { TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    ProfileService,
    TitleCasePipe
  ],
})
export class ProfileComponent implements OnInit, OnDestroy {

  public user: ProfileResponse;
  public userId: number = 0;
  public showModal: boolean = false;
  public userDataSubs: Subscription;

  // Data to customize the Welcome Modal
  public contentWelcomeModal: ContentModal = {
    image: welcomeModal.image,
    title: '',
    content: welcomeModal.content,
    confirmButton: welcomeModal.confirmButton,
  };

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private userSkillService: UserSkillsService,
    private titleCasePipe: TitleCasePipe,
    private route: Router
  ) {
    // taking the logged user id from local storage
    const id = +localStorage.getItem('id');

    if (!isNaN(id)) {
      this.userId = id;
      this.getUserData();
    }
  }

  /**
   * ngOnInit hook
   * shows the modal if it was
   * the first time the user sees his/her page
   */
  ngOnInit() {
    this.showModal = false;
    this.getUserData();
    // merge the subjects
    this.userDataSubs = merge(
      this.profileService.experienceAdded,
      this.profileService.experienceUpdated,
      this.profileService.skillAdded,
      this.profileService.editPersonalUpdated,
      this.profileService.descriptionUpdated
    ).subscribe(() => this.getUserData());

    // refresh data after update personal user info
    this.userService.refreshNedded$
      .subscribe(() => {
        this.getUserData();
      });
  }

  /**
  * getUserData method
  * gets the user data based on its id
  * @return {void}
  */
  private getUserData(): void {
    this.userService
      .getUser(this.userId)
      .subscribe((result: ProfileResponse) => {
        this.user = result;
        this.user.data.photo += `?v=${Date.now()}`;
        if (localStorage.getItem('firstIn')) {
          const firstName = this.titleCasePipe.transform(this.user.data.firstName);
          this.contentWelcomeModal.title = welcomeModal.title(firstName);
          localStorage.removeItem('firstIn');
          this.showModal = true;
        }
    }, error => {
      this.route.navigateByUrl('login');
    });
  }

  /**
   * onCloseModal Method
   * Listen the PopUpMessage EventEmitter of close
   * When it is received the showModal variable turns to false and the modal is closed
   * @return {void}
   */
  public onCloseModal(): void {
    this.showModal = false;
  }

  /**
   * deleteUserSkill method
   * delete a user's skill
   * @param {number} skillId skill to delete
   * @returns {void}
   */
  public deleteUserSkill(skillId: number): void {
    this.userSkillService.deleteUserSkill(this.user.data.id, skillId)
    .subscribe((response: Response) => {
      if (response.status === 'success') {
        this.user.data.skills = this.user.data.skills.filter(
          skillObject => skillObject.id !== skillId
        );
      }
    });
  }

  /**
   * ngOnDestroy hook
   * unsubscribes from all subjects
   * @return {void}
   */
  ngOnDestroy() {
    this.userDataSubs.unsubscribe();
  }
}

