import { Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-database-section-header',
  templateUrl: './database-section-header.component.html',
  styleUrls: ['./database-section-header.component.scss']
})
export class DatabaseSectionHeaderComponent {
  @Input() title: string;
  @Output() showContent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public shapeInShow: boolean = true;

  constructor() { }

  /**
   * toggleShape method
   * @description Emit an event with a boolean value to
   * show/hide the parent component content
   * @returns {void} void
   */
  public toggleShape(): void {
    this.shapeInShow = !this.shapeInShow;
    this.showContent.emit(this.shapeInShow);
  }
}
