import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * validateEmail method
 * custom validator for email
 * @param status response by service validateEmail
 * @return {ValidatorFn} if status is not success or null
 */
function validateEmailValidator(message: string): ValidatorFn {
  return (control: AbstractControl) => {
    
    switch (message) {
      case 'Email exists':
        return null;
      case 'There is already a user account with this email':
        return { emailRepeat: true };
      case 'Email does not exist as an active BTS account':
        return { emailNotFound: true };
      case 'The email already exists':
        return { EmailRepeat: true };
    }
  }
}

export { validateEmailValidator };