import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { 
  HttpRequest, 
  HttpHandler, 
  HttpInterceptor, 
  HttpEvent, 
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  /**
   * interceptor method
   * will be called on all HTTP requests from the application
   * to check if the response is a 401 (unauthorized) error
   * @return {Observable<HttpEvent<any>>} observable with the HTTP event
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error.status) { 
            // unauthorized
            case 401:
              // clear the localStorage
              localStorage.clear();

              // redirect to login
              this.router.navigateByUrl('login');
              break;
              // forbidden
            case 403:
              // set the new token and permissions
              const { id, permissions } = error.error.data;
              const token = error.error.data.token;

              if (id && permissions && token) {
                localStorage.setItem('id', id.toString());
                localStorage.setItem('auth', token);
                // store the user permissions as string
                localStorage.setItem('permissions', JSON.stringify(permissions));
                location.reload();
                return;
              }

              this.router.navigateByUrl('dashboard');
              break;  
          }
          return throwError(error);
        })
      );
  }
}
