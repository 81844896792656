import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { permissions } from 'src/app/config/permissions';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-projects-list-header',
  templateUrl: './projects-list-header.component.html',
  styleUrls: ['./projects-list-header.component.scss']
})
export class ProjectsListHeaderComponent {

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  public enteringInput: boolean = false;
  public permissions = permissions;

  constructor(
    private router: Router,
    public permissionsService: PermissionsService
  ) { }

  /**
   * goToCreateProjects
   * Navigates to create projects view
   * @returns {void} void
   */
  public goToCreateProjects(): void {
    this.router.navigateByUrl('manage/projects/create');
  }

  /**
   * onInput method
   * Hide label text when user is
   * introducing input and emit
   * the introduced text
   * @param {HTMLInputElement} input input element
   * @return {void}
   */
  public onInput(input: HTMLInputElement): void {
    this.enteringInput = input.value ? true : false;
    this.search.emit(input.value);
  }
}
