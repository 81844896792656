import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatabasesRequest } from 'src/app/models/databases-request.model';
import { environment } from 'src/environments/environment';
import { EmptyResponse } from 'src/app/models/projects/empty-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatabasesService {

  constructor(private http: HttpClient) { }

  /**
   * sendRequest method
   * @description Send a request to a certain endpoint, with certain method
   * and a certain body (if is required).
   * @param {DatabasesRequest} request - Object that contains
   * the method, endpoint and body to send the request
   * @returns {Observable<EmptyResponse>} Observable<EmptyResponse>
   */
  public sendRequest(request: DatabasesRequest): Observable<EmptyResponse> {
    return this.http[request.method.toLowerCase()]<EmptyResponse>(
      `${environment.apiUrl}${request.endpoint}`, 
      request.body,
    );
  }
}
