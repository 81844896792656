import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Project } from 'src/app/models/projects/project.model';
import { ClientTeamMember } from 'src/app/models/client-team-member.model';

import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { addClientTeamMemberErrors } from 'src/app/config/error-messages';
import { mailRegex, alphabeticRegex } from 'src/app/config/regex';
import { validateEmailValidator } from 'src/app/shared/custom-validators/custom-validator-email';
import { ClientTeamMemberService } from 'src/app/services/client-team-member.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-edit-client-member',
  templateUrl: './edit-client-member.component.html',
  styleUrls: ['./edit-client-member.component.scss'],
  providers: [ClientTeamMemberService]
})
export class EditClientMemberComponent implements OnInit {

  public project: Project;
  public clientMemberId: string; 
  public clientTeamMemberId: number;
  public clientTeam: ClientTeamMember[];
  public clientTeamMember: Object;
  public hasChanged: boolean;

  // form controls
  public clientTeamMemberNameControl: FormControl = new FormControl('', []);
  public clientTeamMemberRoleControl: FormControl = new FormControl('',[]);
  public clientTeamMemberEmailControl: FormControl = new FormControl('',[])

  // Validators
  public clientTeamMemberNameValidators: Validators[] = [
    Validators.required,
    patternValidator(alphabeticRegex),
  ];
  public clientTeamMemberRoleValidators: Validators[] = [
    Validators.required,
    patternValidator(alphabeticRegex),
  ];
  public emailValidations: Validators[] = [
    Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$/),
  ];
  public clientTeamMemberEmailValidators: Validators[] = Array.from(this.emailValidations);

  // Errors
  public clientTeamMemberNameError =
    addClientTeamMemberErrors.clientTeamMemberName;
  public clientTeamMemberRoleError =
    addClientTeamMemberErrors.role;
  public clientTeamMemberEmailError =
    addClientTeamMemberErrors.email;

  //Form group
  public editClientTeamMemberForm: FormGroup = new FormGroup({
    name: this.clientTeamMemberNameControl,
    role: this.clientTeamMemberRoleControl,
    email: this.clientTeamMemberEmailControl,
  });

  constructor(
    private projectService: ProjectsService,
    private clientTeamMemberService: ClientTeamMemberService,
    private activatedRoute: ActivatedRoute,
    private router: Router
    ) {
      this.activatedRoute.parent.parent.params.subscribe((params) => {
        this.getProject(params.id);
      });
      this.activatedRoute.params.subscribe((params) => {
        this.clientMemberId = params.memberId;
      })
     }

  ngOnInit() { }

  /**
   * getProject method
   * This method subscribes to a getProject method in Projects service
   * and retrieves the data of an existing project
   * @param {number} id project ID of the project to retrieves the data
   * @returns {void}
   */
  private getProject(id: number): void {
    this.projectService.getProject(id).subscribe((project) => {
      this.project = project.data; 
      this.getClientTeamMemberData();
    }); 
  }

  /**
   * getClientTeamMemberData 
   * This method get the data of the current client team member
   * make a filter to retrieve his data
   * @return {void}
   */
  private getClientTeamMemberData () {
    if (this.clientMemberId) { 
      this.clientTeamMemberId = parseInt(this.clientMemberId, 10);
      this.clientTeam = this.project.activeClientMembers;
      this.clientTeam = this.clientTeam.filter(client => client.id === this.clientTeamMemberId);

      !this.clientTeam[0].email ? 
        this.clientTeam[0].email = "" :  this.clientTeam[0].email = this.clientTeam[0].email;

      this.clientTeamMember = {
        name: this.clientTeam[0].name,
        role: this.clientTeam[0].role,
        email: this.clientTeam[0].email,
      }
      
      this.getClientTeamMember();
      this.checkChangesInForm();
    }
  }

  /**
   * getClientTeamMember method
   * Gets client team member data from parent and set it to the form
   * @returns {void} void
   */
  private getClientTeamMember(): void {
    if (this.project) {
      this.editClientTeamMemberForm.setValue(this.clientTeamMember);
    }
  }

  /**
   * checkChangesInForm
   * go trough the values in the form and checks that they are different than the receives.
   * @returns {void} void
   */
  private checkChangesInForm(): void {
    this.editClientTeamMemberForm.valueChanges.subscribe((data) => {
      for (const key in data) {
        if (data[key] === this.clientTeamMember[key]) {
          this.hasChanged = false;
        } else {
          this.hasChanged = true;
          return
        }
      }
    })
  }

   /**
   * update method
   * handle the logic when updating the form
   * @return {void}
   */
   public update() {
     if(!this.editClientTeamMemberForm.value.email) {
      this.editClientTeamMemberForm.removeControl('email');
     }
     this.clientTeamMemberService.editClientTeamMemberProject(this.editClientTeamMemberForm.value,
       this.project.id, this.clientTeamMemberId).subscribe(result => {
         if (result.status === 'success') {
           this.goToEditView();
         } else if (result['error'].message === 'The email already exists') {
          let validators = Array.from(this.emailValidations);
          validators.push(validateEmailValidator(result['error'].message));
          this.clientTeamMemberEmailValidators = Array.from(validators);
        }
       })
    }

    /**
   * dropErrorMessage
   * set again the validators in email
   * @return {void} 
   */
  public dropErrorMessage(): void {
    if (this.clientTeamMemberEmailControl.errors 
      && this.clientTeamMemberEmailControl.errors.hasOwnProperty('EmailRepeat')) {
        this.clientTeamMemberEmailValidators = Array.from(this.emailValidations);
      }
  }
  
    /**
   * goToEditView method
   * go back to the edit project page
   * @return {void}
   */
  public goToEditView() {
    this.router.navigateByUrl(`manage/projects/${this.project.id}/edit`);
  }

}
