import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: string[], filter: string): string[] {
    let filtered: string[] = [];

    if (!filter) {
      return [];
    }

    filtered = list.filter(element => {
      if (element.toLowerCase().search(filter.toLowerCase()) < 0) {
        return false;
      }

      return true;
    });

    return filtered;
  }

}
