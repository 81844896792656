import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-edit-team-member-header',
  templateUrl: './edit-team-member-header.component.html',
  styleUrls: ['./edit-team-member-header.component.scss']
})
export class EditTeamMemberHeaderComponent implements OnInit {

  @Input() username: string;
  @Input() photo: string;
  @Input() projectId: number;

  public photoAlt: string;

  constructor(
    private router: Router,
    private navbarService: NavbarService,
  ) { }

  /**
   * ngOnInit hook
   * set up the alt attribute of the user photo
   */
  ngOnInit() {
    this.photoAlt = `${this.username}'s photo`;
  }

  /**
   * goBack method
   * goes to the previous route
   * @return {void}
   */
  public goBack(): void {
    this.router.navigateByUrl(`manage/projects/${ this.projectId }/edit`);
  }

  /**
   * toggleNavbar method
   * opens the navbar
   * @return {void}
   */
  public toggleNavbar(): void {
    this.navbarService.navbarRef.nativeElement.checked = !this.navbarService.navbarRef.nativeElement.checked;
  }
}
