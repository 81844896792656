import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-team-member-header',
  templateUrl: './add-team-member-header.component.html',
  styleUrls: ['./add-team-member-header.component.scss']
})
export class AddTeamMemberHeaderComponent {
  @Input() projectId: number;

  constructor(private router: Router) { }

  /**
   * goToEditView method
   * go back to the edit project view
   * @return {void}
   */
  public goToEditView(): void {
    this.router.navigateByUrl(`manage/projects/${this.projectId}/edit`);
  }

}
