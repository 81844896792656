import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import { alphaNumberNoEmojisRegex } from 'src/app/config/regex';
import { EnhancedFormControl } from 'src/app/models/enhanced-form-control.model';

@Component({
  selector: 'app-array-input',
  templateUrl: './array-input.component.html',
  styleUrls: ['./array-input.component.scss']
})
export class ArrayInputComponent {

  @ViewChild('inputs', { static: false }) inputs: ElementRef;

  @Input() required: boolean = true;
  @Input() arrayName: string;
  @Input() parentForm: FormGroup;
  @Input() arrayControls: EnhancedFormControl[];
  @Input() labelText: string;
  @Input() arrayErrors;
  @Input() errors;

  @Output() addNewInput: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeNewInput: EventEmitter<number> = new EventEmitter<number>();

  public displayArrayError: boolean = false;
  public formArray: AbstractControl;

  constructor() { }

  ngOnInit() {
    this.formArray = this.parentForm.get(this.arrayName);
  }

  /**
   * checkForErrors method
   * set displayError flag to true if there are errors
   * @param {EnhancedFormControl} control form control to test
   * @returns {void}
   */
  public checkForErrors(control: EnhancedFormControl): void {
    if (this.formArray.errors) {
      this.displayArrayError = true;
    } else {
      this.displayArrayError = false;
    }

    if (control.errors) {
      control.displayError = true;
    } else {
      control.displayError = false;
    }
  }

  /**
   * trimElement method
   * trims the value of the control
   * @param {HTMLInputElement} target HTML Input element to trim
   * @param {EnhancedFormControl} control form control to trim
   * @param {number} index array index for the corresponding control
   * @returns {void}
   */
  public trimElement(target: HTMLInputElement, control: EnhancedFormControl, index: number): void {
    control.setValue(target.value.trim());

    // remove any controls that are empty and not the last of the array
    this.arrayControls.forEach((control, index) => {
      if (!control.value && index !== (this.arrayControls.length - 1)) {
        this.removeInput(control, index);
      }
    });

    this.checkForErrors(control);
  }

  /**
   * manageInputs method
   * emits event to parent form to
   * add a new input when the user starts writing
   * or to remove it if the user deletes its content
   * @param {event} event input event
   * @param {EnhancedFormControl} control form control to check if has value
   * @param {number} index index in the array of current form control
   * @returns {void}
   */
  public manageInputs(event, control: EnhancedFormControl, index: number): void {
    /**
     * add a new input when the user starts
     * typing in the LAST input in the array
     * (backspace counts as input, so it checks it
     * as well)
     */  
    if (
      event.inputType !== 'deleteContentBackward' 
      && control.value.length > 0
      && index === (this.arrayControls.length - 1)
    ) {
      this.addNewInput.emit('addInput');
    }

    // if the control has no value, remove it
    this.removeInput(control, index);
  }

  /**
   * removeInput method
   * if the user deleted the input, we can delete
   * the control, only if the array has still one more
   * element, or it would be left without
   * inputs
   * @param {EnhancedFormControl} control form control to remove
   * @param {number} index index of the form control to remove
   * @return {void}
   */
  private removeInput(control: EnhancedFormControl, index: number): void {
    // no current value in the control
    if (!control.value) {
      if (index === 0 || index === (this.arrayControls.length - 2)) {
        if (!this.arrayControls[index + 1].value) {
          // only remove the sibling control for the first and second to last inputs
          this.removeNewInput.emit(index + 1);
        } else {
          this.removeNewInput.emit(index);
          this.setFocusOnEmptyInput();
        }
      } else {
        this.removeNewInput.emit(index);
        this.setFocusOnEmptyInput();
      }

      if (this.required) {
        this.setAsRequired();
      }
    }
  }

  /**
   * setFocusOnEmptyInput method
   * once an input is removed, check for 
   * empty inputs and focus on it
   * @returns {void}
   */
  private setFocusOnEmptyInput(): void {
    const inputChildren = this.inputs.nativeElement.querySelectorAll('.form-control');

    inputChildren.forEach(input => {
      if (!input.value) {
        input.focus();
      }
    });
  }

  /**
   * setAsRequired method
   * if only one input is left, 
   * set that one as required
   * @return {void}
   */
  private setAsRequired(): void {
    if (this.arrayControls.length === 1) {
      const control = this.arrayControls[0];
      control.setValidators([
        Validators.required, 
        Validators.pattern(alphaNumberNoEmojisRegex),
      ]);
      control.updateValueAndValidity();
    }
  }
}
