import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { ProfileResponse } from 'src/app/models/profile-response.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model';

@Component({
  selector: 'app-teammate',
  templateUrl: './teammate.component.html',
  styleUrls: ['./teammate.component.scss'],
})
export class TeammateComponent implements OnInit {

  public user: UserProfile;
  public userId: number;
  public userLoaded: boolean = false;
  public projectFiltersData: ProjectFiltersData = null;
  public fromRoute: string = null;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  /**
   * ngOnInit hook
   * gets the user id to search from the url
   * @return {void}
   */
  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    
    if (!isNaN(id)) {
      this.userId = id;
      this.getUserData();
    }

    this.fromRoute = history.state.fromRoute;
    this.projectFiltersData = history.state.data;
  }

  /**
   * getUserData method
   * get the users data based on the id
   * @return {void}
   */
  public getUserData(): void {
    this.userService
      .getUser(this.userId)
      .subscribe((response: ProfileResponse) => {
        this.user = response.data;
        this.userLoaded = true;
      });
  }
}
