import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectUser } from 'src/app/models/projects/project-user.model';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user: ProjectUser;
  @Input() projectFiltersData: ProjectFiltersData;

  public userPhotoAlt: string;
  public actualRoute: string = null;

  constructor(private router: Router) { }

  /**
   * ngOnInit hook
   * sets up the alt attribute for the photo
   * of the user
   * @return {void}
   */
  ngOnInit() {
    this.actualRoute = this.router.url;
    if (this.user.firstName) {
      const username = `${this.user.firstName[0].toUpperCase()}${this.user.firstName.slice(1)}`;

      this.userPhotoAlt = `${username}'s photo`;
    }
  }

  /**
   * goToTeammateProfile method
   * navigates to the profile of the selected user
   * @param {number} id id of the user we wish to view 
   * @return {void}
   */
  public goToTeammateProfile(id: number): void {
    const route = this.router.url.split('/');
    this.router.navigate([`${route[1]}/${route[2]}/teammate/${id}`], {
      state: {
        data: this.projectFiltersData,
        fromRoute: this.actualRoute
      }
    });
  }
}
