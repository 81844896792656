import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Locations } from 'src/app/models/sign-up/locations.model';
import { Seniorities } from 'src/app/models/sign-up/seniorities.model';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { Fields } from 'src/app/models/sign-up/fields.model';
import { SignUpService } from 'src/app/services/sign-up.service';
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { ProfileService } from 'src/app/services/profile.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { fileTypeImage } from 'src/app/shared/custom-validators/file-type-image';
import { EmptyResponse } from 'src/app/models/responses/empty-response.model';
import { Subscription, merge, Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { validDate } from 'src/app/shared/custom-validators/date.validator';
import { dateBeforeMin } from 'src/app/shared/custom-validators/date-before-min.validator';
import { dateAfterNow } from 'src/app/shared/custom-validators/date-after-now.validator';
import moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-edit-personal-info',
  templateUrl: './edit-personal-info.component.html',
  styleUrls: ['./edit-personal-info.component.scss']
})
export class EditPersonalInfoComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  //Input from father
  @Input() id: string | number;
  @Input() photo: Observable<string>;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() seniority: string;
  @Input() position: string;
  @Input() field: string;
  @Input() startDate: Date;
  @Input() location: string;
  @Input() region: string;
  @Input() email: string;
  @Input() hasPermissions: boolean;
  @Input() bottomFormsInEdit: boolean;

  @Output() personalInfoInEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public hideEventsSubs: Subscription;
  public showEventsSubs: Subscription;
  public requestInProgress: boolean = false;

  public fields$: SelectorOption[] = [];
  public locations$: SelectorOption[] = [];
  public seniorities$: SelectorOption[] = [];
  public changeView: boolean = true;
  public hasChanged: boolean = false;
  public show: boolean = true;
  public img: string | ArrayBuffer| Observable<string>;
  public isManage: boolean;
  public maxDate: Date = new Date();

  public dateValidators = [
    Validators.required,
    validDate,
    dateBeforeMin(moment.utc('1900', 'YYYY')),
    dateAfterNow
  ];  
  // Controls
  public photoControl = new FormControl('', []);
  public fieldControl = new FormControl('', []);
  public locationControl = new FormControl('', []);
  public seniorityControl = new FormControl('', []);
  public startDateControl = new FormControl('', this.dateValidators);

  // form
  public formEditPersonalInfo: FormGroup = new FormGroup({
    photo: this.photoControl,
    companyField: this.fieldControl,
    companyLocation: this.locationControl,
    companyPosition: new FormControl(
      '', [
        Validators.required,
        Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9.#\s+-]*[A-Za-z][A-Za-zÁÉÍÓÚñáéíóúÑüëÜË0-9.#+-\s]{0,}$/),
      ]),
    companySeniority: this.seniorityControl,
    startDate: this.startDateControl
  });

  constructor(
    private signUpService: SignUpService,
    private profileService: ProfileService,
    private userService: UserService,
    private router: Router,
  ) { }
  
  /**
   * ngOnInit hook
   * initialize locations, seniorities and fields
   */
  ngOnInit(): void {
    this.getLocations();
    this.getSeniorities();
    this.getFields();
    
    // merge the subjects and hide the buttons
    this.hideEventsSubs = merge(
      this.profileService.editExperienceView,
      this.profileService.addExperienceView,
      this.profileService.editSummaryView,
      this.profileService.addSkillView,
    ).subscribe(() => this.bottomFormsInEdit = true);
    
    // show the buttons
    this.showEventsSubs = this.profileService.showButtons
      .subscribe(() => this.bottomFormsInEdit = false);

    this.isManage = this.router.url.split('/')[1] === 'manage'
    if(!this.isManage) { 
      this.formEditPersonalInfo.removeControl('startDate');
    }
    this.changedFormValue();
  }

  /**
   * changedFormValue method
   * know if any changes of formEditPersonalInfo has changed
   * @returns {void}
   */
  private changedFormValue(): void {
    let startDate = null;
    this.formEditPersonalInfo.valueChanges.subscribe((element) => {
      if(this.isManage) { 
        startDate = moment.utc(this.startDate).format('MM/YYYY');
      }
      if (
        element.companyField !== this.defaultField().id ||
        element.companyLocation !== this.defaultLocation().id ||
        element.companyPosition.trim() !== this.position ||
        element.companySeniority !== this.defaultSeniority().id ||
        element.startDate !== startDate && this.isManage  ||
        this.img !== this.photo
      ) {
        this.hasChanged = true;
      } else {
        this.hasChanged = false;
      }
    });
  }

  /**
   * submitEditProfile method
   * submit form edit profile
   * @returns {void}
   */
  public submitEditProfile(): void {
    const personalData = this.formEditPersonalInfo.value;
    this.blockUI.start();
    if(this.isManage) {
      personalData.startDate = moment.utc(this.startDateControl.value, ['MM/YYYY']).format('YYYY-MM');
    }
    this.hasChanged = false;
    this.requestInProgress = true;
    this.userService.putEditPersonalInfo(
      this.id,
      personalData
    ).subscribe((response: EmptyResponse) => {
      this.profileService.editPersonalUpdated.next();
      this.showButtons();
      this.hideInformation();
      this.hasChanged = true;
      this.requestInProgress = false;
      this.blockUI.stop();
    });
  }

  /**
   * isDefaultImage method
   * returns true if the start of the img variable is assets
   * @returns {boolean}
   */
  public isDefaultImage(): boolean {
    return String(this.img).startsWith('assets');
  }

  /**
   * defaultLocation method
   * returns the id and name of the users location
   * @returns {SelectorOption} object with id and name 
   */
  public defaultLocation(): SelectorOption {
    return this.locations$.find(
      element => element.name === this.location
    );
  }

  /**
   * defaultField method
   * returns the id and name of the users field
   * @returns {SelectorOption} object with id and name 
   */
  public defaultField(): SelectorOption {
    return this.fields$.find(
      element => element.name === this.field
    );
  }

  /**
   * defaultSeniority method
   * returns the id and name of the users seniority
   * @returns {SelectorOption} object with id and name 
   */
  public defaultSeniority(): SelectorOption {
    return this.seniorities$.find(
      element => element.name === this.seniority
    );
  }

  /**
   * hideInformation method
   * hide personal info and show form to edit
   * @returns {void}
   */
  public hideInformation(): void {
    this.changeView = !this.changeView;
    if (!this.changeView) {
      this.formEditPersonalInfo.controls.photo.setValidators(null);
      this.formEditPersonalInfo.controls.photo.patchValue(this.photo);
      this.img = this.photo;
      this.formEditPersonalInfo.controls.companyField.setValue(this.defaultField().id);
      this.formEditPersonalInfo.controls.companyLocation.setValue(this.defaultLocation().id);
      this.formEditPersonalInfo.controls.companyPosition.setValue(this.position);
      this.formEditPersonalInfo.controls.companySeniority.setValue(this.defaultSeniority().id);
      if(this.isManage) { 
        this.formEditPersonalInfo.controls.startDate.setValue(this.startDate);
      }
    }
  }

  /**
   * hideButtons method
   * send event emiter for hide other buttons in the profile
   * @returns {void}
   */
  public hideButtons(): void {
    this.profileService.editPersonalView.next();
    this.personalInfoInEdit.emit(true);
  }

  /**
   * showButtons method
   * emit an event that shows the other profile buttons
   * @returns {void}
   */
  public showButtons(): void {
    this.profileService.showButtons.next();
    this.personalInfoInEdit.emit(false);
  }

  /** 
   * getFields method
   * call get endpoint for fiedls, asign data to variable
   * @return {void}
   */
  private getFields(): void {
    this.signUpService
      .getSignUpGeneric('/company/fields')
      .subscribe((data: Response) => {
        this.fields$ = data.data.map((field: Fields) => {
          return {
            id: field.id,
            name: field.name,
          };
        });
      });
  }

  /**
   * getLocations method
   * call get endpoint for locations, asign data to variable
   * @return {void}
   */
  private getLocations(): void {
    this.signUpService
      .getSignUpGeneric('/company/locations')
      .subscribe((data: Response) => {
        this.locations$ = data.data.map((location: Locations) => {
          return {
            id: location.id,
            name: location.name,
          };
        })
      });
  }

  /** 
   * getSeniorities method
   * call get endpoint for senioritis, asign data to variable 
   * @return {void}
  */
  private getSeniorities(): void {
    this.signUpService
      .getSignUpGeneric('/company/seniorities')
      .subscribe((data: Response) => {
        this.seniorities$ = data.data.map((seniority: Seniorities) => {
          return {
            id: seniority.id,
            name: seniority.name,
          };
        });
      });
  }

  /**
   * readImage method
   * render image after select
   * @param image content of input type file
   */
  public readImage(image): void {
    if (image.files && image.files[0]) {
      this.getMagicNumber(image.files[0]);
      let reader: FileReader = new FileReader();
      reader.onload = _event => {
        this.img = reader.result;
        this.formEditPersonalInfo.controls.photo.setValue(this.img);
      };
      reader.readAsDataURL(image.files[0]);
    }
  }

  /** 
   * getMagicNumber method
   * read the image buffer to see if it
   * is actually an image
   * @param {Blob} data contain image
   * @return {void} 
  */
  public getMagicNumber(data: Blob): void {
    this.formEditPersonalInfo.controls.photo.setValidators(null);
    let header = '';
    const file = data;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = ((event: ProgressEvent)  => {
      const bytes = new Uint8Array(event.target['result']).subarray(0, 4);

      for (let i = 0; i < bytes.length; i++) {
        header += bytes[i].toString(16).toUpperCase();
      }
      this.formEditPersonalInfo.controls.photo.setValidators([fileTypeImage(header)]);
      this.formEditPersonalInfo.controls.photo.updateValueAndValidity();
    });
  }

  public trimElement(element): void {
    element.value = element.value.trim();
  }

  /**
   * ngOnDestroy hook
   * unsubscribes from all subjects
   * @return {void}
   */
  ngOnDestroy() {
    this.hideEventsSubs.unsubscribe();
    this.showEventsSubs.unsubscribe();
  }
}
