import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit,
  SimpleChanges,
} from "@angular/core";
import { timesheetErrors } from "src/app/config/error-messages";
import { TimesheetDescription } from "src/app/models/timesheet-description.model";
import { UserProject } from "src/app/models/user-projects.model";
import { alphaNumberNoEmojisRegex } from "src/app/config/regex";
import * as uuid from "uuid";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
} from "@angular/forms";
import { DeleteDescription } from "src/app/models/delete-description.model";

@Component({
  selector: "app-edit-timesheeet-description",
  templateUrl: "./edit-timesheeet-description.component.html",
  styleUrls: ["./edit-timesheeet-description.component.scss"],
})
export class EditTimesheeetDescriptionComponent
  implements OnInit, AfterViewInit
{
  @Input() projects: UserProject[];
  @Input() description: TimesheetDescription;
  @Input() descriptions: TimesheetDescription[] = [this.description];
  @Input() isActive: boolean;
  @Input() isFreeDay: boolean;
  @Input() focused: boolean;
  @Input() isFirstDescription: boolean;
  @Input() isToday: boolean;
  @Input() timesheetEditing: boolean;

  @Output() descriptionForm: FormGroup;
  @Output() emitEvent: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() emitDeleteEvent: EventEmitter<DeleteDescription> =
    new EventEmitter<DeleteDescription>();
  @Output() emitHoursEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formInFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goToPrevious: EventEmitter<string> = new EventEmitter<string>();

  public idControl: FormControl = null;
  public projectControl: FormControl = null;
  public hoursControl: FormControl = null;
  public taskControl: FormControl = null;
  public happyControl: FormControl = null;
  public notesControl: FormControl = null;
  public selectedProject: UserProject = null;
  public showInputFaces = false;
  public displayHoursErrors: boolean = false;
  public inputId: string = this.setInputId();
  public focusSelector: boolean = false;
  public keyboardFocus: boolean = true;

  //Validators
  public emptyValidators: Validators[] = [];
  public projectValidators: Validators = [
    Validators.required,
    Validators.min(1),
  ];

  public taskValidators: Validators = [
    Validators.required,
    Validators.pattern(alphaNumberNoEmojisRegex),
  ];

  public hoursValidators: ValidatorFn[] = [
    Validators.min(1),
    Validators.max(24),
    Validators.required,
    Validators.pattern(/^[0-9]*$/),
  ];

  public notesValidators: ValidatorFn[] = [
    Validators.pattern(alphaNumberNoEmojisRegex),
  ];

  // Errors
  public taskError = timesheetErrors.taskError;
  public projectError = timesheetErrors.projectError;
  public dedicatedHoursError = timesheetErrors.dedicatedHoursError;
  public notesError = timesheetErrors.notesError;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * ngOnInit hook
   * @description Initialize fomControls
   */
  ngOnInit() {
    // if (this.isFreeDay) {
    //   this.notesValidators.push(Validators.required);
    // }

    this.hoursControl = new FormControl(
      this.description.dedicatedHours,
      this.hoursValidators
    );

    this.taskControl = new FormControl(
      this.description.task,
      this.taskValidators
    );

    this.happyControl = new FormControl(
      this.description.isHappy,
      Validators.required
    );

    this.notesControl = new FormControl(
      this.description.note,
      this.notesValidators
    );

    this.projectControl = new FormControl(
      this.description.projectId,
      this.projectValidators
    );

    this.idControl = new FormControl(this.description.id);

    this.descriptionForm = new FormGroup({
      id: this.idControl,
      projectId: this.projectControl,
      dedicatedHours: this.hoursControl,
      task: this.taskControl,
      isHappy: this.happyControl,
      note: this.notesControl,
    });

    this.descriptionForm.controls["projectId"].valueChanges.subscribe(
      (value) => {
        let projectId = value;
        if (value && value.id) {
          projectId = value.id;
        }
        this.description.projectId = projectId;
        if (projectId) {
          localStorage.setItem("projectId", projectId);
        }

        if (this.description.projectId) {
          this.selectedProject = this.projects.find(
            (project) => project.id === projectId
          );

          if (this.selectedProject) {
            this.description.projectName = this.selectedProject.name;
          }
        }
      }
    );

    if (this.projects.length === 1) {
      this.projectControl.setValue(this.projects[0].id);
    }

    this.emitEvent.emit(this.descriptionForm);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("isActive") &&
      changes.isActive.currentValue === true &&
      localStorage.getItem("projectId") && !this.timesheetEditing
    ) {
      this.description.projectId = parseInt(localStorage.getItem("projectId"));
    }
  }

  /**
   * ngAfterViewInit hook
   * @description Check the descriptionForm values
   * and emits an event with the invalidity of the form
   */
  ngAfterViewInit() {
    this.descriptionForm.valueChanges.subscribe(() => {
      this.onError.emit(this.descriptionForm.invalid);
    });
  }

  /**
   * sumHours method
   * @description Sum the hours worked in each project
   * @returns {number} number
   */
  public sumHours(): number {
    const hours = this.descriptions.reduce(
      (sum, description) => sum + (description["dedicatedHours"] || 0),
      0
    );

    if (!this.isFreeDay && hours !== 8) {
      this.notesControl.setValidators([
        ...this.notesValidators,
      ]);
    } else {
      this.notesControl.setValidators(this.notesValidators);
    }

    this.notesControl.updateValueAndValidity();
    this.descriptionForm.updateValueAndValidity();
    return hours;
  }

  /**
   * setInputId method
   * @description Call uuid library to return new unique id
   * @returns {string} string - new uuid
   */
  public setInputId(): string {
    return uuid.v4();
  }

  /**
   * disableAdd method
   * @description Verify if all descriptions have valid values
   * @returns {boolean} boolean - true if there are errors, false if there aren't
   */
  public disableAdd(): boolean {
    const isFormInvalid: boolean = !!this.descriptions.find(
      (description) =>
        !description.projectId ||
        !description.task ||
        !(description.dedicatedHours >= 1 && description.dedicatedHours <= 24)
    );

    return this.sumHours() >= 24 || !this.projects || isFormInvalid;
  }

  /**
   * addDescription method
   * @description Add a new TimesheetDescription
   * object to descriptions array
   * @returns {void} void
   */
  public addDescription(): void {
    //Check if value exist in localStorage and set it as default if not put the first one
    const selectedProject = this.getProjectDefault();
    this.descriptions.push({
      projectId: selectedProject.id,
      dedicatedHours: this.sumHours() < 8 ? 8 - this.sumHours() : 1,
      task: "",
      isHappy: true,
      note: "",
    });

    this.changeDetectorRef.detectChanges();
  }

  /**
   * deleteDescription method
   * @description Delete a description of
   * descriptions array
   * @returns {void} void
   */
  public deleteDescription(): void {
    let totalHours = null;
    const indexDeleted: number = this.descriptions.indexOf(this.description);

    this.descriptions.splice(indexDeleted, 1);
    totalHours = this.sumHours();
    this.emitDeleteEvent.emit({
      indexDeleted: indexDeleted,
      totalHours: totalHours,
    });
  }

  /**
   * displayInputFaces method
   * @description Shows the emotion selector
   * @returns {void} void
   */
  public displayInputFaces(): void {
    this.showInputFaces = true;
  }

  /**
   * selectHappy method
   * @description Define isHappy property as true
   * and hide emotion selector
   * @returns {void} void
   */
  public selectHappy(): void {
    this.description.isHappy = true;
    this.showInputFaces = false;
  }

  /**
   * selectSad method
   * @description Define isHappy property as false
   * and hide emotion selector
   * @returns {void} void
   */
  public selectSad(): void {
    this.description.isHappy = false;
    this.showInputFaces = false;
  }

  /**
   * UpdateTask method
   * @description Updates the value of task property
   * @returns {void} void
   */
  public updateTask(): void {
    this.description.task = this.descriptionForm.get("task").value.trim();
  }

  /**
   * UpdateNotes method
   * @description Updates the value of note property
   * @returns {void}
   */
  public updateNotes(): void {
    this.description.note = this.descriptionForm.get("note").value.trim();
    this.emitHoursEvent.emit(this.sumHours());
  }

  /**
   * getProjectDefault method
   * @description Defines project default in selector
   * @returns {UserProject} UserProject - current project or first record
   */
  public getProjectDefault(): UserProject {
    let projectDefault;

    // Ensure projects array is defined and not empty
    if (!this.projects || this.projects.length === 0) {
      return null;
    }

    if (this.description.projectId) {
      projectDefault = this.projects.find(
        (project) => project.id === this.description.projectId
      );
    } else if (localStorage.getItem("projectId")) {
      projectDefault = this.projects.find(
        (project) => project.id === parseInt(localStorage.getItem("projectId"))
      );
    } else {
      projectDefault = this.projects[0];
    }

    return projectDefault;
  }

  /**
   * checkHoursErrors method
   * @description Set displayErrors flag to true if there are errors
   * @returns {void} void
   */
  public checkHoursErrors(): void {
    if (this.hoursControl.errors) {
      this.displayHoursErrors = true;
    } else {
      this.displayHoursErrors = false;
    }

    this.setFormInFocus(false);
  }

  /**
   * preventPasting method
   * @description Prevents the pasting of values in certain inputs
   * @param {ClipboardEvent} event - event triggered
   * @returns {void} void
   */
  public preventPasting(event: ClipboardEvent): void {
    const copiedData = event.clipboardData.getData("Text");

    // do not allow anything other than numbers
    if (isNaN(+copiedData)) {
      event.preventDefault();
    }
  }

  /**
   * validateNumberInput method
   * @description Validate that the hours input
   * admit only numeric charaters
   * @returns {void} void
   */
  public validateNumberInput(): void {
    const value = this.descriptionForm.get("dedicatedHours").value;

    if (!value.match("^[0-9]*$")) {
      this.descriptionForm
        .get("dedicatedHours")
        .setValue(value.slice(0, value.length - 1));
    }

    this.description.dedicatedHours =
      +this.descriptionForm.get("dedicatedHours").value;

    this.emitHoursEvent.emit(this.sumHours());
  }

  /**
   * setFormInFocus method
   * @description Recives a boolean value to emit an event
   * to parent component with this value to know if the form
   * is in focus or not
   * @param {boolean} value - Received value
   * (true in focus, false in blur)
   * @returns {void} void
   */
  public setFormInFocus(event: boolean) {
    this.formInFocus.emit(event);
  }

  /**
   * canHaveTrashCan method
   * @description Check if there is more than
   * one description to show the trashcan icon or not
   * @returns {boolean} boolean
   */
  public canHaveTrashcan(): boolean {
    return this.descriptions && this.descriptions.length > 1;
  }

  /**
   * isLastDescription method
   * @description Check if is the last description in the FormArray
   * @returns {boolean} boolean
   */
  public isLastDescription(): boolean {
    return (
      this.descriptions.indexOf(this.description) ===
      this.descriptions.length - 1
    );
  }

  /**
   * changeToPrevFromSelector method
   * @description Emit an event to change to previous workday
   * if Shift + Tab keys were pressed in selector
   * @param {KeyboardEvent} event - Shift + Tab keys pressed event to prevent
   * its default action
   * @returns {void} void
   */
  public changeToPrevFromSelector(event: KeyboardEvent): void {
    if (this.isFirstDescription && this.projects.length > 1) {
      event.preventDefault();
      this.goToPrevious.emit("prev");
    }
  }

  /**
   * changeToPrevFromHoursInput method
   * @description Emit an event to change to previous workday
   * if Shift + Tab keys were pressed in hours input
   * @param {KeyboardEvent} event - Shift + Tab keys pressed event to prevent
   * its default action
   * @returns {void} void
   */
  public changeToPrevFromHoursInput(event: KeyboardEvent): void {
    if (this.isFirstDescription && this.projects.length === 1) {
      event.preventDefault();
      this.goToPrevious.emit("prev");
    }
  }

  /**
   * focusFaceSelector method
   * @description Sets a variable value to focus the faces selector
   * @param {boolean} value - Value to set in the variable
   * @returns {void} void
   */
  public focusFaceSelector(value: boolean): void {
    this.focusSelector = value;
  }

  /**
   * setKeyboardFocus method
   * @description Set the variable to show outline property
   * when it is needed
   * @param {boolean} value - Value to set in the variable
   * @returns {void} void
   */
  public setKeyboardFocus(value: boolean): void {
    this.keyboardFocus = value;
  }
}
