import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(words: string[]) {
    let sentence: string = '';
    words.forEach((word, index) => {
      sentence += index !== 0 ? ` ${word}` : word;
    });
    return sentence;
  }
}
