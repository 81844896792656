import { Component, Output, EventEmitter } from '@angular/core';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';

@Component({
  selector: 'app-team-list-header',
  templateUrl: './team-list-header.component.html',
  styleUrls: ['./team-list-header.component.scss']
})
export class TeamListHeaderComponent {

  @Output() locationChange: EventEmitter<UserLocationFilter> = new EventEmitter<UserLocationFilter>();
  @Output() search: EventEmitter<HTMLInputElement> = new EventEmitter<HTMLInputElement>();

  constructor() { }

  /**
   * filterUsers method
   * @description Emit an event to its parent component
   * when changes the value in location selector
   * @param {UserLocationFilter} filter - new filter
   * to apply in users lists
   * @returns {void} void
   */
  public filterUsers(filter: UserLocationFilter): void {
    this.locationChange.emit(filter);
  }

  /**
   * onInput method
   * send parameter input to team-list component
   * @param {HTMLInputElement} input input element
   * @return {void}
   */
  public onInput(input: HTMLInputElement): void {
    this.search.emit(input);
  }
}
