import { Injectable, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';

import { ProfileService } from 'src/app/services/profile.service';
import { validDate } from 'src/app/shared/custom-validators/date.validator';
import { dateAfterNow } from 'src/app/shared/custom-validators/date-after-now.validator';
import { dateBeforeMin } from 'src/app/shared/custom-validators/date-before-min.validator';
import { validateArray } from 'src/app/shared/custom-validators/array.validator';
import { CitiesResponse } from 'src/app/models/responses/cities-response.model';
import { UserExperience } from 'src/app/models/profile/user-experience.model';
import { addExperienceErrors } from 'src/app/config/error-messages';
import { alphaNumberNoEmojisRegex, alphabeticNoEmojisRegex } from 'src/app/config/regex';

import moment, { Moment } from 'moment';

@Injectable()
export class UserExperienceService {

  public userId: number;
  public experienceForm: FormGroup;
  public firstResponsabilityCreated: boolean = false;
  public minDate: Moment = null;
  public maxDate: Moment = null;
  public currentDate: Moment = null;
  public locations: CitiesResponse[] = [];
  public fromDateError: boolean = false;
  public toDateError: boolean = false;

  // form controls
  public userIdControl: FormControl;
  public positionControl: FormControl;
  public companyControl: FormControl;
  public locationControl: FormControl;
  public fromDateControl: FormControl;
  public toDateControl: FormControl;
  public responsibilityArrayControl: FormArray;

  // form validators
  public positionValidators: Validators[];
  public companyValidators: Validators[];
  public locationValidators: Validators[];
  public fromDateValidators: Validators[];
  public toDateValidators: Validators[];
  public responsibilityValidators: Validators[];

  // form errors
  public positionErrors;
  public companyErrors;
  public locationErrors;
  public fromDateErrors;
  public toDateErrors;
  public responsibilitiesErrors;
  public responsibilityErrors;

  constructor(
    private profileService: ProfileService,
    private changeDetector: ChangeDetectorRef
  ) {}

  /**
   * setUpForm method
   * prepares the form controls with their validators
   * and errors before adding them to the form group,
   * it also subscribes to changes in the month inputs
   * to set the minimum and maximum values for them
   * @param {number} userId - User to set the form
   * @return {void}
   */
  public setUpForm(userId: number): FormGroup {
    // set the initial values of the minimum and maximum dates to now
    this.minDate = moment();
    this.maxDate = moment();

    // validate if the id is a number
    userId = isNaN(userId) ? 0 : userId;

    this.userIdControl = new FormControl(userId);
    this.positionControl = new FormControl(' ');
    this.companyControl = new FormControl(null);
    this.locationControl = new FormControl(null);
    this.fromDateControl = new FormControl(null);
    this.toDateControl = new FormControl(null);
    this.responsibilityArrayControl = new FormArray(
      [this.createResponsibilityControl()],
      validateArray
    );

    this.positionValidators = [
      Validators.required,
      Validators.pattern(alphaNumberNoEmojisRegex),
    ];
    this.companyValidators = [
      Validators.required,
      Validators.pattern(alphaNumberNoEmojisRegex),
    ];
    this.locationValidators = [
      Validators.required,
      Validators.pattern(alphabeticNoEmojisRegex),
    ];
    this.fromDateValidators = [
      Validators.required,
      validDate,
      dateAfterNow,
      dateBeforeMin(moment('1900', 'YYYY')),
    ];
    this.toDateValidators = [
      Validators.required,
      validDate,
      dateAfterNow,
      dateBeforeMin(this.minDate, 'month'),
    ];

    this.positionErrors = addExperienceErrors.position;
    this.companyErrors = addExperienceErrors.company;
    this.locationErrors = addExperienceErrors.location;
    this.fromDateErrors = addExperienceErrors.fromDate;
    this.toDateErrors = addExperienceErrors.toDate;
    this.responsibilitiesErrors = addExperienceErrors.responsibilities;
    this.responsibilityErrors = addExperienceErrors.responsibility;

    // this will not be modified
    this.currentDate = moment();

    this.handleDateChanges();

    return new FormGroup({
      'userId': this.userIdControl,
      'position': this.positionControl,
      'company': this.companyControl,
      'location': this.locationControl,
      'fromDate': this.fromDateControl,
      'toDate': this.toDateControl,
      'responsibilities': this.responsibilityArrayControl,
    });
  }

  /**
   * handleDateChanges method
   * subscribes to the value changes that the form controls
   * for both dates can suffer
   * @return {void}
   */
  private handleDateChanges(): void {
    /**
     * the reason we have to subtract one from the month is because
     * the moment package indexes the months starting from 0
     */
    this.fromDateControl.valueChanges.subscribe((value) => {
      if (value) {
        this.minDate = this.getNewMinOrMaxDate(value);
      }
    });

    this.toDateControl.valueChanges.subscribe((value) => {
      if (value) {
        this.maxDate = this.getNewMinOrMaxDate(value);
      }
    });
  }

  /**
   * monthErrorHandler method
   * sets a flag that adds a class to the date containers to
   * add more margin once an error is thrown, or remove it if
   * it is solved
   * @param {string} name name of the month input with the error
   * @param {boolean} solved flag to detect if the event was because
   * an error came up or it was solved
   * @return {void}
   */
  public monthErrorHandler(name: string, solved: boolean = false): void {
    if (solved && name === 'fromDate') {
      this.fromDateError = false;
    } else if (solved && name === 'toDate') {
      this.toDateError = false;
    } else if (!solved && name === 'fromDate') {
      this.fromDateError = true;
    } else {
      this.toDateError = true;
    }
  }

  /**
   * getNewMinOrMaxDate method
   * obtains the new value the form control for the dates took
   * and converts it to a Moment object to be set as either the
   * new minimum or maximum date
   * @param {string} value value of the form control
   * @return {Moment}
   */
  private getNewMinOrMaxDate(value: string): Moment {
    const date = value.split('/');
    return moment([+date[1], (+date[0] - 1)]);
  }

  /**
   * populateFields method
   * populates fields with the provided data
   * @return {void}
   */
  public populateFields(experience: UserExperience, experienceForm: FormGroup): void {
    this.positionControl.setValue(experience.position);
    this.companyControl.setValue(experience.company);
    this.fromDateControl.setValue(experience.fromDate);
    this.toDateControl.setValue(experience.toDate);
    this.locationControl.setValue(experience.location);

    const responsibilitiesArray = (experienceForm.get('responsibilities') as FormArray).controls;

    /**
     * get the difference in length between form array
     * and the experience responsibilities
     */
    const difference = experience.responsibilities.length - responsibilitiesArray.length;

    // add a new field for each missing responsibility plus one more
    for (let i = 0; i < difference; i += 1) {
      this.addResponsibility();
    }

    this.responsibilityArrayControl.setValue(experience.responsibilities);

    // add one more field to enable the user to add more responsibilities
    this.addResponsibility();
  }

  /**
   * createResponsibilityControl method
   * creates a new form control for each
   * responsibility input
   * @return {FormControl} form control to be added to the array
   */
  private createResponsibilityControl(): FormControl {
    if (!this.firstResponsabilityCreated) {
      this.firstResponsabilityCreated = true;
      return new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern(alphaNumberNoEmojisRegex),
        ]
      );
    } else {
      return new FormControl(null, Validators.pattern(alphaNumberNoEmojisRegex));
    }
  }

  /**
   * getCities method
   * invokes the service method with the same name
   * to get the array of cities that match with the
   * query string provided by the user.
   * The query is done after the user types 3 letters
   * because that's the way the API works
   * @param {string} query city name to search
   * @return {void}
   */
  public getCities(query: string): void {
    this.profileService.getCities(query).subscribe(cities => {
      this.locations = cities;
    });
  }

  /**
   * addResponsibility method
   * creates a new responsibility input
   * and pushes it into the responsibilities
   * array
   * @return {void}
   */
  public addResponsibility(): void {
    const control = this.createResponsibilityControl();

    (this.responsibilityArrayControl as FormArray).push(control);
  }

  /**
   * removeResponsibility method
   * removes the last input in the form array
   * @return {void}
   */
  public removeResponsibility(index: number): void {
    this.responsibilityArrayControl.controls.splice(index, 1);
    this.changeDetector.detectChanges();
  }

  /**
   * arrayControls getter
   * obtains each of the controls in the form array
   * to be able to iterate over them
   * @return {AbstractControl[]} array of controls
   */
  public arrayControls(experienceForm: FormGroup): AbstractControl[] {
    return (experienceForm.get('responsibilities') as FormArray).controls;
  }

  /**
   * checkArrayErrors method
   * Check editor content, changing tags from array editors
   * for empty or space
   * @param {string[]} array values from WYSIWIG editors
   * @returns {boolean} return true if someone editor has characters
   */
  public checkArrayErrors(responsabilityArray: string[]): boolean {
    let content: string[] = [];
    
    content = this.arrayWithoutHtmlTags(responsabilityArray);

    return new Set (content).size !== content.length;
  }

  /**
   * arrayWithoutHtmlTags method
   * @description method to get array and return without html tags
   * @param {string[]} array
   * @returns {string[]} array without HTML tags
   */
  public arrayWithoutHtmlTags(array: string[]): string[] {
    return array.map((item) => {
      return this.deleteHtmlTags(item);
    });
  }

  /**
   * deleteHtmlTags method
   * @description remove the HTML tags from value
   * @param {string} value - string with HTML tags
   * @returns {string} the string without HTML tags
   */
  public deleteHtmlTags(value: string): string {
    if (value) {
      value = value.replace(/<p>/gm, '');
      value = value.replace(/<\/p>/gm, '');
      value = value.replace(/&nbsp;/gm, ' ');
      value = value.trim();
    }
    return value;
  }
}
