import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { setNewPasswordErrors } from 'src/app/config/error-messages';
import { ForgotpasswordService } from 'src/app/services/forgot-password.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Response } from 'src/app/models/response.model';
import { passwordRegex } from 'src/app/config/regex';
import { Error } from 'src/app/models/errors/error.model';
import { version } from 'package.json';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
  providers: [ForgotpasswordService],
})
export class SetNewPasswordComponent implements OnInit {

  constructor(
    public forgotPasswordService: ForgotpasswordService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  public version: string = `Version: ${version}`;
  public timerView: boolean = false;
  public showAlert: boolean = false;
  public alertText: string = 'Passwords Do Not Match.';
  public disableRestartButton: boolean = false;
  public hash: string;
  public dirtyPassword: boolean = false;
  
  // form controls
  public passwordControl = new FormControl('', []);
  public confirmPasswordControl = new FormControl('', []);

  // error messages
  public passwordErrors = setNewPasswordErrors.passwordErrors;
  public confirmPasswordErrors = setNewPasswordErrors.confirmPasswordErrors;

  // input validators
  public passwordValidators: Validators[] = [
    Validators.required,
    Validators.pattern(passwordRegex),
  ];

  public confirmPasswordValidators: Validators[] = [
    Validators.required,
  ];

  // reset password form group
  public resetPasswordForm: FormGroup = new FormGroup ({
    password: this.passwordControl,
    confirmPassword: this.confirmPasswordControl,
  });

  /**
   * resetPassword method
   * validate that the passwords match and consume the forgotPasswordService
   * to set the new password
   * @returns {void}
   */
  public resetPassword(): void {
    if (this.passwordControl.value === this.confirmPasswordControl.value) {
      this.disableRestartButton = true;
      this.forgotPasswordService.setNewPassword(
        this.hash,
        this.passwordControl.value,
      ).subscribe(
        (response: Response) => {
          this.disableRestartButton = false;
          if (response.status === 'success') {
            this.timerView = true;
          } else  if (response.message === 'not valid token') {
            this.alertText = 'Invalid Password Reset Link.';
            this.showAlert = true;
          } else {
            this.alertText = 'Something Went Wrong.';
            this.showAlert = true;
          }
        }
      )
    } else {
      this.alertText = 'Passwords Do Not Match.';
      this.showAlert = true;
    }
  }

  /**
   * When the page is started put the input as password and the icon of
   * visibility off and get the token from the path
   * @returns {void}
   */
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params: Params) => { this.hash = params.hash; },
    );

    this.checkUrlAvailability();
  }

  /**
   * readErrors method
   * verify errors from password
   * @param {Error} event control.errors from input password
   * @returns {void}
   */
  public readErrors(event: Error): void {
    if (event && event.pattern) {
      this.dirtyPassword = true;
    } else {
      this.dirtyPassword = false;
    }
  }

  /**
   * checkUrlAvailability method
   * @description Use the getUrlAvailability method from forgotPasswordService
   * to know if the url with the given hash is available to use
   * @returns {void}
   */
  private checkUrlAvailability() {
    this.forgotPasswordService.getUrlAvailability(this.hash)
      .subscribe(
        (response: Response) => {
          if (response.status === 'error') {
            this.router.navigate(['reset-password'], {
              state: {
                errorMessage: [
                  'Your link has expired.',
                  'Please ask for another one.',
                ]
              }
            });
          } else if (response.status !== 'success') {
            this.router.navigate(['reset-password'], {
              state: {
                errorMessage: [
                  'Something went wrong.',
                  'Please send your email again.',
                ]
              }
            });
          }
        }
      );
  }
}
