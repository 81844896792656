import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

import { Observable, of, from } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RegionLocationsResponse } from 'src/app/models/region.model';
import { environment } from 'src/environments/environment';
import { RegionToReportResponse } from 'src/app/models/reports/region-to-report-response.model';
import { RegionToReport } from 'src/app/models/reports/region-to-report.model';
import { CompleteDataToWorkingHoursReport } from 'src/app/models/reports/complete-data-to-working-hours-report.model';
import { ParamsReport } from 'src/app/models/reports/params-report.model';
import { LocationToReport } from 'src/app/models/reports/location-to-report.model';
import { UserToReport } from 'src/app/models/reports/user-to-report.model';
import { CompleteReportResponse } from 'src/app/models/reports/complete-report-response.model';

@Injectable({
  providedIn: 'root',
})
export class WorkingHoursReportService {

  constructor (private http: HttpClient) { }


  /**
   * getRegions method
   * @description obtain the all the regions from the database
   * @returns {Observable<RegionLocationsResponse>}
   */
  public getRegions(): Observable<RegionLocationsResponse> {
    return this.http.get<RegionLocationsResponse>(
      `${environment.apiUrl}/company/regions`,
    ).pipe(
      catchError((error) => of(error))
    );
  }

  /**
   * getWorkingHours method
   * @description obtain the report of the working hours
   * by a time range and location
   * @param {string} startDate start of time range
   * @param {string} endDate end of time range
   * @param {number} region id of the region to search
   * @param {number} location id of the location to search
   * @returns {Observable<RegionToReportResponse>}
   * Observable<RegionToReportResponse>
   */
  public getWorkingHours(
    startDate: string,
    endDate: string,
    region: number = null,
    location: number = null,
    user: number = null,
    project: number = null
  ): Observable<RegionToReportResponse> {
    const queryParams = {
      params: new HttpParams()
        .set('startDate', startDate)
        .set('endDate', endDate)
    };

    if ((region && location) || location) {
      queryParams.params = queryParams.params.set('segment', 'location');
      queryParams.params = queryParams.params.set('segmentId', location.toString());
    } else if (region) {
      queryParams.params = queryParams.params.set('segment', 'region');
      queryParams.params = queryParams.params.set('segmentId', region.toString());
    }

    if(user){
      queryParams.params = queryParams.params.set('segment', 'user');
      queryParams.params = queryParams.params.set('segmentId', user.toString());
    } else if (project) {
      queryParams.params = queryParams.params.set('segment', 'project');
      queryParams.params = queryParams.params.set('segmentId', project.toString());
    }

    return this.http.get<RegionToReport>(
      `${environment.apiUrl}/reports/working-hours`,
      queryParams
    ).pipe(
      catchError((error) => of(error))
    );
  }

  /**
   * openReport method
   * @description open the report view in a new tab
   * @param {CompleteDataToWorkingHoursReport} data - data to load the report
   * @returns {void} void
   */
  public openReport(data: CompleteDataToWorkingHoursReport): void {
    const params: ParamsReport = {
      startDate: data.period.startDate,
      endDate: data.period.endDate,
      periodType: data.periodType,
      periodName: data.period.selectorOption.name,
      descriptions: data.descriptions,
    };

    params.reportType = data.reportType;
    params.segment = data.segment;
    params.segmentId = data.segmentId;

    // set the region if exists
    if (data.regionId) {
      params.region = data.regionId;
      params.regionName = data.title;
    }

    // set the location if exists
    if (data.locationId) {
      params.location = data.locationId;
      params.locationName = data.title;
    }

    if (data.user) {
      params.userId = data.userId;
      params.userName = data.user;
    }

    if (data.project) {
      params.projectId = data.projectId;
      params.projectName = data.project;
    }

    let url =`/reports/working-hours?`;

    Object.keys(params).forEach((key) => {
      url += `${key}=${params[key]}&`;
    });
    window.open(url.substring(0, url.length-1));
  }

  /**
   * calculateHours method
   * @description calculate the totals hours for the report
   * @param {CompleteDataToWorkingHoursReport} data - data to calculate the
   * totals hours
   * @returns {void} void
   */
  public calculateHours(data: CompleteDataToWorkingHoursReport): void {
    if (data) {
      data.totalHours = 0;
      if (data.regions) {
        data.totalHours = data.regions.reduce((accumulator, region) => {
          region.totalHours = 0;
          if (region.locations) {
            region.totalHours = region.locations.reduce((accumulator, location) => {
              location.totalHours = 0;
              if (location.users) {
                (location as LocationToReport).totalHours = (location.users as UserToReport[]).reduce((accumulator, user) => {
                  user.totalHours = 0;
                  if (user.projects) {
                    user.totalHours = user.projects.reduce((accumulator, project) => {
                      return (accumulator + project.dedicatedHours);
                    }, 0);
                  }
                  return accumulator + user.totalHours;
                }, 0);
              }
              return accumulator + location.totalHours;
            }, 0);
          }
          return accumulator + region.totalHours;
        }, 0);
      }
    }
  }

  /**
   * downloadPDF method
   * @description method to get the PDF report from backend
   * @param {string} startDate start date to the report
   * @param {string} endDate end date to the report
   * @param {string} periodName period name to the report
   * @param {string} periodType period type to the report
   * @returns {Blob} blob to dowload the PDf
   */
  public downloadPDF(
    startDate: string,
    endDate: string,
    periodName: string,
    periodType: string,
    segment: string,
    segmentId: string,
  ): Observable<Blob> {

    let params: Params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('periodType', periodType)
      .set('periodName', periodName);

      if (segment) {
        params = params.append('segment', segment)
        .append('segmentId', segmentId);
      }

    return this.http.get<Blob>(
      `${environment.apiUrl}/reports/working-hours-report-short-document`,
      { params, responseType: 'blob' as 'json' },
    ).pipe(
      catchError((error) => of(error))
    );
  }

  /**
   * getWorkingHoursCompleteReport method
   * @description obtain the report complete report of the working hours
   * by a time range and an optional filter
   * @param {string} startDate - Start of time range
   * @param {string} endDate - End of time range
   * @param {string} segment - Type of filter to use
   * (region, location, user or project)
   * @param {number} segmentId - Id of the segment to search
   * (region, location, user or project)
   * @returns {Observable<CompleteReportResponse>}
   * Observable<CompleteReportResponse>
   */
  public getWorkingHoursCompleteReport(
    startDate: string,
    endDate: string,
    region: number = null,
    location: number = null,
    user: number = null,
    project: number = null
  ): Observable<CompleteReportResponse> {
    const queryParams = {
      params: new HttpParams()
        .set('startDate', startDate)
        .set('endDate', endDate)
    };

    if ((region && location) || location) {
      queryParams.params = queryParams.params.set('segment', 'location');
      queryParams.params = queryParams.params.set('segmentId', location.toString());
    } else if (region) {
      queryParams.params = queryParams.params.set('segment', 'region');
      queryParams.params = queryParams.params.set('segmentId', region.toString());
    }

    if(user){
      queryParams.params = queryParams.params.set('segment', 'user');
      queryParams.params = queryParams.params.set('segmentId', user.toString());
    } else if (project) {
      queryParams.params = queryParams.params.set('segment', 'project');
      queryParams.params = queryParams.params.set('segmentId', project.toString());
    }

    return this.http.get<RegionToReport>(
      `${environment.apiUrl}/reports/working-hours-per-day`,
      queryParams
    ).pipe(
      catchError((error) => of(error))
    );
  }

  /**
   * downloadXLSX method
   * @description Method to get the XLSX report from backend
   * @param {string} startDate - Start date to the report
   * @param {string} endDate - End date to the report
   * @param {string} segment - Type of filter to use
   * (region, location, user or project)
   * @param {number} segmentId - Id of the segment to search
   * (region, location, user or project)
   * @returns {Blob} blob to dowload the XLSX
   */
  public downloadXLSX(
    startDate: string,
    endDate: string,
    segment: string,
    segmentId: string,
    descriptions: string,
  ): Observable<Blob> {
    let params: Params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('descriptions', descriptions);

    if (segmentId && segment && segment !== 'all'){
      params = params.set('segment', segment)
        .set('segmentId', segmentId.toString());
    }

    return this.http.get<Blob>(
      `${environment.apiUrl}/reports/working-hours-report-complete-document`,
      { params, responseType: 'blob' as 'json' },
    ).pipe(
      catchError((error) => of(error))
    );
  }
}
