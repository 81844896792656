import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) {}

  /**
   * canActivate method
   * checks if the user is logged in by determining if the
   * token exists in the local storage
   * @return {boolean}
   */
  public canActivate(): boolean {
    // get the user token
    const token = localStorage.getItem('auth');

    // navigate to login if the user hasn't authenticated yet
    if (!token) {
      this.router.navigateByUrl('login');
      return false;
    }

    return true;
  }
}