import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';

@Component({
  selector: 'app-employees-shared-list-header',
  templateUrl: './employees-shared-list-header.component.html',
  styleUrls: ['./employees-shared-list-header.component.scss']
})
export class EmployeesSharedListHeaderComponent {
  @Input() inCheckIn: boolean;
  @Output() locationChange: EventEmitter<UserLocationFilter> = new EventEmitter<UserLocationFilter>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() userStatusFilterChange = new EventEmitter<'all' | 'active' | 'inactive'>();

  public enteringInput: boolean = false;
  public userStatusFilter: 'all' | 'active' | 'inactive' = 'all';

  constructor() { }

  public setUserStatusFilter(filter: 'all' | 'active' | 'inactive'): void {
    this.userStatusFilter = filter;
    this.userStatusFilterChange.emit(this.userStatusFilter);
  }

  public setLocationFilter(filter: UserLocationFilter) {
    this.locationChange.emit(filter);
  }

  public onInput(input: string): void {
    this.search.emit(input);
  }
}


