import { AbstractControl } from '@angular/forms';
import { DateAfterNowError } from 'src/app/models/errors/date-after-now-error.model';

import moment from 'moment';

/**
 * Checks if the date is after now
 * @param {AbstractControl} control form control to be validated
 * @returns {object} object with the key of the error name and a value
 * of true if it's valid or null if not
 */
export function dateAfterNow(control: AbstractControl): DateAfterNowError {
  const date = control.value.split('/');
  const controlMoment = moment([+date[1], (+date[0] - 1)]);

  // determine if the value is after now
  if (controlMoment.isAfter(moment())) {
    return {
      dateAfterNow: true,
    };
  }

  return null;
}
