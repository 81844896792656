import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-project-header',
  templateUrl: './edit-project-header.component.html',
  styleUrls: ['./edit-project-header.component.scss']
})
export class EditProjectHeaderComponent implements OnChanges {
  @Input() projectId: number;
  @Input() statusProject: string;
  @Output() archiveProject: EventEmitter<string> = new EventEmitter<string>();

  public archiveBtnContent: string;
  constructor(private router: Router) { }

  /**
   * ngOnChanges
   * check the data of the project to change the content of the archive project button 
   * when the user open the edit view or press the toggle project button.
   */
  ngOnChanges(){
    if (!this.statusProject) {
      this.archiveBtnContent = 'archive project';
    } else {
      this.archiveBtnContent = 'restore project';
    }
  }

  /**
   * goBack
   * emits an event whit a message to the parent
   * @returns {void}
   */
  public goBack(): void {
    this.router.navigateByUrl(`manage/projects/${this.projectId}`);
  }

  /**
   * goToAddTeamMember
   * emits an event whit a message to the parent
   * @return {void}
   */
  public goToAddTeamMember(): void {
    this.router.navigateByUrl(
      `manage/projects/${this.projectId}/team-member/add`
    );
  }

  /**
   * goToAddClientMemberView
   * Emits an string event that parent catch to go to the addClientMember view
   * @returns {void}
   */
  public goToAddClientMemberView(): void {
    this.router.navigateByUrl(
      `manage/projects/${this.projectId}/client-member/add`
    );
  }

  /**
   * goToAddClientMemberView
   * Emits an string event that parent catch to archive or restore the project
   * @returns {void}
   */
  public archiveProjectButton(): void {
    if (!this.statusProject) {
      this.archiveProject.emit('archiveProject');
    } else {
      this.archiveProject.emit('restoreProject');
    }
  }
}
