import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { ProfileResponse } from "src/app/models/profile-response.model";
import { NavbarService } from "src/app/services/navbar.service";
import { UserService } from "src/app/services/user.service";
import { Router, NavigationEnd } from "@angular/router";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { SelectorOption } from "src/app/models/select/selector-option.model";
import { ContentModal } from "src/app/models/content-modal.model";
import { logOutModal, logOutButtons } from "src/app/config/modal-contents";
import { PermissionsService } from "src/app/services/permissions.service";
import { permissions } from "src/app/config/permissions";
import { filter } from "rxjs/operators";
import { EmptyResponse } from "src/app/models/responses/empty-response.model";
import { version } from "package.json";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements AfterViewInit, OnInit {
  @ViewChild("check", { static: true }) checkInput: ElementRef;
  @ViewChild("select", { static: false }) select: ElementRef;

  // permissions to see certail links
  public permissions = permissions;

  public version: string = `Version: ${version}`;
  public gitHashFE: string;
  public gitHashBE: string;

  public routeControl: FormControl = new FormControl(
    this.router.url.split("/")[1],
    Validators.required
  );

  public formSelect: FormGroup = new FormGroup({
    route: this.routeControl,
  });

  public selectValidator: Validators[] = [Validators.required];
  public selectOptions: SelectorOption[] = [
    {
      id: "dashboard",
      name: "My dashboard",
    },
    // Not Ready
    // {
    //   id: "timesheet",
    //   name: "My timesheet"
    // },
    // {
    //   id: "inventory",
    //   name: "Inventory"
    // },
    {
      id: "checkin",
      name: "Check In",
    },
    {
      id: "manage",
      name: "Manage",
    },
  ];
  public selectedOption: SelectorOption;
  public user: ProfileResponse;
  public showModal = false;

  // Content modal data to customize
  public contentModal: ContentModal = {
    title: logOutModal.title,
    content: logOutModal.content,
    confirmButton: logOutButtons.confirmButton,
    cancelButton: logOutButtons.cancelButton,
  };

  constructor(
    private navbarService: NavbarService,
    private userservice: UserService,
    public router: Router,
    public permissionsService: PermissionsService
  ) {
    this.getGitHashFE();
    this.getGitHashBE();
    this.getUserData();

    // subscriber for reload data
    this.userservice.refreshNedded$.subscribe(() => {
      this.getUserData();
    });

    // subscribe to url change to update the selectedOption
    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
        this.selectOptions.forEach((element) => {
          if (element.id === val.urlAfterRedirects.split("/")[1]) {
            this.selectedOption = element;
          }
        });
      });
  }

  /**
   * getUserData method
   * gets the user data based on its id
   * @return {void}
   */
  private getUserData(): void {
    const id: number = +localStorage.getItem("id");
    this.userservice.getUser(id).subscribe((obj: ProfileResponse) => {
      this.user = obj;
      this.user.data.photo += `?v=${Date.now()}`;
    });
  }

  /**
   * getGitHashFE method
   * gets the git hash from FE
   * @return {void}
   */
  private getGitHashFE(): void {
    this.userservice.getGitHashFE().subscribe((response) => {
      this.gitHashFE = response.hash;
    });
  }

  /**
   * getGitHashBE method
   * gets the git hash from BE
   * @return {void}
   */
  private getGitHashBE(): void {
    this.userservice.getGitHashBE().subscribe((response) => {
      this.gitHashBE = response.gitHash; // Adjust this based on your BE response structure
    });
  }

  /**
   * ngAfterViewInit hook
   * sets the reference of the navbar in the service
   * @return {void}
   */
  ngAfterViewInit() {
    this.navbarService.navbarRef = this.checkInput;
  }

  /***
   * When the select changes it has to redirect to the selected menu options
   */
  public redirectToSelectedMenu(): void {
    if (this.routeControl.value !== this.router.url.split("/")[1]) {
      this.router.navigateByUrl(this.routeControl.value);
      this.hideNavbar();
    }
  }

  /**
   * logOut method
   * show the modal to go out of your account
   * @returns {void}
   */
  public logOut(): void {
    this.showModal = true;
  }

  /**
   * onControl Method
   * Listen the PopUpMessage EventEmitter of control
   * If confirmButton was pushed the backend call occurs
   * If cancelButton was pushed the toggle switch status is restored
   * @param {string} message - Can be confirm or cancel, depending of the pushed button
   * @return {void}
   */
  public onControl(message: string): void {
    this.showModal = false;
    if (message === "confirm") {
      this.userservice.logOutUser().subscribe((response: EmptyResponse) => {
        if (response.status === "success") {
          localStorage.clear();
          this.router.navigateByUrl("login");
        }
      });
    }
  }

  /**
   * onCloseModal Method
   * Listen the PopUpMessage EventEmitter of close
   * When it is received the showModal variable turns to false and the modal is closed
   * @return {void}
   */
  public onCloseModal(): void {
    this.showModal = false;
  }

  /**
   * hideNavbar method
   * it hides the navbar after you
   * click on a link
   * @returns {void}
   */
  public hideNavbar(): void {
    this.checkInput.nativeElement.checked = false;
  }

  ngOnInit() {
    // checking if the user has the permissions to get access to the manage view.
    if (
      !this.permissionsService.userHasPermission([
        this.permissions.userStatus,
        this.permissions.editResume,
        this.permissions.assignRoles,
        this.permissions.projects,
        this.permissions.createRoles,
        this.permissions.generateReports,
        this.permissions.management,
        this.permissions.companyMembers,
        this.permissions.databaseItems,
      ])
    ) {
      // removing the last position in the navbar selector
      this.selectOptions.splice(this.selectOptions.length - 1);
    }

    if (
      !this.permissionsService.userHasPermission([
        this.permissions.editCheckins,
        this.permissions.checkIns,
      ])
    ) {
      // removing the check in position in the navbar selector
      this.selectOptions.splice(1, 1);
    }
  }
}
