import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { SignUpService } from 'src/app/services/sign-up.service';
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { signUpFormErrorMessages } from 'src/app/config/error-messages';
import { validateEmailValidator } from 'src/app/shared/custom-validators/custom-validator-email';
import { Error } from 'src/app/models/errors/error.model';
import { bluetrailsoftMailRegex } from 'src/app/config/regex';

@Component({
  selector: 'app-sign-up-view-one',
  templateUrl: './sign-up-view-one.component.html',
  styleUrls: ['./sign-up-view-one.component.scss'],
  providers: [SignUpService],
})
export class SignUpViewOneComponent {
  @Output() submitForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  public showError: string = '';
  public img: string = 'assets/images/logos/bts-logo.svg';
  public alertIcon: string = 'assets/images/icons/alert-icon.svg';
  public correctEmail: boolean = false;
  public dirtyPassword: boolean = false;

  // forms controls
  public firstNameControl = new FormControl(' ', []);
  public lastNameControl = new FormControl('', []);
  public emailControl = new FormControl('', []);
  public passwordControl = new FormControl('', []);
  public validatePasswordControl = new FormControl('', []);

  //to errors
  public firstNameError = signUpFormErrorMessages.firstNameError;
  public lastNameError = signUpFormErrorMessages.lastNameError;
  public emailErrors = signUpFormErrorMessages.emailErrors;
  public passwordErrors = signUpFormErrorMessages.passwordErrors;
  public validatePasswordErrors = signUpFormErrorMessages
    .validatePasswordErrors;

  // input validators
  public firstNameValidators: Validators[] = [
    Validators.required,
    Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑÄËÏÖÜäëïöü'.\s]+$/),
  ];
  public lastNameValidators: Validators[] = [
    Validators.required,
    Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑÄËÏÖÜäëïöü'\s]+$/),
  ];
  public emailValidations: Validators[] = [
    Validators.required,
    Validators.pattern(bluetrailsoftMailRegex),
  ];
  public emailValidators: Validators[] = Array.from(this.emailValidations);

  public passwordValidators: Validators[] = [
    Validators.required,
    Validators.pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[.@!?_-])(?=.*\d)[A-Za-z\d.@!?_-]{8,}$/
    ),
  ];

  public formSignUpOne: FormGroup = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    password: this.passwordControl,
    validatePassword: this.validatePasswordControl,
  });

  constructor(private route: Router, private signUpService: SignUpService) { }
  
  /**
   * compareEmail
   * compare email to db row and set validator to input
   * @return {void} 
   */
  public compareEmail(): void {
    if (
      this.emailControl.errors && this.emailControl.errors.customValidateEmail
      && (this.emailControl.errors.required
      || this.emailControl.errors.pattern)) {
      this.emailValidators = Array.from(this.emailValidations);
      this.correctEmail = false;
      return;
    }
    
    if (this.emailControl.valid ||
      this.emailControl.errors.hasOwnProperty('emailRepeat') ||
      this.emailControl.errors.hasOwnProperty('emailNotFound') ) {
      this.signUpService.validateEmail(this.emailControl.value.toLowerCase()).subscribe((data: Response) => {
        
        if (data['error']) {
          const validators = Array.from(this.emailValidations);
          validators.push(validateEmailValidator(data['error'].message));
          this.correctEmail = false;
          this.emailValidators = Array.from(validators);
        } else if (data.status === 'success') {
          this.correctEmail = true;
        }
      });
    }
  }

  /**
   * dropErrorMessage
   * set again the validators in email
   * @return {void} 
   */
  public dropErrorMessage(): void {
    this.correctEmail = false;
    if (this.emailControl.errors) {
      if (this.emailControl.errors.hasOwnProperty('emailRepeat') ||
        this.emailControl.errors.hasOwnProperty('emailNotFound')) {
        this.emailValidators = Array.from(this.emailValidations);
      }
    }
  }

  /**
   * onSubmitOne method
   * validate match passwords and go to second view
   * @return {void} 
   */
  public onSubmitOne(): void {
    this.showError = '';
    if (this.formSignUpOne.valid) {
      if (
        this.formSignUpOne.value.password ===
        this.formSignUpOne.value.validatePassword
      ) {
        this.submitForm.emit(this.formSignUpOne);
      } else {
        this.showError = 'Passwords do not match';
      }
    } else {
      this.showError = 'Wrong data';
    }
  }

  //redirect to home
  public toLogin(): void {
    this.route.navigateByUrl('login');
  }
  
  /**
   * readErrors method
   * verify errors from password
   * @param {Error} event control.errors from input password
   * @returns {void}
   */
  public readErrors(event: Error): void {
    if (event && event.pattern) {
      this.dirtyPassword = true;
    } else {
      this.dirtyPassword = false;
    }
  }
}
