import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { UserDescription } from 'src/app/models/profile/user-description.model';
import { ProfileService } from 'src/app/services/profile.service'

@Component({
  selector: 'app-description-display',
  templateUrl: './description-display.component.html',
  styleUrls: ['./description-display.component.scss']
})
export class DescriptionDisplayComponent implements OnInit, OnDestroy {

  @Input() description: UserDescription[];
  @Input() editSummaryHidden: boolean = false;
  @Input() personalInfoInEdit: boolean;
  @Output() editDescription: EventEmitter<string> = new EventEmitter<string>();
  
  public hideEventsSubs: Subscription;
  public showEventsSubs: Subscription;
  public show: boolean = true;

  constructor(private profileService: ProfileService) { }

  ngOnInit() {
    // merge the subjects and hide the buttons
    this.hideEventsSubs = merge(
      this.profileService.addExperienceView,
      this.profileService.editExperienceView,
      this.profileService.editPersonalView,
      this.profileService.addSkillView,
    ).subscribe(() => this.show = false);

    // show the buttons
    this.showEventsSubs = this.profileService.showButtons
      .subscribe(() => this.show = true);
  }

  /**
   * goToEditDescriptionView method
   * go back to the edit description view.
   * @return {void}
   */
  public goToEditDescriptionView(): void {
    this.editDescription.emit('editDescriptionView');
  }

  ngOnDestroy() {
    this.hideEventsSubs.unsubscribe();
    this.showEventsSubs.unsubscribe();
  }
}
