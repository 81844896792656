import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent {

  @Output() teamView: EventEmitter<string> = new EventEmitter<string>();

  // view flags
  public viewFlags = {
    // default view
    teamView: false,
    editView: false,
    teamListView: true,
  };

  constructor() { }

  /**
   * goToTeamView method
   * emits an event to trigger the team list view
   * @return {void}
   */
  public goToTeamView(): void {
    this.teamView.emit('teamView');
  }

  /**
   * changeView method
   * sets the flag of the corresponding view
   * @param {string} view name of the view to display
   * @return {void}
   */
  public changeView(view: string): void {
    for (let key in this.viewFlags) {
      if (this.viewFlags.hasOwnProperty(key) && key === view) {
        this.viewFlags[key] = true;
      } else {
        this.viewFlags[key] = false;
      }
    }
  }

}
