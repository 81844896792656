import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Error } from 'src/app/models/errors/error.model';

@Component({
  selector: 'app-pop-up-message',
  templateUrl: './pop-up-message.component.html',
  styleUrls: ['./pop-up-message.component.scss']
})
export class PopUpMessageComponent {
  // Inputs to customize the modal
  @Input() image: string;
  @Input() title: string;
  @Input() content: string;
  @Input() confirmButton: string;
  @Input() cancelButton: string;
  @Input() isWelcomeModal: boolean;
  @Input() selectorOptions: SelectorOption[];
  @Input() selectorErrors: Error;

  // EventEmitters to notify the clicked button and if the modal will be closed
  @Output() control: EventEmitter<string> = new EventEmitter<string>();
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  public selectedOptionControl = new FormControl(null, []);
  public selectValidators: Validators[] = [Validators.required];
  public optionSelected = null;

  public selectorForm = new FormGroup({
    selectedOption: this.selectedOptionControl,
  }); 

  constructor() { }

  /**
   * Method onConfirm
   * Is called when the confirmButton is clicked
   * Emit two events:
   * The first one to notify a click in this button
   * and if the selector is used to notify which option was selected
   * The second one to notify that the modal must be closed 
   * @return {void}
   */
  public onConfirm(): void {
    if (this.selectedOptionControl.value) {
      this.control.emit(this.selectedOptionControl.value.toString());
    } else {
      this.control.emit('confirm');
    }
    this.close.emit('close');
  }

  /**
   * Method onCancel
   * Is called when the cancelButton is clicked
   * Emit two events:
   * The first one to notify a click in this button
   * The second one to notify that the modal must be closed 
   * @return {void}
   */
  public onCancel(): void {
    // this.control.emit('cancel');
    this.close.emit('close');
  }

  /**
   * setOptionSelected method
   * @description When an option is selected in the selector
   * is setted a variable to send to parent component when
   * confirm will be clicked
   * @returns {void} void
   */
  public setOptionSelected(): void {
    this.optionSelected = this.selectedOptionControl.value;
  }
}
