import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { myProjectsErrors } from 'src/app/config/error-messages';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model';

@Injectable({
  providedIn: 'root'
})
export class MyProjectsService {

  // filter options
  public statusOptions: SelectorOption[] = [
    // default
    {
      id: null,
      name: 'Active',
    },
    {
      id: null,
      name: 'Archived'
    }
  ];
  public involvedOptions: SelectorOption[] = [
    // default
    {
      id: null,
      name: 'My Projects',
    },
    {
      id: null,
      name: 'All Projects',
    },
  ];

  // form controls
  public statusControl: FormControl;
  public involvedControl: FormControl;

  // form validators
  public statusValidators: Validators[];
  public involvedValidators: Validators[];

  // errors
  public statusErrors;
  public involvedErrors;

  // form group
  public getProjectsForm: FormGroup;

  public statusDefaultOption: SelectorOption = null;
  public involvedDefaultOption: SelectorOption = null;

  /**
   * setUpForm method
   * sets up the form that filters the projects
   * @returns {void}
   */
  public setUpForm(selectedFilters: ProjectFiltersData): void {
    // set the values of the form controls and group
    this.statusControl = new FormControl('', []);
    this.involvedControl = new FormControl('', []);

    this.statusValidators = [];
    this.involvedValidators = [];

    this.statusErrors = myProjectsErrors.status;
    this.involvedErrors = myProjectsErrors.involved;

    this.getProjectsForm = new FormGroup({
      status: this.statusControl,
      involved: this.involvedControl,
    });

    if (selectedFilters) {
      this.defineDefaultOptions(selectedFilters);
    } else {
      this.statusDefaultOption = this.statusOptions[0];
      this.involvedDefaultOption = this.involvedOptions[0];
    }
  }

  private defineDefaultOptions(selectedFilters: ProjectFiltersData) {
    if (selectedFilters.status === 'inactive') {
      this.statusDefaultOption = this.statusOptions[1];
    } else {
      this.statusDefaultOption = this.statusOptions[0];
    }

    if (selectedFilters.involved === 'all') {
      this.involvedDefaultOption = this.involvedOptions[1];
    } else {
      this.involvedDefaultOption = this.involvedOptions[0];
    }
  }
}
