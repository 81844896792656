import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { regionNameRegex, locationNameRegex } from 'src/app/config/regex';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { createRegionErrors } from 'src/app/config/error-messages';
import { WorkingHoursReportService } from 'src/app/services/reports/working-hours-report.service';
import { CompanyRegion } from 'src/app/models/company-region.model';
import { DatabasesRequest } from 'src/app/models/databases-request.model';
import { DatabasesService } from 'src/app/services/databases.service';
import { SimpleInputErrors } from 'src/app/models/simple-input-errors.model.';
import { UserService } from 'src/app/services/user.service';
import { TeamListResponse } from 'src/app/models/team-list-response.model';
import { ContentModal } from 'src/app/models/content-modal.model';
import {
  deleteDatabaseItemModal,
  migrateRelatedDataModal,
  deleteButtons,
} from 'src/app/config/modal-contents';
import { DatabaseItem } from 'src/app/models/database-item.model';
import { SelectorOption } from 'src/app/models/select/selector-option.model';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements OnInit {

  @Input() buttonsDisabled: boolean;
  @Output() disableAllButtons: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private workingHoursReportService: WorkingHoursReportService,
    private databasesService: DatabasesService,
    private userService: UserService,
  ) { }

  public showContent: boolean = false;
  public regions: CompanyRegion[] = null;
  public inputRegex: RegExp = locationNameRegex;
  public dataUpdated: boolean = false;
  public elementAdded: boolean = false;
  public showModal: boolean = false;
  public itemToDelete: DatabaseItem = null;
  public categoryToDelete: string = null;
  public locationOptions: SelectorOption[] = [];
  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: deleteButtons.confirmButton,
    cancelButton: deleteButtons.cancelButton,
  }

  // Form Validators
  public regionNameValidators: Validators[] = [
    Validators.required,
    patternValidator(regionNameRegex),
  ];
  public locationNameValidators: Validators[] = [
    patternValidator(locationNameRegex),
  ];
  // Form Errors
  public regionNameError: SimpleInputErrors = createRegionErrors
    .regionNameError;
  public locationNameError: SimpleInputErrors = createRegionErrors
    .locationNameError;

  /**
   * ngOnInit hook
   * @description Get all the existent regions and its locations
   * from the database
   */
  ngOnInit() {
    this.getRegions();
  }

  /**
   * toggleContent method
   * @description toggle the flag showContent to show/hide the content
   * @returns {void} void
   */
  public toggleContent(): void {
    this.showContent = !this.showContent;
    if (!this.showContent && this.buttonsDisabled && this.elementAdded) {
      this.disableButtons(false);
      this.dataUpdated = false;
      this.elementAdded = false;
    }
  }

  /**
   * getRegions method
   * @description Use the method getRegions method
   * of WorkingHoursReportService to get the existent regions
   * @returns {void} void
   */
  private getRegions(): void {
    this.workingHoursReportService.getRegions().subscribe((result) => {
      if (result.data.length) {
        this.regions = result.data.map((region) => {
          const formatedRegion: CompanyRegion = {
            id: region.id,
            regionName: region.name,
            locations : region.locations.map((location) => {
              return {
                id: location.id,
                name: location.name
              };
            }),
          }
        
          return formatedRegion;
        });
      } else {
        this.regions = [];
      }
    });

    this.showContent = true;
  }

  /**
   * sendRequest method
   * @description Send the Backend Request
   * @param {FormGroup} parentForm - Region FormGroup that has the data to send
   * @returns {void} void 
   */
  public sendRequest(request: DatabasesRequest): void {
    this.databasesService.sendRequest(request).subscribe(() => {
      this.getRegions();
      this.dataUpdated = true;
      this.elementAdded = false;
    });
  }

  /**
   * disableButtons method
   * @description Emit an event to its parent component with the value
   * received from its child to send the new value of buttonsDisabled
   * variable
   * @param {boolean} value - Received value
   * @returns {void} void 
   */
  public disableButtons(value: boolean): void {
    this.disableAllButtons.emit(value);
    this.elementAdded = true;
  }

  /**
   * hasUsersRelated method
   * @description Check if the given location
   * has users related
   * @param {DatabaseItemArray} location - Object
   * with the index position in locations array
   * of the location to check
   * @returns {void} void
   */
  public hasUsersRelated(location: DatabaseItem): void {
    this.itemToDelete = location;

    this.userService.getFilteredUsers(
      1,
      { locationId: location.id },
    ).subscribe((response: TeamListResponse) => {
      if (response.data.pages) {
        this.locationOptions = [];

        this.regions.forEach((region) => {
          region.locations.forEach((location) => {
            if (location.id !== this.itemToDelete.id) {
              this.locationOptions.push({
                id: location.id,
                name: location.name,
              });
            }
          })
        });

        this.contentModal.title = migrateRelatedDataModal.title('Users');
        this.contentModal.content = migrateRelatedDataModal.content(
          location.name,
          'Location',
          'Users'
        );
      } else {
        this.contentModal.title = deleteDatabaseItemModal.title('Location'),
        this.contentModal.content = deleteDatabaseItemModal.content(
          location.name,
          'Location',
        )
      }

      this.categoryToDelete = 'locations';
      this.showModal = true;
    });
  }

  /**
   * onControlModal method
   * @description Listen the control event from PopUpMessage
   * when a button was clicked to know which was
   * clicked and if confirm was clicked send to object
   * to make the BE Request
   * @param value - Clicked button ('confirm'/'cancel'/optionId (in case you used selector))
   * @returns {void} void
   */
  public onControlModal(value: string): void {
    let request: DatabasesRequest = null;
    let endpoint: string = `/company/${this.categoryToDelete}/${this.itemToDelete.id}`;

    // In case you used the selector
    if (parseInt(value, 10) && this.categoryToDelete === 'locations') {
      endpoint += `?locationToMigrateId=${value}`;
    }

    request = {
      method: 'DELETE',
      endpoint: endpoint,
    }

    if (value !== 'cancel') {
      this.sendRequest(request);
    }

    this.locationOptions = [];
  }

  /**
   * onCloseModal method
   * @description Listen the close event from PopUpMessage
   * to know when the modal may be closed
   * @returns {void} void
   */
  public onCloseModal(): void {
    this.showModal = false;
  }

  /**
   * deleteRegion method
   * @description Delete the given region from Databases Section
   * @param {DatabaseItem} region - Region to delete
   * (contains the region's id and name)
   * @returns {void} void
   */
  public deleteRegion(region: DatabaseItem): void {
    this.itemToDelete = region;

    this.contentModal.title = deleteDatabaseItemModal.title('Region'),
    this.contentModal.content = deleteDatabaseItemModal.content(
      region.name,
      'Region',
    );

    this.categoryToDelete = 'regions';
    this.showModal = true;
  }
}
