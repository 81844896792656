import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { UserProject } from "src/app/models/user-projects.model";
import { TimesheetDescription } from "src/app/models/timesheet-description.model";
import { TimesheetService } from 'src/app/services/timesheet.service';
import { DatePipe } from '@angular/common'
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { WorkdayJump } from 'src/app/models/workday-jump.model';
import { WorkdayJumpTo } from 'src/app/models/workday-jump-to.model';

@Component({
  selector: "app-timesheet-display",
  templateUrl: "./timesheet-display.component.html",
  styleUrls: ["./timesheet-display.component.scss"],
})

export class TimesheetDisplayComponent implements OnChanges{
  @Input() date: Date;
  @Input() projects: UserProject[];
  @Input() showDisplay: boolean;
  @Input() timesheetActive: Date;
  @Input() descriptions: TimesheetDescription[];
  @Input() status: string;
  @Input() isFreeDay: boolean;
  @Input() lastWorkday: Date;
  @Input() firstWorkday: Date;

  @Output() activeEmitEvent: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() deleteTimesheets: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() timesheetInFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() jumpWorkday: EventEmitter<WorkdayJump> =
    new EventEmitter<WorkdayJump>();

  constructor(
    private timesheetService: TimesheetService,
    private datePipe: DatePipe,
  ) {}

  public changeToEdit: boolean = false;

  public deleteTimesheetDay(date): void {
    const formatDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.timesheetService.deleteTimesheetDay(formatDate)
      .subscribe((response: Response) => {
        if(response.status === 'success') {
          this.descriptions = [
            {
              projectId: null,
              dedicatedHours: 8,
              isHappy: true,
              task: "",
              note: ""
            }
          ];
          this.deleteTimesheets.emit(this.date);
          this.showDisplay = true;
          this.changeToEdit = false;
        }
      });
  }

  /**
   * sumHours method
   * gets the sum of dedicated hours
   * @returns {number} sum of hours
   */
  public sumHours(): number {
    return this.descriptions.reduce(
      (sum, description) => sum + (description['dedicatedHours'] || 0), 0);
  }

  /**
   * isToday method
   * Verify if the timesheet is correspondig to this day
   * @returns {boolean} is true if the date of the timesheet is today
   */
  public isToday(): boolean {
    return this.date.toDateString() == new Date().toDateString();
  }

  /**
   * changeView method
   * changes between display and edit views
   * @param newDescriptions timesheet descriptions to show
   * @returns {void}
   */
  public changeView(newDescriptions?: TimesheetDescription[]): void {
    if (newDescriptions) {
      this.descriptions = newDescriptions;
      this.activeEmitEvent.emit(this.date);
    }
    this.changeToEdit = this.showDisplay;
    this.showDisplay = !this.showDisplay;
    this.updateActive();
  }

  /**
   * ngOnChanges hook
   * When selected timesheet changes, it displays read only view
   * and cancel changes
   * @param changes changes detected
   */
  ngOnChanges(changes: SimpleChanges) {
    if(
      this.changeToEdit &&
      changes.timesheetActive &&
      changes.timesheetActive.previousValue === this.date) {
        this.showDisplay = true;
        this.changeToEdit = false; 
    }
  }

  /**
   * updateActive method
   * notify to update component about a new selected date
   * @returns {void}
   */
  public updateActive(): void {
    if(this.timesheetActive !== this.date) {
      this.activeEmitEvent.emit(this.date);
    }
  }

  /**
   * cancelEdit method
   * when an edit is cancelled it put the
   * focus is the next timesheet
   * @returns {void}
   */
  public cancelEdit(): void {
    this.activeEmitEvent.emit(this.timesheetActive);
    event.stopPropagation();
  }

  /**
   * setTimesheetInFocus method
   * @description Recives a boolean value to emit an event
   * to parent component with this value to know if the
   * timesheet is in focus or not
   * @param {boolean} value - Received value
   * (true in focus, false in blur)
   * @returns {void} void
   */
  public setTimesheetInFocus(event: boolean): void {
    this.timesheetInFocus.emit(event);
  }

  /**
   * goToWorkday method
   * @description Emit an event with the jump to do
   * and the actual active date
   * @param {WorkdayJumpTo} event - Received value with the jump to do
   * @returns {void} void
   */
  public goToWorkday(event: WorkdayJumpTo): void {
    this.jumpWorkday.emit({
      jumpTo: event,
      active: this.timesheetActive,
    });
  }
}
