import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

import { Tab } from 'src/app/models/shared/tab.model';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @Input() tabs: Tab[] = [];
  @Input() template: TemplateRef<any>;
  @Output() tabSelected: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  /**
   * selectTab method
   * select a tab based on the user input
   * @return {void}
   */
  public selectTab(tab: Tab): void {
    
    this.tabs.forEach((tab) => tab.active = false);
    tab.active = true;
    this.tabSelected.emit(tab.id);
  }
}
