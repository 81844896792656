import { AbstractControl } from '@angular/forms';

/**
 * Checks if the value is valid
 * @param pattern Regex used to know if the values has the correct format.
 * @returns {object} object with the key of the error name and a value
 * of true if it's valid or null if not
 */
export function patternValidator(pattern:RegExp) {
  return (control:AbstractControl) => {
    const value:string = control.value;
    if (value) {
      if (value.match(pattern) === null) {

        return {
          patternValidator: true
        }
      }
      return null;
    }
    return null;
  }
}
