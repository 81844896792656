import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-hidden-input',
  templateUrl: './hidden-input.component.html',
  styleUrls: ['./hidden-input.component.scss']
})
export class HiddenInputComponent implements OnInit {

  @Input() type: string;
  @Input() id: string;
  @Input() name: string;
  @Input() labelText: string;
  @Input() showLabel: boolean = true;
  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() errors: Error;
  // if control is part of an array
  @Input() controlIndex: number;
  @Input() centerText: boolean = false;

  @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();

  public displayInput: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  /**
   * showInput method
   * toggles the value of the displayInput flag to hide/show the input
   * @return {void}
   */
  public showInput(): void {
    this.displayInput = !this.displayInput;
  }

  /**
   * emitBlur method
   * emits an event to the parent component informing that the input
   * has been blurred
   * @return {void}
   */
  public emitBlur(): void {
    this.onBlur.emit('');
    this.showInput();
  }

  /**
   * emitInput method
   * emits an event to the parent component informing that the field
   * is being changed
   * @return {void}
   */
  public emitInput(): void {
    this.onInput.emit('');
  }
}
