import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ClientTeamMember} from 'src/app/models/client-team-member.model';
import {AddClientMemberResponse} from 'src/app/models/add-client-member-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddClientMemberService {

  constructor(private http: HttpClient) { }

    /**
   * addClientMemberProject method
   * return an observable with the data created on the database
   * @param {ClientTeamMember} clientTeamMember contain the data necesary to create the 
   * new team member
   * @param {number}projectId contain the id of the project necesary to make the request
   * @returns {Observable} add Team Member Response
   */
  public addClientMemberProject(clientTeamMember: ClientTeamMember, projectId: number): Observable<AddClientMemberResponse> {
    return this.http.post<AddClientMemberResponse>(
      `${environment.apiUrl}/projects/${projectId}/client-member`,
      clientTeamMember
      ).pipe(
        catchError((error) => {
          return of (error);
        })
      );
  }
}
