import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navbar.service';
import { UserService } from 'src/app/services/user.service';
import { ProfileResponse } from 'src/app/models/profile-response.model';
import { UserProfile } from 'src/app/models/user-profile.model';
// import { HeaderGeneralData } from 'src/app/models/headers/header-general-data.model';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {

  @Input() module: string;
  @Input() section: string;
  @Input() icon: string;
  @Input() color: string = 'green';
  
  public photoAlt: string;
  public user: UserProfile;

  constructor(
    private navbarService: NavbarService,
    private userService: UserService,
  ) { }

  /**
   * ngOnInit hook
   * set up the alt attribute of the user photo
   */
  ngOnInit() {
    this.getUserData();
  }
  
  /**
   * getUserData method
   * gets the user data based on its id
   * @return {void}
   */
  private getUserData(): void {
    const id: number = +localStorage.getItem('id');
    this.userService.getUser(id).subscribe((obj: ProfileResponse) => {
      this.user = obj.data;
      this.photoAlt = `${this.user.photo}'s photo`;
  });
}
  /**
   * toggleNavbar method
   * opens the navbar
   * @return {void}
   */
  public toggleNavbar(): void {
    this.navbarService.navbarRef.nativeElement.checked = !this.navbarService.navbarRef.nativeElement.checked;
  }

}
