import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PermissionsResponse } from 'src/app/models/permissions-response.model';
import { Role } from 'src/app/models/role.model';
import { NewRoleResponse } from 'src/app/models/new-role-response.model';
import { RolesListResponse } from 'src/app/models/roles/roles-list-response.model';
import { RoleDetailsResponse } from 'src/app/models/roles/role-details-response.model';
import { RolesResponse } from 'src/app/models/responses/roles-response.model';
import { DeleteRoleResponse } from 'src/app/models/roles/deleteRoleResponse.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }

   /**
   * getPermissions method
   * @description gets the Permission from the backend
   * @returns {Observable<PermissionsResponse>} Observable<PermissionsResponse> - array containing all the necesary Permissions
   * for create Roles
   */
  public getPermissions(): Observable<PermissionsResponse> {
    return this.http.get<PermissionsResponse>(
      `${environment.apiUrl}/permissions`
      ).pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getRoles method
   * @description Gets the roles from the backend through pagination
   * @param {number} page array containing the roles to be sliced
   * @returns {Observable<RolesListResponse>} Observable<RolesListResponse> - array containing the page of roles
   */
  public getRoles(page: number): Observable<RolesListResponse> {
    const url: string = `${environment.apiUrl}/company/roles?page=${page}`;

    return this.http.get<RolesListResponse>(url)
      .pipe(
        catchError((error) => {
          return of (error);
        })
      );
  }

  /**
   * createNewRole method
   * @description return an observable with the data created on the database
   * @param newRole contain the data necesary to create the
   * new role
   * @returns {Observable<NewRoleResponse>} Observable<NewRoleResponse> - New Role Response
   */
  public createNewRole(newRole: Role): Observable<NewRoleResponse> {
    return this.http.post<NewRoleResponse>(
       `${environment.apiUrl}/company/roles`,
      newRole
      ).pipe(
        catchError((error) => {
          return of (error);
        })
    )
  }

  /**
   * getRoleDetails method
   * @description retrieves the data from an specific role
   * @param {number} id role id
   * @returns {Observable<RoleDetailsResponse>} Observable<RoleDetailsResponse> - observable with response from API
   */
  public getRoleDetails(id:number): Observable<RoleDetailsResponse> {
    return this.http.get<RoleDetailsResponse>(`${environment.apiUrl}/company/roles/${id}/details`)
    .pipe(
      catchError((error) => {
        return of (error);
      })
    )
  }

/**
 * editRole method
 * @description receives an id and role data to edit an specific role
 * @param {number} id role id
 * @param {Role} roleData information for the role to edit data
 * @returns {Observable<NewRoleResponse>} - Observable<NewRoleResponse> observable with response from API
 */
public editRole(id: number, roleData: Role): Observable<NewRoleResponse> {
  return this.http.put<NewRoleResponse>(`${environment.apiUrl}/company/roles/${id}`, roleData)
  .pipe(
    catchError((error) => {
      return of (error);
    })
  );
}
  /**
   * getRoles method
   * @description obtain the existent company roles along the users that have them assigned
   * @param {number} page page to retrieve from database
   * @return {Observable<RolesResponse>} Observable<RolesResponse> - observable from response
   */
  public getRolesForEmployee(page: number): Observable<RolesResponse> {
    const queryParams = {
      params: new HttpParams().set('page', page.toString()),
    };

    return this.http.get<RolesResponse>(
      `${environment.apiUrl}/company/roles`,
      queryParams
    ).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
   * deleteRole method
   * @description receives an id to delete an specific role
   * @param {number} id role id
   * @returns {Observable<DeleteRoleResponse>} Observable<DeleteRoleResponse> - observable with response from API
   */
  public deleteRole(id: number): Observable<DeleteRoleResponse> {
    return this.http.delete<DeleteRoleResponse>(`${environment.apiUrl}/company/roles/${id}`)
    .pipe(
      catchError((error) => {
        return of (error);
      })
    );
  }
}
