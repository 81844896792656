import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PermissionsService } from 'src/app/services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuardService implements CanActivate {

  constructor(
    private router: Router,
    private permissionsService: PermissionsService
  ) {}

  /**
   * canActivate method
   * checks if the user is logged in by determining if the
   * token exists in the local storage
   * @return {boolean}
   */
  public canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): boolean {
    const permissionsNeeded = route.data.permissions;

    if (this.permissionsService.userHasPermission(permissionsNeeded)) {
      return true;
    }

    this.router.navigateByUrl('dashboard');
    return false;
  }
}