import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Skills } from 'src/app/models/sign-up/skills.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { skillErrors } from 'src/app/config/error-messages';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { alphaNumberNoEmojisRegex } from 'src/app/config/regex';
import { SignUpService } from 'src/app/services/sign-up.service';
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { ProfileService } from 'src/app/services/profile.service';
import { UserSkillsService } from 'src/app/services/user-skills.service';
import { merge, Subscription } from 'rxjs'
import { Skill } from 'src/app/models/skill.model';
import { SkillLevel } from 'src/app/models/skill-level.model';
import { ChangeSkillLevel } from 'src/app/models/change-skill-level.model';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit, OnDestroy {
  @Input() skills: Skill[];
  @Input() userId: number;
  @Input() personalInfoInEdit: boolean;

  @Output() skillAdded: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public addSkills: boolean = false;
  public hideButtons: boolean = false;
  public sameSkill: boolean;
  public skillsList$: string[];
  public skillControl: FormControl = new FormControl('', []);
  public message: string;
  public hideEventsSubs: Subscription;
  public showEventsSubs: Subscription;

  // validators for skills form
  public skillValidators: Validators[] = [
    Validators.required,
    patternValidator(alphaNumberNoEmojisRegex),
  ];

  // error messages
  public skillErrors = skillErrors.skillFieldErrors;

  // form group
  public skillsForm: FormGroup = new FormGroup({
    skill: this.skillControl,
  });

  public skillLevels: SkillLevel[];
  public actualSkillLevel: number = 0;

  constructor(
    private signUp: SignUpService,
    private userSkillService: UserSkillsService,
    private profileService: ProfileService,
  ) {}

  /**
   * ngOnInit
   * merge all the subjects that are used to hide the buttons
   * in the profile view, allows to notify this component when hide his buttons,
   * and subscribes to the subject that shows the buttons, setting a boolean variable
   */
  ngOnInit() {
    this.getAllSkills();

    // merge subscriptions
    this.hideEventsSubs = merge(
      this.profileService.editPersonalView,
      this.profileService.editSummaryView,
      this.profileService.addExperienceView,
      this.profileService.editExperienceView,
    ).subscribe(() => this.hideButtons = true);

    // subscribing to showButtons, to know when the buttons will show
    this.showEventsSubs = this.profileService.showButtons
      .subscribe(() => this.hideButtons = false);
    this.filterSkills();

    this.getSkillLevels();
  }

  /**
   * getAllSkills
   * gets all the skills from the API
   * @returns {void} void
   */
  public getAllSkills(): void {
    this.signUp.getSignUpGeneric('/skills').subscribe((data: Response) => {
      this.skillsList$ = data.data.map((skill: Skills) => skill.name);
    });
  }

  /**
   * onSubmit
   * checks the data from the form and send it to the function addUserSkill
   * @returns {void} void
   */
  public onSubmit(): void {
    if (this.skillsForm.valid) {
      const newSkill: Skill = this.skillsForm.value;
      this.addUserSkill(newSkill, this.skillLevels[this.actualSkillLevel]);
      this.actualSkillLevel = 0;
    }
    this.addSkills = false;
    this.onEdit.emit(false);
  }

  /**
   * addUserSkill
   * @param {Skill} newSkill the skill the the user wants to add
   * @returns {void} void
   */
 public addUserSkill(newSkill: Skill, level: SkillLevel): void {
    // checking that the user id is a valid
    if (isNaN(this.userId) || this.userId < 1) {
      this.message = 'Invalid user id'
    } else {
      this.userSkillService
      .addSkill(
        newSkill,
        this.userId,
        level.id,
      )
      .subscribe((data) => {
        if (data.status === 'success') {
          this.skillAdded.emit('added');
          this.profileService.skillAdded.next();
          this.profileService.showButtons.next();
        } else {
          this.message = data.message;
        }
      });
    }
  }

  /**
   * goToAddSkillsView
   * emits and event to hide the buttons of the profile view
   * @returns {void} void
   */
  public goToAddSkillsView(): void {
    this.profileService.addSkillView.next();
    this.hideButtons = true;
    this.onEdit.emit(true);

    // setting the form to empty value
    this.skillsForm.reset();
  }

  /**
   * goToProfileView
   * emits an event to show the buttons in the profile view
   * @returns {void} void
   */
  public goToProfileView(): void {
    this.sameSkill = false;
    this.profileService.showButtons.next();
    this.onEdit.emit(false);
    this.hideButtons = false;
  }

    /**
   * deleteUserSkill method
   * delete a user's skill
   * @param {number} skillId skill to delete
   * @returns {void}
   */
  public deleteUserSkill(skillId: number): void {
    this.userSkillService.deleteUserSkill(this.userId, skillId)
    .subscribe((response: Response) => {
      if (response.status === 'success') {
        this.skills = this.skills.filter(
          skillObject => skillObject.id !== skillId
        );
      }
    });
  }

  /**
   * ngOnDestroy
   * executes when the component is destroyed and
   * unsubscribe from the hideEventSubs and showEventSubs
   * @returns {void} void
   */
  ngOnDestroy(): void {
    this.hideEventsSubs.unsubscribe();
    this.showEventsSubs.unsubscribe();
  }

  /**
   * filterSkills
   * checks the skills that the user writes in the input against those he
   * already has. Use a boolean variable to able or enable the update button
   * @returns {void} void
   */
  public filterSkills(): void {
    this.skillsForm.valueChanges.subscribe((skill) => {
      if (skill.skill) {
        for (const item of this.skills) {
          if (item.skill.toLowerCase() === skill.skill.toLowerCase()) {
            this.sameSkill = true;
            return;
          } else {
            this.sameSkill = false;
          }
        }
      }
      });
  }

  /**
   * getSkillLevels method
   * @description Use the userSkillService to get the existent skill levels
   * @returns {void} void
   */
  private getSkillLevels(): void {
    this.userSkillService.getSkillLevels().subscribe((response) => {
      this.skillLevels = response.data;
    });
  }

  /**
   * changeSkillLevel method
   * @description Listen the event changeSkillLevel of
   * SuggestionsComponent and set the actualSkillLevel variable
   * to the received index
   * @param {ChangeSkillLevel} inputChanged - index of the level that will change
   * @returns {void} void
   */
  public changeSkillLevel(inputChanged: ChangeSkillLevel): void {
    this.actualSkillLevel = inputChanged.index;
  }
}
