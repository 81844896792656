import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesService } from 'src/app/services/roles.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.scss']
})
export class EditRolesComponent implements OnInit {
  public roleName: string;
  public roleId: number;
  public canDelete: boolean = false;
  public toggleValues: boolean[] = [];
  public readyData: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private roleService: RolesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((values) => {
      if (parseInt(values.id, 10) === environment.managerIdRole) {
        this.router.navigateByUrl('manage/roles');
      }

      this.roleId = values.id;
    });

    this.getRoles(this.roleId);
  }

  /**
   * setRoleName method
   * sets the role name to send it to header
   * @param {string} name role name from roles form
   * @returns {void} void
   */
  setRoleName(name: string): void {
    this.roleName = name;
  }

  /**
   * getRoles method
   * receives an id to get the an specific role an its details
   * @param {number} id role id to get
   * @returns {void} void
   */
  public getRoles(id: number): void {
    this.roleService.getRoleDetails(id)
    .subscribe((details) => {
      if (details.status === 'success') {
        this.roleName = details.data[0].name;
        details.data[0].permissions
          .forEach(enabled => this.toggleValues.push(enabled.enabled));
        if(details.data[0].users == 0) {
          this.canDelete = true;
        }

        if (this.toggleValues.some((val) =>  val === true)) {
          this.readyData = true;
        }
      } else {
        this.router.navigateByUrl('manage/roles');
      }
    });
  }
}
