import { Component, OnInit, Input } from '@angular/core';

import { MyProjectsService } from 'src/app/services/my-projects.service';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model';

@Component({
  selector: 'app-my-projects-header',
  templateUrl: './my-projects-header.component.html',
  styleUrls: ['./my-projects-header.component.scss'],
})
export class MyProjectsHeaderComponent implements OnInit {
  @Input() filtersData: ProjectFiltersData;

  constructor(public myProjectsService: MyProjectsService) { }

  /**
   * ngOnInit hook
   * sets up the form that will filter the projects based on
   * user-selected parameters
   * @return {void}
   */
  ngOnInit() {
    // set up default values for the project filters
    this.myProjectsService.setUpForm(this.filtersData);
  }

}
